export const DO_FOLDER_NAMES = {
  MERCHANT: "MERCHANT",
  INTERNAL: "INTERNAL",
  MENU: "MENU",
  BUSINESS: "BUSINESS",
  GUEST: "GUEST",
};

export const DO_FILE_TYPES = {
  MENU: "MENU_ITEM_IMAGE",
  BUSINESS_LOGO_IMAGE: "BUSINESS_LOGO_IMAGE",
  BUSINESS_BACKGROUND_IMAGE: "BUSINESS_BACKGROUND_IMAGE",
  BUSINESS_OTHER_IMAGES: "BUSINESS_OTHER_IMAGES",
  GUEST_PROFILE_PHOTO: "GUEST_PROFILE_PHOTO",
};

export const IMAGE_FILE = {
  defaults: {
    maxSizeMB: 1,
    limit: 1,
    maxWidthOrHeight: 1280,
  },
  menuItem: {
    limit: 0.2, // The compressed file will be reduced to 20% of the original size.
    maxSizeMB: 0.15, // The maximum size of the compressed file: 0.2 MB (200 KB).
    maxWidthOrHeight: 700, // The maximum dimension (width or height) of the image is 700 pixels.
    useWebWorker: true, // Use Web Worker to perform the task in the background without blocking the main thread.
    initialQuality: 1, // The starting quality for the compression process (1 = original quality).
    fileType: "image/webp", // The output file format: WebP.
    alwaysKeepResolution: true, // Ensure the image resolution (dimensions) is always preserved.
    maxIteration: 10, // The maximum number of attempts to meet the target size or quality.
  },
  businessLogo: {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 600,
    limit: 0.5,
  },
  businessBackground: {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    limit: 1,
  },
  guestAvatar: {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 600,
    limit: 0.5,
  },
};
