import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowDown.svg";
import { concatFirstNameWithLastName } from "utils/helpers";
import {
  calculateTotalDiscountPriceOrderItems,
  calculateTotalPriceOrderItems,
  calculateTotalServiceFeeOrderItems,
  calculateTotalSubtotalOrderItems,
} from "utils/helper-functions/orderHelper";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { STORE_NAMES } from "utils/constants/redux";
import useLanguage from "utils/hooks/useLanguage";
import OrderItemModificationList from "components/admin/cards/order-item-modification-list/OrderItemModificationList";

import "./PaidInfo.scss";

const PaidInfo = ({ orderTransaction, allOrderItems }) => {
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const { t } = useTranslation();
  const [showPaidOrderDetail, setShowPaidOrderDetail] = useState(true);
  const paidItemsIds = orderTransaction.transactionItems.flatMap(
    ({ externalId }) => externalId
  );

  const paidItems = allOrderItems.filter((orderItem) =>
    paidItemsIds.includes(orderItem.id)
  );

  const totalPrice = calculateTotalPriceOrderItems(paidItems);
  const totalSubtotal = calculateTotalSubtotalOrderItems(paidItems);
  const totalDiscount = calculateTotalDiscountPriceOrderItems(paidItems);
  const totalServiceFee = calculateTotalServiceFeeOrderItems(paidItems);

  const [orderDetailsHeight, setOrderDetailsHeight] = useState(0);
  const PaidDetailRef = useRef(null);
  const { displayDataByLanguage } = useLanguage();

  useEffect(() => {
    if (PaidDetailRef.current) {
      const height = PaidDetailRef.current.scrollHeight;
      setOrderDetailsHeight(height);
    }
  }, [showPaidOrderDetail, paidItems]);

  const PaidOrderDetails = (
    <div className="AdminOrderPageHomeInfoBoxOrderDetails">
      <div
        className="PaidDetailsButton"
        onClick={() => {
          setShowPaidOrderDetail(!showPaidOrderDetail);
        }}
      >
        <p>{t("dashboard.table.adminOrderHistoryTable.orderDetails")}</p>
        <ArrowIcon
          className={showPaidOrderDetail ? "RotateArrowIcon" : undefined}
        />
      </div>
      <ul className="OrderDetails" ref={PaidDetailRef}>
        {paidItems.map((paidItem, index) => {
          if (
            paidItem.isConfirmed !== false &&
            !paidItems.includes(paidItem.id)
          ) {
            const discountPrice = paidItem.payment.orderItemDiscountPrice;
            const menuItemSubtotalPrice =
              paidItem.payment.orderItemSubtotalPrice;
            const menuItemFinalPrice =
              paidItem.payment.orderItemSubtotalPrice + discountPrice;
            return (
              <li key={index}>
                <div className="AdminOrderInfoDetails">
                  <p style={{ marginRight: "8px" }}>
                    <span>{paidItem.count}x </span>
                    {displayDataByLanguage(paidItem.item.name)}
                  </p>
                  <div className="AdminOrderInfoPrice">
                    {discountPrice !== 0 && (
                      <Price
                        type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                        value={menuItemSubtotalPrice}
                      />
                    )}
                    <Price
                      type={ENUMS_PRICE.types.PRICE_XXS}
                      value={menuItemFinalPrice}
                    />
                  </div>
                </div>
                {paidItem.item.modifications.length > 0 && (
                  <ul className="OrderItemDetailsListModifications">
                    {paidItem.item.modifications.map(
                      (modification) =>
                        modification.options.length > 0 && (
                          <OrderItemModificationList
                            modification={modification}
                            className="OrderItemDetailsListModificationList"
                            key={modification.id}
                            hasPriceInfo
                          />
                        )
                    )}
                  </ul>
                )}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
  const staticBoxHeight = 285;
  return (
    <div
      className="PaidInfoWrapper"
      style={{
        height: !showPaidOrderDetail
          ? `${staticBoxHeight}px`
          : `calc(${staticBoxHeight}px + ${orderDetailsHeight}px)`,
      }}
    >
      <div className="PaidInfo">
        <div className="PaidInfoHeaderContainer">
          <div className="PaidInfoHeader">
            <h2 className="PaidInfoHeaderTitle Medium">
              {t("payment.receipt")} {orderTransaction.transactionId}
            </h2>
            <div className="CreatedByContainer">
              <p className="h7 Medium CreatedBy">
                {t("payment.payedBy")}{" "}
                <span className="SemiBold">
                  {concatFirstNameWithLastName({
                    firstName: orderTransaction.guest.firstName,
                    lastName: orderTransaction.guest.lastName,
                  })}
                </span>
              </p>
              <h6 className="Medium">{t("dashboard.paid")}</h6>
            </div>
            <hr />
          </div>
          <div className="PaidInfoBody">
            <div className="PaidInfoBodyTotal">
              <h3 className="SemiBold">{t("basket.order.total")}</h3>
              <h3 className="SemiBold">
                <Price value={totalPrice} type={ENUMS_PRICE.types.PRICE_L} />
              </h3>
            </div>
            <div className="PaidInfoBodySubTotal">
              <h6 className="Medium">{t("basket.order.subtotal")}</h6>
              <h6 className="Medium">
                <Price
                  value={totalSubtotal}
                  type={ENUMS_PRICE.types.PRICE_S_REGULAR}
                />
              </h6>
            </div>
            <div className="PaidInfoBodyServiceFee">
              <h6 className="Medium">
                {" "}
                {t("inputs.serviceFee")} ({serviceFee || 0}%)
              </h6>
              <h6 className="Medium">
                {serviceFee > 0 && <span>+</span>}

                <Price
                  value={totalServiceFee}
                  type={ENUMS_PRICE.types.PRICE_S_REGULAR}
                />
              </h6>
            </div>
            <div className="PaidInfoBodyDiscount">
              <h6 className="Medium">{t("inputs.discount")}</h6>
              <h6 className="Medium">
                {totalDiscount < 0 && <span>-</span>}
                <Price
                  value={Math.abs(totalDiscount)}
                  type={ENUMS_PRICE.types.PRICE_S_REGULAR}
                />
              </h6>
            </div>
          </div>
          {/*<div className="AdminOrderHomeInfoBoxOrderDetailPrintTheBill">*/}
          {/*  <img src={Printer} alt="Printer" />*/}
          {/*  <h5 className="Medium">{t("buttons.printBill")} </h5>*/}
          {/*</div>*/}
        </div>
        {PaidOrderDetails}
      </div>
    </div>
  );
};

PaidInfo.propTypes = {
  orderTransaction: PropTypes.object.isRequired,
  allOrderItems: PropTypes.array.isRequired,
};

export default PaidInfo;
