import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import VirtualTourPreview from "assets/images/virtual-tour/VirtualTourPreview.gif";
import MapReservation from "assets/images/virtual-tour/MapReservation.jpg";
import { ReactComponent as VR360Icon } from "assets/icons/reserve/VR360.svg";
import { ReactComponent as ChooseMapIcon } from "assets/icons/reserve/ChooseMapIcon.svg";
import { ReactComponent as ArrowUpIcon } from "assets/icons/arrows/ArrowUpRight.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/arrows/ArrowRight.svg";
import { QUERY_PARAMS } from "utils/constants/routes";
import ReservationMapZone from "pages/client/reservation/create-reservation/reservation-table-selection-type/reservation-zone-map/ReservationMapZone";
import ReservationVR from "pages/client/reservation/create-reservation/reservation-table-selection-type/reservation-vr/ReservationVR";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";

import "./ReservationTableSelectionType.scss";

const ReservationTableSelectionType = ({
  searchParams,
  setSearchParams,
  formData,
  zones,
  handleNextStep,
  activeStep,
}) => {
  const { t } = useTranslation();
  const [isMapVisible, setIsMapVisible] = useState(false);
  const containerRef = useRef(null);
  const [isVRVisible, setIsVRVisible] = useState(false);
  const showVR = formData.business?.reservationVREnabled;
  const showMap = formData.business?.reservationMapEnabled;
  const handleOnUpdateOrderTable = async (table) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      [QUERY_PARAMS.reservation.tableId]: table.id,
      [QUERY_PARAMS.reservation.zoneId]: table.zoneId,
    });
  };
  // const handleMapClick = () => {
  //   setIsMapVisible(true);
  //
  //   if (containerRef.current) {
  //     containerRef.current.scrollTo({
  //       top: showVR ? 340 : 190,
  //       behavior: "smooth",
  //     });
  //   }
  // };
  const handleScroll = () => {
    if (containerRef?.current?.scrollTop === 0) {
      setIsMapVisible(false);
    }
  };
  const openVRModal = () => {
    setIsVRVisible(true);
  };
  const closeVRModal = () => {
    setIsVRVisible(false);
  };
  const openMapModal = () => {
    setIsMapVisible(true);
  };
  const closeMapModal = () => {
    setIsMapVisible(false);
  };
  return (
    <>
      <div
        className="ReservationTableSelectionType"
        onScroll={handleScroll}
        ref={containerRef}
        style={{
          overflow: isMapVisible ? "scroll" : "hidden",
        }}
      >
        <span className="">{t("reservation.vr.explore")}</span>
        <div className="ReservationTableSelectionTypeContainer">
          {showVR && (
            <div
              className="GuestInfoFormDetailsVirtualTourPreview"
              onClick={openVRModal}
            >
              <img src={VirtualTourPreview} alt={"VirtualTourPreview"} />
              <div className="GuestInfoFormDetailsVirtualTourReservation">
                <h6 className="Bold">
                  {t("reservation.vr.vrTourReservation")}
                </h6>
                <ArrowUpIcon />
              </div>
            </div>
          )}
          {showMap && (
            <div
              className="GuestInfoFormDetailsVirtualTourPreview"
              onClick={openMapModal}
            >
              <img src={MapReservation} alt="map" />
              <div className="GuestInfoFormDetailsVirtualTourReservation">
                <h6 className="Bold">{t("reservation.map.mapReservation")}</h6>
                <ArrowUpIcon />
              </div>
            </div>
          )}
        </div>

        <div className={`ReservationMapZoneContainer`}>
          {isMapVisible && (
            <ReservationMapZone
              handleNextStep={handleNextStep}
              onClose={closeMapModal}
              selectedTable={formData.table}
              onClickTable={handleOnUpdateOrderTable}
              zones={zones}
              formData={formData}
            />
          )}
        </div>
        <CTAButton
          onClick={handleNextStep}
          icon={<ArrowIcon />}
          name={t("buttons.continue")}
          type={ENUMS_CTA_BUTTON.types.TYPE_R}
        />
      </div>
      {isVRVisible && formData.business?.reservationVRLink && (
        <ReservationVR
          onClick={closeVRModal}
          vrLink={formData.business?.reservationVRLink}
        />
      )}
    </>
  );
};
ReservationTableSelectionType.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  searchParams: PropTypes.instanceOf(URLSearchParams).isRequired,
  setSearchParams: PropTypes.func.isRequired,
};

export default ReservationTableSelectionType;
