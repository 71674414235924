import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Canvas } from "@react-three/fiber";
import { KeyboardControls, Sparkles } from "@react-three/drei";

import Interface from "pages/client/fun-zone/games/tower-block/interface";
import BackButton, {
  ENUMS as BACK_BUTTON_ENUMS,
} from "components/buttons/back-button/BackButton";
import { ReactComponent as Refresh } from "assets/icons/fun-zone/refresh.svg";
import { ROUTE_NAME } from "utils/constants/routes";
import {
  TowerGameProvider,
  useTowerGameContext,
} from "pages/client/fun-zone/games/tower-block/stores/useGame";
import Game from "pages/client/fun-zone/games/tower-block/Game";

import "./TowerBlock.scss";

const TowerBlock = () => {
  return (
    <TowerGameProvider>
      <TowerBlockContent />
    </TowerGameProvider>
  );
};

const TowerBlockContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessId } = useParams();

  const { setPhase } = useTowerGameContext();

  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`
    );
  };

  return (
    <div style={{ height: "100%" }} className="TowerBlock">
      <div className={"TowerGameHeader"}>
        <BackButton
          onClick={handleGoBack}
          type={BACK_BUTTON_ENUMS.types.TYPE_C}
        />
        <h2 className={"SemiBold"}>{t("funZone.tower")}</h2>
        <div
          className={"RefreshButton"}
          onClick={() => {
            setPhase("restart");
          }}
        >
          <Refresh />
        </div>
      </div>
      <KeyboardControls map={[{ name: "drop", keys: ["Space"] }]}>
        <Canvas
          shadows
          orthographic
          camera={{
            fov: 45,
            near: 0.1,
            far: 200,
            position: [2, 2.5, 2],
            zoom: 250,
          }}
        >
          <Game />
          <Sparkles
            size={2}
            scale={[5, 100, 0]}
            speed={0.1}
            count={500}
            position={[-1, 45, -1]}
            rotation-y={0.85}
          />
        </Canvas>
        <Interface />
      </KeyboardControls>
    </div>
  );
};

export default TowerBlock;
