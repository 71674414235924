import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import Price, { ENUMS as ENUMS_PRICE } from "../price/Price";

import "./GuestProfiles.scss";

export const ENUMS = {
  name: "GuestProfiles",
};

const GuestProfileCard = ({ participant, isActive, handleGuestClick }) => {
  const { t } = useTranslation();

  const currencySymbol = useSelector(
    (state) => state[STORE_NAMES.menu].data?.currency?.symbol
  );
  const { name, id, originalId, profilePic, orderAmountsToBePaid, firstName } =
    participant;

  return (
    <div
      className={cx("GuestProfile", {
        isActive,
      })}
      onClick={() => handleGuestClick(id)}
    >
      <img alt={name} src={profilePic} />
      <div className="GuestProfileIdentity">
        <div className="GuestProfileName">
          <h6 className="Medium">
            {participant?.isMe
              ? t("dashboard.guest.me")
              : name || t("dashboard.guest.guest")}
          </h6>
          <h6 className="h7 GuestPersonId">{!name && originalId}</h6>
        </div>
        <h6 className="Bold GuestTotalPrice">
          <Price
            value={orderAmountsToBePaid}
            type={ENUMS_PRICE.types.PRICE_S}
          />
        </h6>
      </div>
    </div>
  );
};

const GuestProfiles = ({
  allParticipants,
  handleSelectGuest,
  selectedGuests,
}) => {
  const handleGuestClick = (generatedGuestId) => {
    handleSelectGuest(generatedGuestId);
  };
  return (
    <div className="GuestProfiles">
      {allParticipants.map((participant, index) => {
        const isActive = selectedGuests.find(
          (selectedGuestId) => participant.id === selectedGuestId
        );
        return (
          <GuestProfileCard
            key={index}
            participant={participant}
            isActive={isActive}
            handleGuestClick={handleGuestClick}
          />
        );
      })}
    </div>
  );
};

GuestProfiles.propTypes = {
  /**
   * A callback function to handle click events on guest profiles.
   */
  onClick: PropTypes.func,
  /**
   * An array of selected guest objects.
   */
  selectedGuests: PropTypes.array.isRequired,

  /**
   * The id of the guest
   */
  guestId: PropTypes.string,
};

export default GuestProfiles;
