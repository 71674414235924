import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import { zodResolver } from "@hookform/resolvers/zod";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import useAsync from "utils/hooks/useAsync";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import IconButton from "components/buttons/icon-button/IconButton";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import useOutsideClick from "utils/hooks/useOutsideClick";
import useFormOutsideClickHandler from "utils/hooks/useFormOutsideClickHandler";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import { updateUser } from "utils/api/services/user";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import {
  convertSnakeCaseToTitleCase,
  handleOnAsyncSuccess,
} from "utils/helpers";
import { getUsers } from "redux/actions/userAction";
import { STORE_NAMES } from "utils/constants/redux";
import { STEP_ENUMS, USER_MODAL_ENUMS } from "../../AdminUser";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import Confirm, {
  ENUMS as ENUMS_CONFIRM,
} from "components/admin/cards/confirm/Confirm";

import "./AdminUserModalBody.scss";

const AdminEditOrDeleteUserModalBody = ({
  selectedUser,
  setOpenSlide,
  setFormData,
  handleOnDeleteUser,
  setSignInMethod,
  initialFormData,
  setOutsideClickAction,
  setModalStep,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const schemas = useValidationSchema(t);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business].business?.id
  );
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();

  const roleEnums = Object.values(
    useSelector((state) => state[STORE_NAMES.app].roles)
  );

  const roles = roleEnums
    .map((role) => {
      return {
        id: role.id,
        name: convertSnakeCaseToTitleCase(role.name),
      };
    })
    .filter((role) => role !== null);

  const getRoleName = (formDataValues) =>
    roleEnums.find((role) => role?.id === formDataValues.role?.id)?.name;

  const methods = useForm({
    resolver: zodResolver(schemas.userModalSchema(false)),
    criteriaMode: USER_MODAL_ENUMS.criteriaModeAll,
  });

  const [
    openSlideConfirmCloseModal,
    setOpenSlideConfirmCloseModal,
    mainElementRefConfirmCloseModal,
  ] = useOutsideClick();

  const handleOnAsyncSuccessForUser = (successMessage) => {
    handleOnAsyncSuccess(successMessage, () => {
      dispatch(getUsers({ businessId }));
      setOpenSlide(false);
    });
  };

  const {
    watch,
    handleSubmit,
    control,
    register,
    getValues,
    formState: { errors },
    setError,
  } = methods;

  watch();

  const currentFormData = {
    firstName: selectedUser.firstName,
    lastName: selectedUser.lastName,
    pinCode: selectedUser.pinCode.userPinCode,
    role: {
      id: selectedUser.roles[0]?.id,
      name: convertSnakeCaseToTitleCase(selectedUser.roles[0]?.name),
    },
  };

  const { execute: executeUpdateUser, loading: isLoadingUpdateUser } = useAsync(
    updateUser,
    {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: () => {
        handleOnAsyncSuccessForUser(t("toastMessages.success.updateUser"));
        handleOnCloseModal();
      },
    }
  );

  const handleOnCloseModal = () => {
    if (!isEqual(getValues(), currentFormData)) {
      return setOpenSlideConfirmCloseModal(true);
    }
    setSignInMethod(USER_MODAL_ENUMS.phoneNumber);
    setFormData(initialFormData);
    setOpenSlide(false);
  };

  useFormOutsideClickHandler({
    formData: { ...currentFormData, ...getValues() },
    formDataInitial: currentFormData,
    setOpenSlide,
    setOpenSlideConfirmCloseModal,
    setOutsideClickAction,
  });

  const handleOnCancelCloseModal = () => {
    setOpenSlideConfirmCloseModal(false);
  };

  const handleOnConfirmCloseModal = () => {
    setFormData(initialFormData);
    setOpenSlideConfirmCloseModal(false);
    setOpenSlide(false);
  };

  const handleClickSubmitEditUser = () => {
    const userData = {
      firstName: getValues().firstName,
      lastName: getValues().lastName,
      businessUser: {
        id: selectedUser.pinCode.id,
        userPinCode: getValues().pinCode,
        role: getRoleName(getValues()),
      },
    };

    executeUpdateUser({
      businessId,
      userId: selectedUser?.id,
      user: userData,
    });
  };

  return (
    <div>
      <div>
        <div className="AdminUserModalTitle">
          <h3 className="SemiBold">{t("user.editEmployee")}</h3>
          <div className="AdminUserModalTitleRight">
            <DeleteButton
              setOpenSlide={setOpenSlide}
              onClick={() => {
                handleOnDeleteUser(selectedUser.id);
              }}
            />
            <IconButton
              onClick={handleOnCloseModal}
              svgComponent={<IconClose />}
            />
          </div>
        </div>
        <div className="AdminUserModalBody">
          <div className="AdminAddUserModal">
            <form className="AdminUserModalBodyForm">
              <InputControl
                name="firstName"
                required
                defaultValue={selectedUser?.firstName}
                type="text"
                placeholder={t("inputs.firstName")}
                {...register("firstName")}
                hasError={errors.firstName}
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="firstName"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
              <InputControl
                name="lastName"
                placeholder={t("inputs.lastName")}
                required
                defaultValue={selectedUser?.lastName}
                type="text"
                {...register("lastName")}
                hasError={errors.lastName}
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="lastName"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
              <Controller
                name="role"
                control={control}
                defaultValue={{
                  id: selectedUser?.roles[0]?.id,
                  name: convertSnakeCaseToTitleCase(
                    selectedUser?.roles[0]?.name
                  ),
                }}
                render={({ field: { value, onChange } }) => (
                  <Dropdown
                    onChange={(value) => {
                      setFormData((prev) => ({
                        ...prev,
                        role: value,
                      }));
                      onChange(value);
                    }}
                    required
                    isOptionRequired
                    placeholder={t("inputs.role")}
                    name="role"
                    value={value}
                    options={roles}
                    error={
                      <ErrorMessage
                        errors={errors}
                        name="role"
                        render={({ message }) => (
                          <p className="h7 error-message">{message}</p>
                        )}
                      />
                    }
                    hasError={errors.role}
                  />
                )}
              />
              <InputControl
                name="pinCode"
                type="number"
                defaultValue={selectedUser?.pinCode?.userPinCode}
                placeholder={t("inputs.pinCode")}
                {...register("pinCode")}
                required
                labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
                hasError={errors.pinCode}
                error={
                  <ErrorMessage
                    errors={errors}
                    name="pinCode"
                    render={({ message }) => (
                      <p className="h7 error-message">{message}</p>
                    )}
                  />
                }
              />
            </form>
          </div>
          {selectedUser.hasSignedUp && (
            <div className="AdminUserModalBodyResetPassword">
              <h6 className="Medium AdminUserModalBodyResetPasswordTitle">
                {t("user.changeYourPassword")}
              </h6>
              <PrimaryButton
                onClick={() => {
                  setModalStep(STEP_ENUMS.RESET_PASSWORD);
                }}
                text={t("login.resetPassword.title")}
                className="AdminUserModalBodyResetPasswordButton"
                type="TYPE_D"
              />
            </div>
          )}
        </div>
        <div className="AdminUserModalFooter">
          <PrimaryButton
            onClick={handleSubmit(handleClickSubmitEditUser)}
            text={t("buttons.save")}
            isLoading={isLoadingUpdateUser}
          />
        </div>
      </div>
      <Confirm
        title={t("modal.warningModalTitleUnsavedChanges")}
        type={ENUMS_CONFIRM.types.TYPE_C}
        mainElementRefConfirm={mainElementRefConfirmCloseModal}
        openSlide={openSlideConfirmCloseModal}
        onCancel={handleOnCancelCloseModal}
        onConfirm={handleOnConfirmCloseModal}
        description={t("modal.warningModalDescription")}
      />
    </div>
  );
};

AdminEditOrDeleteUserModalBody.propTypes = {
  selectedUser: PropTypes.object,
  setOpenSlide: PropTypes.func,
  setFormData: PropTypes.func,
  handleOnDeleteUser: PropTypes.func,
  setSignInMethod: PropTypes.func,
  initialFormData: PropTypes.object,
  setOutsideClickAction: PropTypes.func,
  setModalStep: PropTypes.func,
};

export default AdminEditOrDeleteUserModalBody;
