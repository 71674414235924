import { TIME, WEEKDAYS } from "utils/constants/global";
import { CHART_PERIODS } from "components/buttons/chart-period-select-button/ChartPeriodSelectButton";
import { MONTHS } from "utils/constants/data/base";

export const getStatsWeekdaysProgressData = (fetchedRawData) => {
  const weeklyData = fetchedRawData?.salesData?.weekly;
  if (!weeklyData) {
    return {};
  }
  const statsData = {};
  // Iterate over the keys of weeklyData
  Object.keys(weeklyData).forEach((day) => {
    // Convert the day to lowercase and trim any whitespace
    const lowercaseDay = day.toLowerCase().trim();
    // Check if the lowercaseDay exists in WEEKDAYS
    if (WEEKDAYS[lowercaseDay]) {
      // If it exists, update the statsData with fetched data
      statsData[WEEKDAYS[lowercaseDay]] = weeklyData[day];
    }
  });

  return statsData;
};

export function formatOrderChartData(
  fetchedRawData,
  fetchedRawDataKey,
  selectedPeriod,
  keyName,
  keyFirstValue,
  keySecondValue,
  formatValueOfData
) {
  const data = [];
  if (
    fetchedRawData &&
    fetchedRawData[fetchedRawDataKey] &&
    fetchedRawData[fetchedRawDataKey][selectedPeriod]
  ) {
    for (let day in fetchedRawData[fetchedRawDataKey][selectedPeriod]) {
      data.push({
        [keyName]: formatValueOfData
          ? formatChartDailyAndHourlyXAxis(selectedPeriod, day)
          : day,
        [keyFirstValue]: parseFloat(
          fetchedRawData[fetchedRawDataKey][selectedPeriod][day][
            keyFirstValue
          ]?.toFixed(2)
        ),
        [keySecondValue]: parseFloat(
          fetchedRawData[fetchedRawDataKey][selectedPeriod][day][
            keySecondValue
          ]?.toFixed(2)
        ),
      });
    }
  }

  return data;
}

export function formatTime(averageMinute, t) {
  // Convert minutes to seconds
  const totalSeconds = Math.round(averageMinute * 60);
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minute = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;
  let result = "";
  if (hours > 0) {
    result += hours + t(`common.time.shortVersion.${TIME.hour}`) + " ";
  }
  if (minute > 0 || hours > 0) {
    result += minute + t(`common.time.shortVersion.${TIME.minute}`) + " ";
  }
  if (seconds > 0 || (hours === 0 && minute === 0)) {
    result += seconds + t(`common.time.shortVersion.${TIME.second}`);
  }

  return result.trim();
}
export function getCategoryNameById(categories, id) {
  // Check if categories is an array
  if (!Array.isArray(categories)) {
    return null;
  }

  for (let category of categories) {
    // Check if the current category's id matches the provided id
    if (category.id === id) {
      return category.name;
    }
  }

  return null;
}

export const fixCharDataWidth = (length, maxSize, extraWidth) => {
  let chartDataWidth = "100%";
  if (length > maxSize) {
    chartDataWidth = `${100 + (length - maxSize) * extraWidth}%`;
  }

  return chartDataWidth;
};

export const formatChartDailyAndHourlyXAxis = (selectedPeriod, day) => {
  if (selectedPeriod === CHART_PERIODS.daily) {
    return day.replace("d", "");
  } else if (selectedPeriod === CHART_PERIODS.hourly) {
    const hours = day.substring(1);
    return hours + ":00";
  } else {
    return day;
  }
};

export const formatChartDailyAndHourly = (data, fromDay, toDay) => {
  const daysInMonth = data.map((item) => parseInt(item.daily));

  const fromIndex = daysInMonth.indexOf(fromDay);
  const toIndex = daysInMonth.indexOf(toDay);

  let result = [];
  if (fromIndex <= toIndex) {
    result = data.slice(fromIndex, toIndex + 1);
  } else {
    const firstPart = data.slice(fromIndex);
    const secondPart = data.slice(0, toIndex + 1);
    result = [...firstPart, ...secondPart];
  }

  return result;
};

export const formatChartDailyData = (dataRaw, fromDate, toDate, t) => {
  const fromDay = fromDate.getDate();
  const toDay = toDate.getDate();

  let dailyData = formatChartDailyAndHourly(dataRaw, fromDay, toDay);

  return dailyData.map((item, index) => {
    const day = new Date(
      fromDate.getFullYear(),
      fromDate.getMonth(),
      fromDate.getDate() + index
    );
    const monthName = MONTHS[day.getMonth()].name;
    const translatedMonthName = t(monthName);
    return {
      ...item,
      daily: `${item.daily} ${translatedMonthName.slice(0, 3)}`,
    };
  });
};

export const getOrderedWeekDays = (t) => {
  return Object.keys(WEEKDAYS).map((key) => t(`weekdays.${WEEKDAYS[key]}`));
};

export const formatDateRange = (from, to, t) => {
  const fromDate = new Date(from);
  const toDate = new Date(to);

  const fromMonth = t(MONTHS[fromDate.getMonth()].name);
  const toMonth = t(MONTHS[toDate.getMonth()].name);

  const fromDateString = `${fromDate.getDate()} ${fromMonth} ${fromDate.getFullYear()}`;
  const toDateString = `${toDate.getDate()} ${toMonth} ${toDate.getFullYear()}`;

  if (from === to) {
    return fromDateString;
  } else {
    return `${fromDateString} - ${toDateString}`;
  }
};

export const reorderWeekDays = (data, t) => {
  const order = getOrderedWeekDays(t);
  return data.sort((a, b) => order.indexOf(a.weekly) - order.indexOf(b.weekly));
};

// export const isWithinWeeklyDateRange = (dayOfWeek, fromDate, toDate, t) => {
//   const daysOfWeekMap = Object.fromEntries(
//     Object.keys(WEEKDAYS).map((key, index) => [
//       t(`weekdays.${WEEKDAYS[key]}`),
//       index,
//     ])
//   );
//   const dayIndex = daysOfWeekMap[dayOfWeek];
//   const fromDay = fromDate.getDay();
//   // const toDay = toDate.getDay();
//
//   const fromTime = fromDate.getTime();
//   const toTime = toDate.getTime();
//   const daysDifference = Math.floor(
//     (toTime - fromTime) / (1000 * 60 * 60 * 24)
//   );
//
//   // Get the range of day indexes
//   const range = [];
//   for (let i = 0; i <= daysDifference; i++) {
//     range.push((fromDay + i) % 7);
//   }
//
//   return range.includes(dayIndex);
// };

export const formatChartWeeklyData = (
  data,
  firstKeyValue,
  secondKeyValue,
  t
) => {
  const daysOfWeek = getOrderedWeekDays(t);

  // Create a lookup for the data based on the day of the week
  const dataLookup = data.reduce((acc, curr) => {
    const translatedKey = t(`weekdays.${curr.weekly.toLowerCase()}`);
    acc[translatedKey] = curr;
    return acc;
  }, {});

  const formattedData = daysOfWeek.map((day) => {
    // Find the corresponding data for the current day
    const dayData = dataLookup[day] || {
      [firstKeyValue]: 0,
      [secondKeyValue]: 0,
    };

    return {
      weekly: day,
      [firstKeyValue]: dayData[firstKeyValue] || 0,
      [secondKeyValue]: dayData[secondKeyValue] || 0,
    };
  });
  return reorderWeekDays(formattedData, t);
};

export const formatFromDateToDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// export const formatDataForExport = (sheetsData, columnKey, rowKey) => {
//   return {
//     sheets: sheetsData.map((sheetData) => {
//       const { name, rawData } = sheetData;
//       const columns = rawData.map((item) => item[columnKey]);
//       const rows = [rawData.map((item) => item[rowKey])];
//       return {
//         name,
//         columns,
//         rows,
//       };
//     }),
//   };
// };
