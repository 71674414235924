import { AXIOS_GUEST } from "../axios";
import { API_PATH_PARAMS } from "utils/constants/api";

export const makeOrderPaymentByOdero = async ({
  businessId,
  orderId,
  paymentBody,
}) =>
  AXIOS_GUEST.post(
    `/${API_PATH_PARAMS.business}/${businessId}/${API_PATH_PARAMS.orders}/${orderId}/${API_PATH_PARAMS.payments.root}`,
    paymentBody
  );
