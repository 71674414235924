import React from "react";
import { useTranslation } from "react-i18next";

import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="PrivacyPolicyContainer">
      <h3 className="SemiBold">{t("privacyPolicy.title")}</h3>

      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title1")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description1")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title2")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description2")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title3")}
        </h5>
        <p className="PrivacyPolicyDescription NewLine Regular">
          {t("privacyPolicy.description3")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title4")}
        </h5>
        <p className="PrivacyPolicyDescription NewLine Regular">
          {t("privacyPolicy.description4")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title5")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description5")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title6")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description6")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title7")}
        </h5>
        <p className="PrivacyPolicyDescription NewLine Regular">
          {t("privacyPolicy.description7")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title8")}
        </h5>
        <p className="PrivacyPolicyDescription NewLine Regular">
          {t("privacyPolicy.description8")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title9")}
        </h5>
        <p className="PrivacyPolicyDescription NewLine Regular">
          {t("privacyPolicy.description9")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title10")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description10")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title11")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description11")}
        </p>
      </div>
      <div className="PrivacyPolicy">
        <h5 className="PrivacyPolicyTitle SemiBold">
          {t("privacyPolicy.title12")}
        </h5>
        <p className="PrivacyPolicyDescription Regular">
          {t("privacyPolicy.description12")}
        </p>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
