import React, { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import MemoryCard from "pages/client/fun-zone/games/memory-card-game/memory-card/MemoryCard";
import MemoryCardResultModal from "pages/client/fun-zone/games/memory-card-game/memory-card-win-modal/MemoryCardWinModal";
import BackButton from "assets/icons/arrows/ArrowLeftWhite.svg";
import Refresh from "assets/icons/memory-card-game/refresh.svg";
import useCountdown from "utils/hooks/useCountdown";
import { filterMenuByIsArchivedAndIsPublished } from "utils/helpers";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { ROUTE_NAME } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";

import "./MemoryCardGame.scss";

const CARD_COUNT = 8;
const INIT_ATTEMPT_COUNT = 20;
const initChoices = {
  first: null,
  second: null,
};
const GAME_TIME = 30;

const MemoryCardGame = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const menu = useSelector((state) => state[STORE_NAMES.menu].data);
  const { businessId } = useParams();

  const [cards, setCards] = useState([]);
  const [attempts, setAttempts] = useState(INIT_ATTEMPT_COUNT);
  const [choices, setChoices] = useState(initChoices);
  const [gameIsStarted, setGameIsStarted] = useState(false);
  const [seconds, setSeconds] = useCountdown(null);
  const [interactionEnabled, setInteractionEnabled] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [finalSeconds, setFinalSeconds] = useState(null);

  const timeoutRef = useRef(null);

  const [openSlideMemoryCardGame, setOpenSlideMemoryCardGame] =
    useOutsideClick();

  useEffect(() => {
    shuffleCards();
  }, []);

  const generatedCardImages = useMemo(() => {
    const filteredMenuByIsArchivedAndIsPublished =
      filterMenuByIsArchivedAndIsPublished({ menu });
    const menuItems = filteredMenuByIsArchivedAndIsPublished.categories.flatMap(
      (category) => category.menuItems
    );
    const availableImages = menuItems
      .map((menuItem) => {
        if (menuItem.coverImageSrc) {
          return {
            src: menuItem.coverImageSrc,
          };
        }
      })
      .filter(Boolean);

    const selectedImages = availableImages
      .sort(() => Math.random() - 0.5)
      .slice(0, CARD_COUNT);
    return selectedImages.map((image) => {
      return {
        src: image.src,
        isMatched: false,
      };
    });
  }, [menu]);

  const shuffleCards = () => {
    const shuffleCards = [...generatedCardImages, ...generatedCardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: uuidv4() }));
    setCards(shuffleCards);
    timeoutRef.current = setTimeout(() => {
      setGameIsStarted(true);
      setInteractionEnabled(true);
      timeoutRef.current = null;
    }, 2000);
  };

  const resetStates = () => {
    setCards([]);
    setChoices(initChoices);
    setAttempts(INIT_ATTEMPT_COUNT);
    setGameIsStarted(false);
    setInteractionEnabled(false);
    setMatchCount(0);
    setFinalSeconds(null);
    setSeconds(null);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleNewGame = () => {
    resetStates();
    shuffleCards();
  };

  const updateAttempt = () => {
    setChoices(initChoices);
    setAttempts((prev) => prev - 1);
    setInteractionEnabled(true);
  };

  useEffect(() => {
    let timeoutId;
    const { first: firstChoice, second: secondChoice } = choices;
    if (firstChoice && secondChoice) {
      setInteractionEnabled(false);
      if (firstChoice.src === secondChoice.src) {
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.src === firstChoice.src ? { ...card, isMatched: true } : card
          )
        );
        setMatchCount((prevCount) => prevCount + 1);
        updateAttempt();
      } else {
        timeoutId = setTimeout(updateAttempt, 1000);
      }
    }
    return () => clearTimeout(timeoutId);
  }, [choices]);

  const handleChoice = (card) => {
    const { first: firstChoice } = choices;

    if (!firstChoice && attempts === INIT_ATTEMPT_COUNT) {
      setSeconds(GAME_TIME);
    }
    if (firstChoice && firstChoice.id !== card.id) {
      setChoices((prev) => {
        return {
          ...prev,
          second: card,
        };
      });
    } else {
      setChoices((prev) => {
        return {
          ...prev,
          first: card,
        };
      });
    }
  };

  const handleGoBack = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`
    );
  };
  const handleCancelModal = () => {
    setOpenSlideMemoryCardGame(false);
    handleNewGame();
  };

  const isWinner = cards.length > 0 && cards.every((card) => card.isMatched);

  const isLoser =
    gameIsStarted &&
    (attempts === 0 || seconds === 0) &&
    cards.some((card) => card.isMatched === false);

  useEffect(() => {
    if (isLoser || isWinner) {
      setOpenSlideMemoryCardGame(true);
      setFinalSeconds(seconds);
    }
  }, [isLoser, isWinner]);

  const MemoryCardGameHeader = (
    <div className="MemoryCardGameTitle">
      <div className="MemoryCardGameTitleBack">
        <div className="MemoryCardGameBackRefreshButton" onClick={handleGoBack}>
          <img src={BackButton} alt="BackButton" />
        </div>
      </div>
      <h2 className="SemiBold">{t("funZone.memoryCardGame.mindGame")}</h2>
      <button
        className="MemoryCardGameBackRefreshButton"
        onClick={handleNewGame}
      >
        <img src={Refresh} alt="Refresh" />
      </button>
    </div>
  );

  const MemoryCardGameScoreBoard = (
    <div className="MemoryCardGameCountsBoard">
      <div className="MemoryCardGameSeconds">
        <h2 className="Bold">{finalSeconds ?? seconds ?? GAME_TIME}</h2>
        <h4>{t("funZone.memoryCardGame.secondsLeft")}</h4>
      </div>
      <div className="MemoryCardGameMatchs">
        <p className="Bold">
          {matchCount}/{CARD_COUNT}
        </p>
      </div>
      <div className="MemoryCardGameTries">
        <h2 className="Bold">{attempts}</h2>
        <h4>{t("funZone.memoryCardGame.triesLeft")} </h4>
      </div>
    </div>
  );

  return (
    <div className="MemoryCardGame">
      {MemoryCardGameHeader}
      {MemoryCardGameScoreBoard}
      <div className="MemoryCardGameCards">
        {cards.map((card, index) => {
          const { first: firstChoice, second: secondChoice } = choices;
          return (
            <MemoryCard
              card={card}
              key={card.id}
              handleChoice={handleChoice}
              interactionEnabled={interactionEnabled}
              isFlipped={
                card.id === firstChoice?.id ||
                card.id === secondChoice?.id ||
                card.isMatched
              }
              gameIsStarted={gameIsStarted}
              index={index}
            />
          );
        })}
      </div>
      <MemoryCardResultModal
        onCancel={handleCancelModal}
        openSlide={openSlideMemoryCardGame}
        isLoser={isLoser}
        finalAttempts={attempts}
        finalSeconds={finalSeconds}
        matchCount={matchCount}
        cardCount={CARD_COUNT}
      />
    </div>
  );
};

export default MemoryCardGame;
