import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import ICON_CLOSE from "assets/icons/close/Close.svg";
import ICON_ADD from "assets/icons/math-operators/add/AddThick.svg";
import ICON_HEART_FULL from "assets/icons/favorite/FavoriteFull.svg";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import {
  calculateItemPriceWithDefaultModificationPrice,
  calculateMenuItemPriceBySchedule,
} from "utils/general";

import IMAGE_ITEM_PLACEHOLDER from "assets/images/placeholder/ItemPlaceholder.webp";
import useLanguage from "utils/hooks/useLanguage";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "./FavoriteCard.scss";

export const ENUMS = {
  name: "FavoriteCard ",
  types: {
    TYPE_A: "TYPE_A",
    TYPE_B: "TYPE_B",
  },
};

const FavoriteCard = ({ menuItem, onClose, onAdd, type }) => {
  const discountPrice = menuItem && calculateMenuItemPriceBySchedule(menuItem);
  const menuItemPrice =
    calculateItemPriceWithDefaultModificationPrice(menuItem);

  const { displayDataByLanguage } = useLanguage();

  return (
    <div
      className={cx("FavoriteCard", {
        typeA: type === ENUMS.types.TYPE_A,
        typeB: type === ENUMS.types.TYPE_B,
      })}
    >
      <div className="FavoriteCardImg">
        <div
          className="FavoriteCardCloseButton"
          onClick={(e) => onClose(e, menuItem)}
        >
          {type === ENUMS.types.TYPE_A && <img src={ICON_CLOSE} alt="close" />}
          {type === ENUMS.types.TYPE_B && (
            <img src={ICON_HEART_FULL} alt="close" />
          )}
        </div>
        <ImageWithPlaceholder
          imageSource={menuItem.coverImageSrc}
          placeholder={IMAGE_ITEM_PLACEHOLDER}
          alt={displayDataByLanguage(menuItem.name)}
        />
      </div>
      <div className="FavoriteCardInfo" onClick={(e) => onAdd(e, menuItem)}>
        <h6 className="FavoriteCardName h8 Medium">
          {displayDataByLanguage(menuItem.name)}
        </h6>
        <div className="FavoriteCardInfoBottom">
          <h6 className="h7 SemiBold FavoriteCardPrice">
            <Price
              value={
                discountPrice !== null
                  ? menuItemPrice + discountPrice
                  : menuItemPrice
              }
              type={ENUMS_PRICE.types.PRICE_XS}
            />
            {type === ENUMS.types.TYPE_A && discountPrice !== null && (
              <Price
                type={ENUMS_PRICE.types.PRICE_LINE_THROUGH}
                value={menuItemPrice}
              />
            )}
          </h6>
          <button className="FavoriteCardAddButton" type="button">
            <img src={ICON_ADD} alt="add" />
          </button>
        </div>
      </div>
    </div>
  );
};

FavoriteCard.propTypes = {
  /**
   * The menu item object with its details.
   */
  menuItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.array.isRequired,
    description: PropTypes.array,
    coverImageSrc: PropTypes.string,
    otherImagesSrc: PropTypes.array,
    priceSell: PropTypes.number.isRequired,
    tags: PropTypes.array,
    modifications: PropTypes.array,
  }).isRequired,
  /**
   * Callback function to handle closing the FavoriteCard.
   */
  onClose: PropTypes.func,
  /**
   * Callback function to handle adding the item to favorites.
   */
  onAdd: PropTypes.func.isRequired,
  /**
   * Type of the component
   */
  type: PropTypes.oneOf(Object.values(ENUMS.types)),
};

FavoriteCard.defaultProps = {
  type: ENUMS.types.TYPE_A,
};
export default FavoriteCard;
