import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import cx from "classnames";
import { Link } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

import {
  ENUMS as ENUMS_EMAIL_OR_PHONE,
  ENUMS,
} from "components/elements/email-or-phone/EmailOrPhone";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { ROUTE_NAME } from "utils/constants/routes";
import { SIGN_IN_METHOD as SIGN_UP_METHOD } from "pages/common/login/sign-in/SignIn";
import PrimaryButton, {
  ENUMS as PRIMARY_BUTTON_ENUMS,
} from "components/admin/buttons/primary-button/PrimaryButton";
import useAsync from "utils/hooks/useAsync";
import { createOtpRequest } from "utils/api/services/otp";
import useAPIErrorStatusCodeHelper from "utils/hooks/useAPIErrorStatusCodeHelper";
import EmailInput, {
  ENUMS as ENUMS_EMAIL_INPUT,
} from "components/forms/input/input-email/EmailInput";
import PasswordInput, {
  ENUMS as ENUMS_PASSWORD_INPUT,
} from "components/forms/input/input-password/PasswordInput";
import TextInput, {
  ENUMS as ENUMS_TEXT_INPUT,
} from "components/forms/input/input-text/TextInput";
import SwitchOptions from "components/elements/switch-options/SwitchOptions";
import OrSignUpWith from "pages/client/auth/sign-up/or-sign-up-with/OrSignUpWith";
import { GUEST_SIGN_UP_STEP_TYPES } from "pages/client/auth/ClientAuthHelper";
import CheckTermsAndPrivacy from "pages/common/legal/CheckTermsAndPrivacy/CheckTermsAndPrivacy";
import useOutsideClick from "utils/hooks/useOutsideClick";
import PolicyModal from "components/policy-modal/PolicyModal";

import "./SignUpMainForm.scss";

const SWITCH_OPTIONS_FOR_SIGN_UP = [
  {
    name: "phoneNumber",
    label: "inputs.phoneNumber",
    value: SIGN_UP_METHOD.phoneNumber,
  },
  {
    name: "email",
    label: "inputs.email",
    value: SIGN_UP_METHOD.email,
  },
];

const SignUpMainForm = ({
  setCounter,
  formData,
  setFormData,
  selectedMethod,
  setSelectedMethod,
  setActiveStep,
  setOpenSlideGuestAuthModal,
}) => {
  const { handleAPIErrorMessage } = useAPIErrorStatusCodeHelper();
  const [termsOrPrivacy, setTermsOrPrivacy] = useState("");

  const [openSlidePolicy, setOpenSlidePolicy, mainElementRefPolicy] =
    useOutsideClick();
  const { t } = useTranslation();

  const handlePhoneInputChange = (phone, meta, onChange) => {
    const countryDialCode = meta.country.dialCode;
    if (phone && phone !== ENUMS_EMAIL_OR_PHONE.plusSign + countryDialCode) {
      setFormData((prev) => {
        return {
          ...prev,
          phoneNumber: phone,
        };
      });
    }
    onChange(phone);
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const schemas = useValidationSchema(t);

  const { guestSignUpSchema } = schemas;

  const methods = useForm({
    resolver: zodResolver(guestSignUpSchema(selectedMethod, t)),
    criteriaMode: "all",
    defaultValues: {
      phoneNumber: formData.phoneNumber,
      email: formData.email,
      termsAndConditions: false,
    },
  });

  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    setError,
    setFocus,
    watch,
  } = methods;

  const { execute: executeCreateOtpRequest, loading: isSignUpLoading } =
    useAsync(createOtpRequest, {
      onError: (error) => {
        const errorData = handleAPIErrorMessage(error.response.data);
        if (
          error.response.data.statusCode === 607 ||
          error.response.data.statusCode === 606
        ) {
          setOpenSlideGuestAuthModal(true);
        }
        if (errorData) {
          const { field, errorMessage } = errorData;
          setError(field, {
            type: "manual",
            message: errorMessage,
          });
        }
      },
      onSuccess: ({ data }) => {
        setFormData((prev) => ({
          ...prev,
          otpToken: data.otpToken,
          otpSendCount: data.otpSendCount,
        }));
        setCounter(60);
        setActiveStep(GUEST_SIGN_UP_STEP_TYPES.verifyOtp);
      },
    });
  const handleOnSubmit = () => {
    let otpBody;
    if (selectedMethod === SIGN_UP_METHOD.email) {
      otpBody = { email: formData.email };
    } else if (selectedMethod === SIGN_UP_METHOD.phoneNumber) {
      otpBody = { phoneNumber: formData.phoneNumber.replace(/\+/g, "") };
    }
    executeCreateOtpRequest(otpBody);
  };

  const handleKeyDown = ({ target, key }) => {
    if (key === "Enter") {
      switch (target.name) {
        case "phoneNumber":
        case "email":
          setFocus("firstName");
          break;
        case "firstName":
          setFocus("lastName");
          break;
        case "lastName":
          setFocus("password");
          break;
        case "password":
          handleSubmit(handleOnSubmit)();
          break;
        default:
          break;
      }
    }
  };
  const isTermsChecked = watch("termsAndConditions");
  const openTermsOrPrivacy = (value) => {
    setTermsOrPrivacy(value);
    setOpenSlidePolicy(true);
  };
  const handleOnModalClose = () => {
    setOpenSlidePolicy(false);
  };
  return (
    <div className="SignUpMainForm">
      <h1 className="SemiBold title">{t("auth.createAnAccount")}</h1>

      <form
        className="SignUpCreateAnAccountForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <SwitchOptions
          className={"SignUpPhoneOrEmailSwitch"}
          options={SWITCH_OPTIONS_FOR_SIGN_UP}
          selectedMethod={selectedMethod}
          setSelectedMethod={setSelectedMethod}
        />
        {selectedMethod === SIGN_UP_METHOD.phoneNumber ? (
          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <PhoneInput
                  value={value}
                  onChange={(phone, meta) =>
                    handlePhoneInputChange(phone, meta, onChange)
                  }
                  name="phoneNumber"
                  placeholder={t("inputs.phoneNumber")}
                  defaultCountry={ENUMS.defaultCountry}
                  hideDropdown
                  className={cx("phone-input", {
                    hasError: errors.phoneNumber,
                  })}
                  inputProps={{
                    autoComplete: "username",
                    onKeyDown: handleKeyDown,
                  }}
                />
                {errors.phoneNumber && (
                  <div className="EmailOrPhoneSignInMethodError">
                    <ErrorMessage
                      errors={errors}
                      name="phoneNumber"
                      render={({ message }) => (
                        <p className="h7 error-message">{message}</p>
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        ) : (
          <EmailInput
            className={"InputEmail"}
            containerClassName={"TypeA"}
            placeholder={t("inputs.email")}
            required
            name="email"
            labelType={ENUMS_EMAIL_INPUT.types.TYPE_B}
            func={{
              ...register("email", {
                onChange: handleInputChange,
              }),
            }}
            onKeyDown={handleKeyDown}
            hasError={errors.email}
            error={
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            autoComplete={"username"}
          />
        )}
        <div className="SignUpMethodInputGroups">
          <TextInput
            placeholder={t("inputs.firstName")}
            required
            name="firstName"
            labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
            func={{
              ...register("firstName", {
                onChange: handleInputChange,
              }),
            }}
            onKeyDown={handleKeyDown}
            hasError={errors.firstName}
            value={formData.firstName}
            error={
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            containerClassName={"TypeA"}
          />
          <TextInput
            placeholder={t("inputs.lastName")}
            name="lastName"
            labelType={ENUMS_TEXT_INPUT.types.TYPE_B}
            func={{
              ...register("lastName", {
                onChange: handleInputChange,
              }),
            }}
            onKeyDown={handleKeyDown}
            hasError={errors.lastName}
            value={formData.lastName}
            error={
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            containerClassName={"TypeA"}
          />
          <PasswordInput
            name="password"
            placeholder={t("inputs.password")}
            value={formData.password}
            hasError={errors.password}
            required
            func={{
              ...register("password", {
                onChange: handleInputChange,
              }),
            }}
            onKeyDown={handleKeyDown}
            labelType={ENUMS_PASSWORD_INPUT.types.TYPE_B}
            error={
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <p className="h7 error-message">{message}</p>
                )}
              />
            }
            containerClassName={"TypeA"}
          />
        </div>
        <CheckTermsAndPrivacy
          errors={errors}
          methods={methods}
          isTermsChecked={isTermsChecked}
          openTermsOrPrivacy={openTermsOrPrivacy}
          register={register}
        />
        <OrSignUpWith />
        <h6 className="Medium SignUpSubTitle">
          {t("login.signUp.alreadyHaveAccount")}
          <Link
            to={`${ROUTE_NAME.client}${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`}
            className="LoginLink SemiBold"
          >
            {t("buttons.login")}
          </Link>
        </h6>

        <PrimaryButton
          onClick={handleSubmit(handleOnSubmit)}
          type={PRIMARY_BUTTON_ENUMS.types.TYPE_P}
          text={t("buttons.continue")}
          isLoading={isSignUpLoading}
          className="SignUpFooterButton"
        />
      </form>
      <PolicyModal
        open={openSlidePolicy}
        onClose={handleOnModalClose}
        mainElementRefPolicy={mainElementRefPolicy}
        policyModalTranslateFileKey={"signUpTermsData"}
        termsOrPrivacy={termsOrPrivacy}
      />
    </div>
  );
};

SignUpMainForm.propTypes = {
  setCounter: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    otpToken: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  selectedMethod: PropTypes.oneOf([
    SIGN_UP_METHOD.phoneNumber,
    SIGN_UP_METHOD.email,
  ]).isRequired,
  setSelectedMethod: PropTypes.func.isRequired,
  setShowGuestAuthModal: PropTypes.func,
  setActiveStep: PropTypes.func.isRequired,
  setOpenSlideGuestAuthModal: PropTypes.func,
};

export default SignUpMainForm;
