import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";

import { STORE_NAMES } from "utils/constants/redux";
import useAsync from "utils/hooks/useAsync";
import Spinner from "components/elements/spinner/Spinner";
import {
  getFeedbacksData,
  getFeedbacksStars,
} from "utils/api/services/dashboard";
import StatsCardChartWithPeriodWrapper from "components/admin/charts/chart-wrappers/stat-chart-with-periods-wrapper/StatsCardChartWithPeriodWrapper";
import AdminDashFeedbackCard from "./AdminDashFeedbackCard";
import VerticalBarChart from "components/admin/charts/vertical-bar-chart/VerticalBarChart";
import { fixCharDataWidth } from "utils/helper-functions/dashboard/dashboardStatsHelper";
import { hasFeedbacks } from "utils/helper-functions/dashboard/feedbackHelpers";
import { useFeedbackChartData } from "utils/hooks/useFeedbackChartData";
import EmptyState from "components/admin/empty-state/EmptyState";
import EmptyChartDataIcon from "assets/icons/admin-dashboard/EmptyChartDataIcon.svg";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import Pagination from "components/pagination/Pagination";

import "./AdminDashFeedbacks.scss";

export const CHART_DATA_KEYS = {
  xAxisDataKey: "starKey",
  starCount: "starCount",
  service: "service",
  meal: "meal",
};

const FeedbackChartWrapper = ({
  title,
  chartData,
  chartDataWidth,
  hasFeedbacks,
  t,
}) => (
  <StatsCardChartWithPeriodWrapper
    title={title}
    chartToRender={
      hasFeedbacks ? (
        <VerticalBarChart
          chartData={chartData}
          chartDataWidth={Number(chartDataWidth)}
          barSize={40}
          showCustomTick={true}
        />
      ) : (
        <EmptyState
          icon={EmptyChartDataIcon}
          isAdmin={true}
          description={t("dashboard.noResultForThisPeriodMsg")}
          descriptionClassName={"AdminDashMenuStatsEmptyStateDescription"}
        />
      )
    }
  />
);

const AdminDashFeedbacks = () => {
  const { t } = useTranslation();
  const { setTitle, fromDateToDate } = useOutletContext();
  const zones = useSelector((state) => state[STORE_NAMES.zones].zones);
  const allStaff = useSelector((state) => state[STORE_NAMES.business].users);
  const [staff, setStaff] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const businessId = useSelector(
    (state) => state[STORE_NAMES.business]?.business?.id
  );

  const { result: feedbacksStarsData, loading: feedbacksStarsLoading } =
    useAsync(getFeedbacksStars, {
      immediate: true,
      params: [businessId, fromDateToDate.from, fromDateToDate.to],
    });

  const { result: feedbackData, loading: feedbacksLoading } = useAsync(
    getFeedbacksData,
    {
      immediate: true,
      params: [
        {
          businessId,
          startDate: fromDateToDate.from,
          endDate: fromDateToDate.to,
          pageSize: 18,
          pageNumber: activePage,
          zoneId: selectedZone?.id,
          staffId: selectedStaff?.id,
        },
      ],
    }
  );

  const feedbacksServiceStarsChartData = useFeedbackChartData(
    feedbacksStarsData,
    CHART_DATA_KEYS.service
  );
  const feedbacksMealsStarsChartData = useFeedbackChartData(
    feedbacksStarsData,
    CHART_DATA_KEYS.meal
  );

  const hasServiceFeedbacks = hasFeedbacks(feedbacksServiceStarsChartData.data);
  const hasMealFeedbacks = hasFeedbacks(feedbacksMealsStarsChartData.data);

  const chartDataWidth = useMemo(
    () => fixCharDataWidth(feedbacksServiceStarsChartData.data.length),
    [feedbacksServiceStarsChartData]
  );

  useEffect(() => {
    setActivePage(1);
    if (feedbackData) {
      setTotalPages(feedbackData?.totalPages);
      setActivePage(feedbackData?.pageNumber);
    }
  }, [feedbackData]);

  useEffect(() => {
    setTitle(t("navbarRoutes.pageTitles.feedbacks"));
  }, [t, setTitle]);

  useEffect(() => {
    setStaff(
      allStaff.map((staff) => ({
        id: staff.id,
        name: `${staff.firstName} ${staff.lastName}`,
      }))
    );
  }, [allStaff]);

  const handleStaffChange = (option) => {
    setSelectedStaff(option);
    setActivePage(1);
  };

  const handleZoneChange = (option) => {
    setSelectedZone(option);
    setActivePage(1);
  };

  if (feedbacksStarsLoading || feedbacksLoading) return <Spinner />;

  return (
    <div className="AdminFeedbacks">
      <div className="AdminFeedbacksStats">
        <FeedbackChartWrapper
          title={t("dashboard.feedbackStatistic.service")}
          chartData={feedbacksServiceStarsChartData}
          chartDataWidth={Number(chartDataWidth)}
          hasFeedbacks={hasServiceFeedbacks}
          t={t}
        />
        <FeedbackChartWrapper
          title={t("dashboard.feedbackStatistic.meal")}
          chartData={feedbacksMealsStarsChartData}
          chartDataWidth={Number(chartDataWidth)}
          hasFeedbacks={hasMealFeedbacks}
          t={t}
        />
      </div>

      <div className="AdminFeedbackDropdownContainer">
        <Dropdown
          onChange={handleZoneChange}
          placeholder={t("zone.zone")}
          options={zones}
          value={selectedZone}
          name="zone"
          className={"CustomDropDown"}
        />
        <Dropdown
          onChange={handleStaffChange}
          placeholder={t("navbarRoutes.staff")}
          options={staff}
          value={selectedStaff}
          name="staff"
          className={"CustomDropDown"}
        />
      </div>

      <div className="AdminFeedbacksData">
        {feedbackData?.data.map((userFeedback, feetBackIndex) => {
          return (
            <div className="FeedBackCards" key={feetBackIndex}>
              <AdminDashFeedbackCard userFeedback={userFeedback} t={t} />
            </div>
          );
        })}
      </div>
      {totalPages > 1 && !feedbacksLoading && (
        <div className="AdminFeedbacksPagination">
          <Pagination
            pageCount={totalPages}
            pageRange={10}
            setActivePage={setActivePage}
            currentPage={activePage}
          />
        </div>
      )}
    </div>
  );
};

FeedbackChartWrapper.propTypes = {
  title: PropTypes.string,
  chartData: PropTypes.object,
  chartDataWidth: PropTypes.number,
  hasFeedbacks: PropTypes.bool,
  t: PropTypes.func,
};

export default AdminDashFeedbacks;
