import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Checkbox, {
  ENUMS as CHECKBOX_BUTTON_ENUMS,
} from "components/admin/forms/checkbox/Checkbox";
import { TermsOfPrivacy } from "pages/common/login/sign-up/sign-up-method/SignUpMethod";
import cx from "classnames";

const CheckTermsAndPrivacy = ({
  methods,
  isTermsChecked,
  openTermsOrPrivacy,
  register,
}) => {
  const { t } = useTranslation();
  const hasError = Boolean(methods.formState.errors.termsAndConditions);
  return (
    <div className="SignUpFormTermsAndConditions">
      <div className={cx("CheckboxContainer", { CheckboxError: hasError })}>
        <Checkbox
          {...register("termsAndConditions", {
            required: t("login.signUp.termsErrorMessage"),
          })}
          isChecked={isTermsChecked}
          onChange={() => {
            const newValue = !isTermsChecked;
            methods.setValue("termsAndConditions", newValue);
            if (newValue) {
              methods.clearErrors("termsAndConditions");
            }
          }}
          type={CHECKBOX_BUTTON_ENUMS.types.TYPE_B}
        />
        <p className="SignUpFormTermsAndConditionsText">
          {t("login.signUp.agree")}{" "}
          <span onClick={() => openTermsOrPrivacy(TermsOfPrivacy.TERMS)}>
            {t("login.signUp.terms")}
          </span>{" "}
          &{" "}
          <span onClick={() => openTermsOrPrivacy(TermsOfPrivacy.PRIVACY)}>
            {t("login.signUp.privacy")}
          </span>
        </p>
      </div>
      {methods.formState.errors.termsAndConditions && (
        <p className="h7 SignUpFormError">
          {methods.formState.errors.termsAndConditions?.message}
        </p>
      )}
    </div>
  );
};

CheckTermsAndPrivacy.propTypes = {
  methods: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    formState: PropTypes.shape({
      errors: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  isTermsChecked: PropTypes.bool.isRequired,
  openTermsOrPrivacy: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default CheckTermsAndPrivacy;
