import { AXIOS_USER } from "utils/api/axios";
import { ENDPOINTS, QUERY_PARAMS } from "../../constants/api";

export const getStatsOrderSale = async (businessId, startDate, endDate) =>
  AXIOS_USER.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}${ENDPOINTS.sales}?${QUERY_PARAMS.startDate}=${startDate}&${QUERY_PARAMS.endDate}=${endDate}`
  );

export const getStatsOrderHistory = async (businessId, startDate, endDate) =>
  AXIOS_USER.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}/?${QUERY_PARAMS.startDate}=${startDate}&${QUERY_PARAMS.endDate}=${endDate}`
  );

export const getStatsOrderHistoryTable = async (
  businessId,
  startDate,
  endDate,
  pageSize,
  pageNumber,
  sortBy,
  sortDir,
  zone,
  staff,
  orderId
) => {
  let queryString = `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}${ENDPOINTS.history}/?`;
  const queryParams = [
    { key: QUERY_PARAMS.startDate, value: startDate },
    { key: QUERY_PARAMS.endDate, value: endDate },
    { key: QUERY_PARAMS.pagination.size, value: pageSize },
    { key: QUERY_PARAMS.pagination.number, value: pageNumber },
    { key: QUERY_PARAMS.sort.by, value: sortBy },
    { key: QUERY_PARAMS.sort.dir, value: sortDir },
    { key: QUERY_PARAMS.zone, value: zone },
    { key: QUERY_PARAMS.staff, value: staff },
    { key: QUERY_PARAMS.orderId, value: orderId },
  ];

  // Filter out empty or undefined parameters
  const filteredParams = queryParams.filter(
    (param) => param.value !== undefined && param.value !== ""
  );

  // Construct the query string
  queryString += filteredParams
    .map((param) => `${param.key}=${param.value}`)
    .join("&");

  return AXIOS_USER.get(queryString);
};

export const getStatsMenuStatistics = async (businessId, startDate, endDate) =>
  AXIOS_USER.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.menu}/?${QUERY_PARAMS.startDate}=${startDate}&${QUERY_PARAMS.endDate}=${endDate}`
  );
export const getStatsMenuStatisticsTableData = async (
  businessId,
  startDate,
  endDate,
  pageSize,
  pageNumber,
  sortBy,
  sortDir,
  categoryId,
  itemName,
) => {
  let queryString = `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.menu}${ENDPOINTS.items}/?`;

  const queryParams = [
    { key: QUERY_PARAMS.startDate, value: startDate },
    { key: QUERY_PARAMS.endDate, value: endDate },
    { key: QUERY_PARAMS.pagination.size, value: pageSize },
    { key: QUERY_PARAMS.pagination.number, value: pageNumber },
    { key: QUERY_PARAMS.sort.by, value: sortBy },
    { key: QUERY_PARAMS.sort.dir, value: sortDir },
    { key: QUERY_PARAMS.itemName, value: itemName },
    { key: QUERY_PARAMS.categoryId, value: categoryId },
  ];

  // Filter out empty or undefined parameters
  const filteredParams = queryParams.filter(
    (param) => param.value !== undefined && param.value !== ""
  );

  // Construct the query string
  queryString += filteredParams
    .map((param) => `${param.key}=${param.value}`)
    .join("&");

  return AXIOS_USER.get(queryString);
};

export const getStatsStaff = async (businessId) =>
  AXIOS_USER.get(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}`
  );

export const getFeedbacksStars = async (businessId, startDate, endDate) => {
  let queryString = `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.feedbacks}${ENDPOINTS.summary}?`;

  const queryParams = [
    { key: QUERY_PARAMS.startDate, value: startDate },
    { key: QUERY_PARAMS.endDate, value: endDate },
  ];

  const filteredParams = queryParams.filter(
    (param) => param.value !== undefined && param.value !== ""
  );

  queryString += filteredParams
    .map((param) => `${param.key}=${param.value}`)
    .join("&");

  return AXIOS_USER.get(queryString);
};

export const getFeedbacksData = async (
  {
    businessId,
    startDate,
    endDate,
    zoneId,
    staffId,
    pageSize,
    pageNumber,
    sortBy,
    sortDir,
  }
) => {
  let queryString = `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.feedbacks}/?`;

  const queryParams = [
    { key: QUERY_PARAMS.startDate, value: startDate },
    { key: QUERY_PARAMS.endDate, value: endDate },
    { key: QUERY_PARAMS.pagination.size, value: pageSize },
    { key: QUERY_PARAMS.pagination.number, value: pageNumber },
    { key: QUERY_PARAMS.sort.by, value: sortBy },
    { key: QUERY_PARAMS.sort.dir, value: sortDir },
    { key: QUERY_PARAMS.zoneId, value: zoneId },
    { key: QUERY_PARAMS.staffId, value: staffId },
  ];

  const filteredParams = queryParams.filter(
    (param) => param.value !== undefined && param.value !== ""
  );

  queryString += filteredParams
    .map((param) => `${param.key}=${param.value}`)
    .join("&");

  return AXIOS_USER.get(queryString);
};

export const deleteOrderHistoryTable = async ({
  businessId,
  deletedOrderHistory,
}) =>
  AXIOS_USER.patch(
    `${ENDPOINTS.business}/${businessId}${ENDPOINTS.statistics}${ENDPOINTS.orders}${ENDPOINTS.deleteHistory}`,
    deletedOrderHistory
  );
