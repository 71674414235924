import React, { createContext, useContext, useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import PropTypes from "prop-types";

const MixpanelContext = createContext();

const MixpanelProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [eventQueue, setEventQueue] = useState([]);

  useEffect(() => {
    try {
      mixpanel.init("a8005590449948b7726dfedd483a99e0", {
        debug: true,
        track_pageview: true,
        persistence: "localStorage",
      });
      mixpanel.identify("84");
      mixpanel.people.set({
        $name: "Ilgar Ismayilzada",
        $email: "ilgar.ismayilzada@gmail.com",
        plan: "Premium",
      });
      mixpanel.track("View Item", { Category: 1 });
      setIsInitialized(true);
      console.log("Mixpanel initialized");
    } catch (error) {
      addEventToQueue("Failed to initialize Mixpanel", {});
      console.error("Mixpanel initialization failed:", error);
      setIsInitialized(false);
    }
  }, []);

  useEffect(() => {
    if (isInitialized && eventQueue.length > 0) {
      const queueClone = [...eventQueue];
      queueClone.forEach((item) => {
        console.log({ eventName: item.eventName, properties: item.properties });
        mixpanel.track(item.eventName, item.properties);
      });
      setEventQueue([]);
    }
  }, [eventQueue.length, isInitialized]);

  const addEventToQueue = (eventName, properties) => {
    setEventQueue((prev) => {
      return [...prev, { eventName, properties }];
    });
  };

  return (
    <MixpanelContext.Provider value={{ trackMixpanel: addEventToQueue }}>
      {children}
    </MixpanelContext.Provider>
  );
};

MixpanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMixpanel = () => {
  return useContext(MixpanelContext);
};

export default MixpanelProvider;
