import { useEffect, useRef } from "react";

import { DOM_ID } from "utils/constants/domId";

const useFlyBasketAnimation = () => {
  const basketButtonRef = useRef(null);
  const menuItemImageRef = useRef(null);

  useEffect(() => {
    basketButtonRef.current = document.getElementById(DOM_ID.guestBasketBtnId);
  }, []);

  const flyAnimationHandler = () => {
    const menuItemRect = menuItemImageRef.current.getBoundingClientRect();
    const basketRect = basketButtonRef.current.getBoundingClientRect();

    const clone = menuItemImageRef.current.cloneNode(true);
    clone.classList.add("flying-image");
    document.body.appendChild(clone);

    clone.style.position = "fixed";
    clone.style.left = `${menuItemRect.left}px`;
    clone.style.top = `${menuItemRect.top}px`;
    clone.style.width = `${menuItemRect.width}px`;
    clone.style.height = `${menuItemRect.height}px`;
    clone.style.transition = "all 0.5s ease";
    clone.style.zIndex = 3;

    setTimeout(() => {
      clone.style.left = `${
        basketRect.left + basketRect.width / 2 - menuItemRect.width / 2
      }px`;
      clone.style.top = `${
        basketRect.top + basketRect.height / 2 - menuItemRect.height / 2
      }px`;
      clone.style.transform = "scale(0.1)";
      clone.style.borderRadius = "50%";
      clone.style.zIndex = 6;
      // clone.style.opacity = "0";
    }, 100);

    setTimeout(() => {
      clone.remove();
    }, 600);
  };

  return { flyAnimationHandler, menuItemImageRef };
};

export default useFlyBasketAnimation;
