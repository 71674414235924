import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { formatDateRange } from "utils/helper-functions/dashboard/dashboardStatsHelper";

import "./StatsCardChartWrapper.scss";

const StatsCardChartWrapper = ({
  title,
  subTitle,
  chartToRender,
  className,
}) => {
  const { t } = useTranslation();
  const formattedDateRange = subTitle
    ? formatDateRange(subTitle.from, subTitle.to, t)
    : "";

  return (
    <div className={`StatsCardChartWrapper CardInsetShadow-v1 ${className}`}>
      <div className="StatsCardChartWrapperTitleAndSubtitle">
        <h3 className="SemiBold StatsTitle">{title}</h3>
        <h5 className="SemiBold StatsDate">({formattedDateRange})</h5>
      </div>
      <div className="StatsCardChart">{chartToRender}</div>
    </div>
  );
};

StatsCardChartWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
  chartToRender: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default StatsCardChartWrapper;
