import React from "react";
import PropTypes from "prop-types";

import "./TextArea.scss";

const TextArea = ({ value, placeholder, name, onChange, maxLength }) => {
  const handleChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= maxLength) {
      onChange(e);
    }
  };

  return (
    <div className="TextAreaWrapper">
      <textarea
        placeholder={placeholder}
        name={name}
        onChange={handleChange}
        maxLength={maxLength}
        className="TextAreaControl"
      />
      <p>
        {value.length}/{maxLength}
      </p>
    </div>
  );
};

TextArea.displayName = "TextArea";
TextArea.propTypes = {
  /**
   * The current value of the textarea
   */
  value: PropTypes.string,

  /**
   * Placeholder text for the textarea
   */
  placeholder: PropTypes.string,

  /**
   * The name attribute for the textarea
   */
  name: PropTypes.string,

  /**
   * Callback function to handle textarea change events
   */
  onChange: PropTypes.func,

  maxLength: PropTypes.number,
};

export default TextArea;
