import Decimal from "decimal.js";

// Function to filter out unpaid order items
export const getUnpaidOrderItems = (orderItems, paidOrderItemIds) => {
  return orderItems.filter(
    (orderItem) => !paidOrderItemIds.includes(orderItem.id)
  );
};

// Function to filter out deleted or denied order items
export const filterOrderItemsByIsArchivedAndIsConfirmed = (orderItems) => {
  return orderItems.filter(
    (orderItem) => !orderItem.isArchived && orderItem.isConfirmed !== false
  );
};

export const calculateTotalPriceOrderItems = (orderItems) => {
  return orderItems
    .reduce((total, orderItem) => {
      const orderItemTotal = new Decimal(orderItem.payment.orderItemTotalPrice);
      return new Decimal(total).plus(orderItemTotal);
    }, new Decimal(0))
    .toNumber();
};

export const calculateTotalServiceFeeOrderItems = (orderItems) => {
  return orderItems
    .reduce((total, orderItem) => {
      const orderItemServiceFee = new Decimal(
        orderItem.payment.orderItemServiceFee
      );
      return new Decimal(total).plus(orderItemServiceFee);
    }, new Decimal(0))
    .toNumber();
};

export const calculateTotalSubtotalOrderItems = (orderItems) => {
  return orderItems
    .reduce((total, orderItem) => {
      const orderItemSubtotal = new Decimal(
        orderItem.payment.orderItemSubtotalPrice
      );
      return new Decimal(total).plus(orderItemSubtotal);
    }, new Decimal(0))
    .toNumber();
};
export const calculateTotalDiscountPriceOrderItems = (orderItems) => {
  return orderItems
    .reduce((total, orderItem) => {
      const orderItemDiscount = new Decimal(
        orderItem.payment.orderItemDiscountPrice
      );
      return new Decimal(total).plus(orderItemDiscount);
    }, new Decimal(0))
    .toNumber();
};

export const calculateOrderAmountsToBePaid = ({
  orderItems,
  paidOrderItems,
}) => {
  return calculateTotalPriceOrderItems(
    filterOrderItemsByIsArchivedAndIsConfirmed(
      getUnpaidOrderItems(orderItems, paidOrderItems)
    )
  );
};

export const isDeletedOrderItem = (orderItem) => {
  return orderItem.isArchived === true;
};

export const isDeniedOrderItem = (orderItem) => {
  return orderItem.isConfirmed === false && orderItem.isArchived === false;
};

export const isWaitingOrderItem = (orderItem) => {
  return orderItem.isConfirmed === null;
};
