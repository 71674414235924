import React, { useEffect, useState } from "react";
import { Physics } from "@react-three/rapier";
import { OrbitControls } from "@react-three/drei";

import Level from "pages/client/fun-zone/games/tower-block/Level";
import Lights from "pages/client/fun-zone/games/tower-block/Lights";
import colorSchemes from "pages/client/fun-zone/games/tower-block/color";
import { useTowerGameContext } from "pages/client/fun-zone/games/tower-block/stores/useGame";

function Game() {
  const [colors, setColors] = useState([]);
  const [bgColor, setBgColor] = useState([]);
  const { phase, score } = useTowerGameContext();

  useEffect(() => {
    if (phase === "start" && score === 0) {
      const colorScheme =
        colorSchemes[Math.floor(Math.random() * colorSchemes.length)];
      setColors(colorScheme);
      setBgColor(colorScheme[Math.floor(Math.random() * colorScheme.length)]);
    }
  }, [phase]);

  return (
    <>
      <color args={[bgColor]} attach="background" />
      <OrbitControls />
      <Lights />
      <Physics>{colors.length && <Level colors={colors} />}</Physics>
    </>
  );
}

export default Game;
