import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import IconButton from "components/buttons/icon-button/IconButton";
import InputControl from "components/admin/forms/input-control/InputControl";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import Modal from "components/modal/Modal";
import { ReactComponent as IconClose } from "assets/icons/close/AdminClose.svg";
import PrimaryButton from "components/admin/buttons/primary-button/PrimaryButton";
import Dropdown from "components/admin/forms/dropdown/Dropdown";
import { STORE_NAMES } from "utils/constants/redux";
import { INVENTORY_CATEGORIES } from "utils/constants/data/sample-menu";

import "./AddInventoryItemModal.scss";

export const ENUMS = {
  name: "AddItem",
};

const AddInventoryItemModal = ({
  mainElementRef,
  setOpenSlide,
  title,
  item,
  openSlide,
  activeCategory,
  onSave,
  onDelete,
  error,
  setError,
  showError,
  setShowError,
}) => {
  const isEditable = !!item;
  const initialData = {
    name: "",
    amount: "",
    warningAmount: "",
    unit: {},
    category: activeCategory,
  };
  const { units } = useSelector((state) => state[STORE_NAMES.app]);
  const categories = INVENTORY_CATEGORIES;
  const [formData, setFormData] = useState(initialData);
  const { t } = useTranslation();

  useEffect(() => {
    if (item) {
      setFormData({
        name: item.name,
        amount: item.amount,
        warningAmount: item.warningAmount,
        unit: item.unit,
        category: activeCategory,
      });
    } else {
      setFormData(initialData);
    }
  }, [item, openSlide]);

  const handleOnInputChange = (e) => {
    const { name, value, type } = e.target;
    const updatedFormData = { ...formData };

    if (type === "number") {
      updatedFormData[name] = value !== "" ? parseFloat(value) : "";
    } else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
  };

  const handleOnModalClose = () => {
    setOpenSlide(false);
    setShowError(false);
    setError( {
      name: false,
      amount: false,
      warningAmount: false,
      unit: false,
      category: false,
    });
  };

  const handleOnSubmit = () => { 
    const errors = ValidateForm();
    const hasErrors = Object.values(errors).some((error) => error);
  
    if (!hasErrors) {
      onSave(isEditable, formData);
    }else {
      setError(errors)
      setShowError(true);
    }
  };

  const handleOnDropdownChange = (option, name) => {
    setFormData({
      ...formData,
      [name]: option,
    });
  };

  const AddInventoryItemModalHeader = (
    <div className="AddInventoryItemModalHeader">
      <h3 className="SemiBold AddInventoryItemModalHeaderTitle">
        {isEditable ? t("buttons.editForModal") : `${t("buttons.addForModal")}`}
      </h3>
      {isEditable && (
        <DeleteButton
          setOpenSlide={setOpenSlide}
          onClick={() => onDelete(item.id)}
        />
      )}
      <IconButton onClick={handleOnModalClose} svgComponent={<IconClose />} />
    </div>
  );

  const ValidateForm = () => {
    return {
      name: !formData.name || !/^[a-zA-Z\s]+$/.test(formData.name),
      amount: formData.amount === "" || isNaN(formData.amount) || Number(formData.amount) < 0,
      warningAmount: formData.warningAmount === "" || isNaN(formData.warningAmount) || Number(formData.warningAmount) < 0,
      unit: !(formData.unit && formData.unit.id),
      category: !(formData.category && formData.category.id),
    };
  }

  useEffect(() => {
    if (showError) {
      const errors = ValidateForm();
      setError(errors);
    }
  }, [formData]);
  
  const AddInventoryItemModalBody = (
    <div className="AddInventoryItemModalBody">
      <form
        className="AddInventoryItemModalBodyForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <Dropdown
          placeholder={t("menu.category.category")}
          name="category"
          hasError={error.category}
          value={formData.category}
          options={categories}
          onChange={(category) => {
            handleOnDropdownChange(category, "category");
          }}
          required={true}
          className={`Dropdown`}
        />
        <InputControl
          required={true}
          hasError={error.name}
          type="text"
          name="name"
          placeholder={t("inputs.name")}
          value={formData.name}
          className={`AddInventoryItemModalBodyFormInput ''}`}
          onChange={handleOnInputChange}
        />
        <div className="amountunitcontainer">
          <InputControl
            hasError={error.amount}
            required={true}
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleOnInputChange}
            placeholder={t("inputs.amount")}
            className={`InputControl`}
          />
          <Dropdown
            placeholder={t("inputs.unit")}
            name="unit"
            hasError={error.unit}
            required={true}
            value={formData.unit}
            options={units}
            onChange={(unit) => {
              handleOnDropdownChange(unit, "unit");
            }}
            className={`AddInventoryModalBodyFormInputHalfWidth`}
            isDisabled={isEditable}
          />
        </div>
        <InputControl
          type="number"
          hasError={error.warningAmount}
          required={true}
          name="warningAmount"
          value={formData.warningAmount}
          onChange={handleOnInputChange}
          placeholder={t("inputs.warningAmount")}
          className={`AddInventoryModalBodyFormInputHalfWidth`}
        />
        <h6 className="h7 AddInventoryModalWarningAmountInfo">
          <span className="AddInventoryModalWarningAmountInfoStar">*</span>
          {t("inventory.warningAmountInfo")}
        </h6>
      </form>
    </div>
  );

  const AddInventoryItemModalFooter = (
    <PrimaryButton onClick={handleOnSubmit} text={t("buttons.save")} />
  );

  return (
    <Modal
      header={AddInventoryItemModalHeader}
      body={AddInventoryItemModalBody}
      footer={AddInventoryItemModalFooter}
      mainElementRef={mainElementRef}
      openSlide={openSlide}
    ></Modal>
  );
};

AddInventoryItemModal.propTypes = {
  /**
   * The ref for the modal
   */
  mainElementRef: PropTypes.object,

  /**
   * The function called to set the open slide state
   */
  setOpenSlide: PropTypes.func,

  /**
   * The title to display
   */
  title: PropTypes.string.isRequired,

  /**
   * The item to display
   */
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    amount: PropTypes.number,
    warningAmount: PropTypes.number,
    unit: PropTypes.object,
    category: PropTypes.object,
  }),

  /**
   * Flag to determine if the modal is open
   */
  openSlide: PropTypes.bool,

  /**
   * The active category
   */
  activeCategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),

  /**
   * The function called when the form is submitted to save the data.
   */
  onSave: PropTypes.func,

  /**
   * The function called to delete the item.
   */
  onDelete: PropTypes.func,
};

export default AddInventoryItemModal;
