import React from "react";
import { useTranslation } from "react-i18next";

import { useTowerGameContext } from "pages/client/fun-zone/games/tower-block/stores/useGame";

export default function Interface() {
  const { t } = useTranslation();
  const { phase, score, reset } = useTowerGameContext();

  return (
    <>
      <div className="interface">
        {score !== 0 && <div className="count">{score}</div>}
        {phase === "stop" && (
          <div className="restart" onClick={() => reset()}>
            {t("funZone.towerGame.clickToRestart")}
          </div>
        )}
      </div>
    </>
  );
}
