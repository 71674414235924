import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Chat from "components/elements/chat/Chat";
import { GuestWebsocketContext } from "utils/context-api/GuestWebsocketContext";
import { STORE_NAMES } from "utils/constants/redux";
import { ROUTE_NAME } from "utils/constants/routes";
import useChatControllerHelper from "utils/hooks/websocket/useChatControllerHelper";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
// import BackButton, { ENUMS } from "components/buttons/back-button/BackButton";
// import HamburgerButton from "components/buttons/hamburger-button/HamburgerButton";

const GuestChatController = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // let [searchParams, setSearchParams] = useSearchParams();
  const { publishTableChatMessage, messages } = useContext(
    GuestWebsocketContext
  );
  const qrScanStore = useSelector((state) => state[STORE_NAMES.qrScan]);
  const tableId = qrScanStore.table.id;
  const guest = useSelector((state) => state[STORE_NAMES.guest]);
  const orders = useSelector((state) => state[STORE_NAMES.orders].orders);
  const order = orders?.find((order) => order?.table.id === tableId);
  const isOrderEmpty = !order;
  const orderId = useSelector((state) => state[STORE_NAMES.orders].orders)[0]
    ?.id;
  const businessId = useSelector(
    (state) => state[STORE_NAMES.qrScan]
  ).businessId;
  const business = useSelector((state) => state[STORE_NAMES.business].business);

  const roleEnums = useSelector(
    (state) => state[STORE_NAMES.app].enums
  )?.roleName;

  const author = { id: guest.id, role: { name: roleEnums.guest } };

  const {
    authorLastReadTime,
    handleNewMessage,
    updateUserLastReadTimeWithArgs,
  } = useChatControllerHelper({
    author: author,
    onPublishMessage: publishTableChatMessage,
    businessId: businessId,
    orderId: orderId,
    userId: author.id,
  });

  useEffect(() => {
    if (isOrderEmpty) {
      return navigate(
        `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.menu}`
      );
    }
  }, [isOrderEmpty]);

  // const hamburgerClickHandler = () => {
  //   setSearchParams({
  //     ...searchParams,
  //     [QUERY_PARAMS.showHamburgerMenu]: true,
  //   });
  // };

  const backButtonClickHandler = () => {
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.dashboard}`
    );
  };

  const ChatHeader = (
    <div className="ChatHeader">
      {/*<BackButton type={ENUMS.types.TYPE_C} onClick={backButtonClickHandler} />*/}
      <h2 className="SemiBold ChatHeaderTitle">{t("chat.chat")}</h2>
      {/*<HamburgerButton onClick={hamburgerClickHandler} hasBackground />*/}
      <CloseButton
        onClick={backButtonClickHandler}
        type={ENUMS_CLOSE_BUTTON.types.TYPE_F}
      />
    </div>
  );

  return business?.chat ? (
    <Chat
      author={author}
      messages={messages}
      onNewText={handleNewMessage}
      chatHeader={ChatHeader}
      authorLastReadTime={authorLastReadTime}
      updateUserLastReadTime={updateUserLastReadTimeWithArgs}
      topicId={orderId}
    />
  ) : null;
};

export default GuestChatController;
