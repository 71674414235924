import React from "react";
import PropTypes from "prop-types";
import ReviewTickSvg from "assets/icons/admin_feedbacks/tick.svg";
import profilePicture from "assets/images/user/User.png";
import { formatDate } from "utils/helper-functions/dashboard/feedbackHelpers";
import PaintedStarSvg from "assets/icons/admin_feedbacks/star.svg";
import { concatFirstNameWithLastName } from "utils/helpers";

const FeedBackStar = ({ length }) => {
  const stars = Array.from({ length: length }, (_, index) => (
    <img key={index} src={PaintedStarSvg} alt="Star" className="FeedBackStar" />
  ));

  return (
    <div className="FeedBackStarContainer">{stars.map((star) => star)}</div>
  );
};

const AdminDashFeedbackCard = ({ userFeedback, t }) => {
  return (
    <div className="FeedBackCard">
      <div className="FeedBackCardHeader">
        <div className="FeedBackCardUserName">
          <img
            src={profilePicture}
            alt="PersonPicture"
            className="PersonPicture"
          />
          <h5 className="PersonName Medium">
            {userFeedback?.guestFirstName
              ? concatFirstNameWithLastName({
                  firstName: userFeedback.guestFirstName,
                  lastName: userFeedback.guestLastName,
                })
              : `Guest ${userFeedback?.guestId}`}
          </h5>
        </div>
        <div className="RatingContainer">
          <div className="RatingContainerRates">
            <h6 className="Medium">{t("dashboard.guest.review.meal")} </h6>
            <div>
              <h6 className="Medium">{userFeedback.guestRatingProduct}</h6>
              <FeedBackStar length={1} />
            </div>
          </div>
          <div className="RatingContainerRates">
            <h6 className="Medium">{t("dashboard.guest.review.service")} </h6>
            <div>
              <h6 className="Medium">{userFeedback.guestRatingService}</h6>
              <FeedBackStar length={1} />
            </div>
          </div>
        </div>
      </div>

      <div className="FeedBackTextPart">
        {userFeedback?.guestRatingMessage && (
          <>
            <img src={ReviewTickSvg} alt="ReviewTick" className="ReviewTick" />
            <h6 className="Medium"> {userFeedback?.guestRatingMessage} </h6>
          </>
        )}
      </div>
      <div className="MainInformationArea">
        <div className="MainInformation">
          <div className="Table_Info">
            <div id="TableInfoGroup">
              <h6 className="h7 Medium">{userFeedback?.tableName}</h6>
              <h6 className="h7 Medium">{`(${userFeedback?.zoneName})`}</h6>
            </div>
            <p>{t("navbarRoutes.table")}</p>
          </div>

          <div className="Date_Info">
            <h6 className="h7 Medium">
              {formatDate(userFeedback?.createDate)}
            </h6>
            <p>{t("common.date.date")}</p>
          </div>
        </div>

        <div className="MainInformation">
          <div className="OrderId">
            <h6 className="h7 Medium">{userFeedback?.orderId}</h6>
            <p>{t("dashboard.placeholder.orderId")}</p>
          </div>

          <div className="Staff">
            <h6 className="h7 Medium">{userFeedback?.staffName}</h6>
            <p>{t("navbarRoutes.staff")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

FeedBackStar.propTypes = {
  length: PropTypes.number.isRequired,
};

AdminDashFeedbackCard.propTypes = {
  userFeedback: PropTypes.shape({
    guestFirstName: PropTypes.string,
    guestLastName: PropTypes.string,
    guestId: PropTypes.number,
    guestRatingProduct: PropTypes.number,
    guestRatingService: PropTypes.number,
    guestRatingMessage: PropTypes.string,
    tableName: PropTypes.string,
    zoneName: PropTypes.string,
    createDate: PropTypes.string,
    orderId: PropTypes.number,
    staffName: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default AdminDashFeedbackCard;
