import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import { ReactComponent as ExclamationIcon } from "assets/icons/other/exclamationBlack.svg";
import { ReactComponent as ICON_WARNING } from "assets/icons/client-dashboard/circleInformation.svg";
import { ReactComponent as ICON_WARNING_TRIANGLE } from "assets/icons/other/Triangle.svg";

import Price, {
  ENUMS as PRICE_ENUMS,
  ENUMS as ENUMS_PRICE,
} from "components/elements/price/Price";
import useLanguage from "utils/hooks/useLanguage";
import { STORE_NAMES } from "utils/constants/redux";
import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import {
  calculateTotalPriceOrderItems,
  calculateTotalServiceFeeOrderItems,
  calculateTotalSubtotalOrderItems,
  calculateTotalDiscountPriceOrderItems,
} from "utils/helper-functions/orderHelper";

import "./ReceiptModal.scss";

const ReceiptModal = ({
  openSlide,
  mainElementRef,
  onConfirm,
  onCancel,
  isLoading,
  selectedOrderObjects,
}) => {
  const { t } = useTranslation();
  const { displayDataByLanguage } = useLanguage();

  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );

  const receiptForOnlinePayment = useMemo(() => {
    const unConfirmedOrderItems = selectedOrderObjects.filter(
      (item) => item.isConfirmed === null
    );
    const confirmedOrderItems = selectedOrderObjects.filter(
      (item) => item.isConfirmed === true && item.isPaid === false
    );
    const totalAmountToBePaid =
      calculateTotalPriceOrderItems(confirmedOrderItems);
    const totalServiceFeeToBePaid =
      calculateTotalServiceFeeOrderItems(confirmedOrderItems);
    const totalSubtotalToBePaid =
      calculateTotalSubtotalOrderItems(confirmedOrderItems);

    const totalDiscountToBePaid =
      calculateTotalDiscountPriceOrderItems(confirmedOrderItems);

    return {
      totalAmountToBePaid,
      totalServiceFeeToBePaid,
      totalSubtotalToBePaid,
      confirmedOrderItems,
      unConfirmedOrderItems,
      totalDiscountToBePaid,
    };
  }, [selectedOrderObjects]);

  const {
    totalAmountToBePaid,
    totalServiceFeeToBePaid,
    totalSubtotalToBePaid,
    totalDiscountToBePaid,
    confirmedOrderItems,
    unConfirmedOrderItems,
  } = receiptForOnlinePayment;

  const ReceiptModalHeader = (
    <div className="ReceiptModalHeader">
      <div className="CloseButtonContainer">
        <CloseButton
          onClick={onCancel}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
        />
      </div>
      <div className="ReceiptModalBodyIconWrapper">
        <div className="ReceiptModalBodyIcon">
          <ICON_WARNING className="ReceiptModalBodyIconCircle" />
          <ExclamationIcon className="ReceiptModalBodyExclamationIcon" />
        </div>
      </div>
    </div>
  );
  const ReceiptModalBody = (
    <div className="ReceiptModalBody">
      {confirmedOrderItems.length > 0 && (
        <h4 className="Medium ReceiptModalTitle">
          {t("modal.payMenuItemTitle")}
        </h4>
      )}
      <div className="ReceiptModalBodyContainer">
        {confirmedOrderItems.length > 0 && (
          <div className="ReceiptModalBodyContentDetails">
            {confirmedOrderItems.map((orderItem, index) => {
              const discountPrice = orderItem.payment.orderItemDiscountPrice;
              const menuItemSubtotalPrice =
                orderItem.payment.orderItemSubtotalPrice;
              const menuItemFinalPrice =
                orderItem.payment.orderItemSubtotalPrice + discountPrice;
              return (
                <div key={index} className="ReceiptModalBodyContentDetail">
                  <div className="ReceiptModalBodyContentDetailContent">
                    <p className="h7 Medium ReceiptModalBodyContentDetailContentTitle">
                      {orderItem.count}x{" "}
                      {displayDataByLanguage(orderItem.item.name)}
                    </p>
                    <div className="ReceiptModalBodyContentDetailContentTotalPriceWrapper">
                      <div className="h7 Medium ReceiptModalBodyContentTotalPrice">
                        {discountPrice !== 0 && (
                          <Price
                            type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                            value={menuItemSubtotalPrice}
                          />
                        )}
                      </div>
                      <div className="h7 Medium ReceiptModalBodyContentTotalPrice">
                        <Price
                          type={ENUMS_PRICE.types.PRICE_XS}
                          value={menuItemFinalPrice}
                        />
                      </div>
                    </div>
                  </div>
                  {orderItem.item.modifications &&
                  orderItem.item.modifications.length > 0 ? (
                    <div className="ReceiptModalBodyContentDetailContentModifications">
                      {orderItem.item.modifications.map(
                        (modification, index) => (
                          <div
                            className="ReceiptModalBodyContentDetailContentModificationWrapper"
                            key={index}
                          >
                            <p className="h8 Regular ReceiptModalBodyContentDetailContentModification">
                              {displayDataByLanguage(modification.name)}:
                            </p>
                            <div className="h8 Regular ReceiptModalBodyContentDetailContentModification">
                              {modification.options
                                .map((option, i) => (
                                  <React.Fragment key={i}>
                                    {`${option.count}x ${displayDataByLanguage(
                                      option.name
                                    )}`}
                                    <Price
                                      type={ENUMS_PRICE.types.PRICE_XS}
                                      value={option.count * option.priceSell}
                                    />
                                  </React.Fragment>
                                ))
                                .reduce((prev, curr) => [prev, ", ", curr])}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              );
            })}
            <div className="ReceiptModalBodyOrderInfo">
              <div className="ReceiptModalBodyOrderInfoDetail">
                <h6 className="Medium">{t("basket.order.subtotal")}</h6>
                <h6 className="Medium">
                  <Price
                    value={totalSubtotalToBePaid}
                    type={PRICE_ENUMS.types.PRICE_S_REGULAR}
                  />
                </h6>
              </div>
              <div className="ReceiptModalBodyOrderInfoDetail">
                <h6 className="Medium">{`${t(
                  "inputs.serviceFee"
                )} (${serviceFee}%)`}</h6>
                <h6 className="Medium">
                  {totalServiceFeeToBePaid > 0 && <span>+</span>}
                  <Price
                    value={totalServiceFeeToBePaid}
                    type={PRICE_ENUMS.types.PRICE_S_REGULAR}
                  />
                </h6>
              </div>
              <div className="ReceiptModalBodyOrderInfoDetail">
                <h6 className="Medium">{t("inputs.discount")}</h6>
                <h6 className="Medium">
                  {totalDiscountToBePaid < 0 && <span>-</span>}
                  <Price
                    value={Math.abs(totalDiscountToBePaid)}
                    type={PRICE_ENUMS.types.PRICE_S_REGULAR}
                  />
                </h6>
              </div>
              <div className="ReceiptModalBodyOrderInfoDetail">
                <h5 className="TotalLabel Medium">{t("basket.order.total")}</h5>
                <h5 className="TotalPrice Mediumå">
                  <Price
                    value={totalAmountToBePaid}
                    type={PRICE_ENUMS.types.PRICE_S_REGULAR}
                  />
                </h5>
              </div>
            </div>
          </div>
        )}

        {confirmedOrderItems.length > 0 && unConfirmedOrderItems.length > 0 && (
          <div className="ReceiptModalBodyContentDetailsLine"></div>
        )}

        {unConfirmedOrderItems.length > 0 && (
          <div className="ReceiptModalBodyContentConfirmedDetails ">
            <div className="ReceiptModalBodyContentConfirmedDetailsTitle">
              <div className="ReceiptModalBodyContentConfirmedDetailsTitleIcon">
                <ICON_WARNING_TRIANGLE className="ReceiptModalBodyIconTriangle" />
                <ExclamationIcon className="ReceiptModalBodyExclamationIcon" />
              </div>
              <h6 className="Medium h7">
                {t("modal.unConfirmedCannotBeRemoved")}
              </h6>
            </div>
            {unConfirmedOrderItems.map((itemInOrder, index) => {
              const discountPrice = itemInOrder.payment.orderItemDiscountPrice;
              const menuItemSubtotalPrice =
                itemInOrder.payment.orderItemSubtotalPrice;
              const menuItemFinalPrice =
                itemInOrder.payment.orderItemSubtotalPrice + discountPrice;
              return (
                <div
                  key={index}
                  className="ReceiptModalBodyContentConfirmedDetail"
                >
                  <div className="ReceiptModalBodyContentConfirmedDetailContent">
                    <p className="h7 Medium ReceiptModalBodyContentConfirmedDetailContentTitle">
                      {itemInOrder.count}x{" "}
                      {displayDataByLanguage(itemInOrder.item.name)}
                    </p>
                    <div className="ReceiptModalBodyContentConfirmedDetailContentTotalPriceWrapper">
                      <div className="h7 Medium ReceiptModalBodyContentConfirmedTotalPrice">
                        {discountPrice !== 0 && (
                          <Price
                            type={ENUMS_PRICE.types.PRICE_LINE_THROUGH_XXS}
                            value={menuItemSubtotalPrice}
                          />
                        )}
                      </div>
                      <div className="h7 Medium ReceiptModalBodyContentConfirmedTotalPrice">
                        <Price
                          type={ENUMS_PRICE.types.PRICE_XS}
                          value={menuItemFinalPrice}
                        />
                      </div>
                    </div>
                  </div>
                  {itemInOrder.item.modifications &&
                  itemInOrder.item.modifications.length > 0 ? (
                    <div className="ReceiptModalBodyContentConfirmedDetailContentModifications">
                      {itemInOrder.item.modifications.map(
                        (modification, index) => (
                          <div
                            className="ReceiptModalBodyContentConfirmedDetailContentModificationWrapper"
                            key={index}
                          >
                            <p className="h8 Regular ReceiptModalBodyContentConfirmedDetailContentModification">
                              {displayDataByLanguage(modification.name)}:
                            </p>
                            <div className="h8 Regular ReceiptModalBodyContentConfirmedDetailContentModification">
                              {modification.options
                                .map((option, i) => (
                                  <React.Fragment key={i}>
                                    {`${option.count}x ${displayDataByLanguage(
                                      option.name
                                    )}`}
                                    <Price
                                      type={ENUMS_PRICE.types.PRICE_XS}
                                      value={option.count * option.priceSell}
                                    />
                                  </React.Fragment>
                                ))
                                .reduce((prev, curr) => [prev, ", ", curr])}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
  const ReceiptModalFooter = (
    <div className="ReceiptModalFooter">
      <CTAButton
        onClick={onConfirm}
        element={
          <div className="GuestPayDetail">
            <h3 className="SemiBold PayButton">{t("buttons.pay")}</h3>
            <h6 className="Medium">
              {confirmedOrderItems.length} {t("menu.item.item")}
            </h6>
          </div>
        }
        disabled={confirmedOrderItems.length === 0}
        type={ENUMS_CTA_BUTTON.types.TYPE_M}
        price={totalAmountToBePaid}
        className="ReceiptModalFooterPayButton"
        isLoading={isLoading}
      />
    </div>
  );

  return (
    <GuestModal
      header={ReceiptModalHeader}
      body={ReceiptModalBody}
      mainElementRef={mainElementRef}
      footer={ReceiptModalFooter}
      openSlide={openSlide}
      className={"ModalClassname"}
      containerClassname={"ContainerClassname"}
      modalClassname={"ModalClassname"}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};

export default ReceiptModal;
