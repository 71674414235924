import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Switch, { ENUMS as ENUMS_SWITCH } from "components/forms/switch/Switch";
import WorkingHours from "components/admin/cards/working-hours/WorkingHours";
import CalendarPicker from "components/elements/calendar-picker/CalendarPicker";
import useOutsideClick from "utils/hooks/useOutsideClick";
import { compareDateWithCurrentDate, formatDateToString } from "utils/helpers";

import "./DateAccordion.scss";

export const ENUMS = {
  name: "DateAccordion",
  DATE_FIELDS: {
    FROM: "from",
    TO: "to",
  },
};

const DateAccordion = ({
  title,
  isAccordionOpen,
  setIsAccordionOpen,
  extraComponent,
  formData,
  setFormData,
}) => {
  const { t } = useTranslation();
  const [openCalendar, setOpenCalendar, mainElementRefCalendar] =
    useOutsideClick();
  const [activeField, setActiveField] = useState(null);
  const { from, to, weekdays } = formData.schedule;
  const startDate = formatDateToString(new Date(from));
  const endDate = formatDateToString(new Date(to));
  const handleOnDateChange = (date) => {
    const dateInDateType = new Date(date);
    const formattedDate = formatDateToString(dateInDateType);
    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule,
        [activeField]: formattedDate,
      },
    });
    setOpenCalendar(false);
    setActiveField(null);
  };

  const setFieldAndOpenCalendar = (field) => {
    setOpenCalendar(true);
    setActiveField(field);
  };

  const handleOnSwitchIsWorking = (day) => {
    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule,
        weekdays: {
          ...formData.schedule.weekdays,
          [day]: {
            ...formData.schedule.weekdays[day],
            isWorking: !formData.schedule.weekdays[day].isWorking,
          },
        },
      },
    });
  };

  const handleOnChangeTime = (day, value, timeType) => {
    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule,
        weekdays: {
          ...formData.schedule.weekdays,
          [day]: {
            ...formData.schedule.weekdays[day],
            [timeType]: value.name,
          },
        },
      },
    });
  };

  const handleOnSwitchIsActive = () => {
    setIsAccordionOpen(!isAccordionOpen);

    setFormData({
      ...formData,
      schedule: {
        ...formData.schedule,
        weekdays: {
          ...formData.schedule.weekdays,
          ...weekdays,
        },
        isActive: !isAccordionOpen,
      },
    });
  };

  return (
    <div className="DateAccordion">
      <div className="DateAccordionTitle">
        <Switch
          onChange={handleOnSwitchIsActive}
          isChecked={isAccordionOpen}
          type={ENUMS_SWITCH.types.TYPE_A}
        />
        <h6 className="Medium">{title || t("common.date.schedule")}</h6>
      </div>
      {isAccordionOpen && (
        <div className="DateAccordionBody">
          {extraComponent && extraComponent}
          <h6 className="Medium">{t("common.date.dateSelection")}</h6>
          <div className="DateAccordionBodyDate">
            <div
              className="DateAccordionBodyDateFrom"
              onClick={() => setFieldAndOpenCalendar(ENUMS.DATE_FIELDS.FROM)}
            >
              <h6 className="Medium">{startDate}</h6>
              <h6 className="Medium h7 DateAccordionBodyDateLabel">
                {t("common.time.from")}
              </h6>
            </div>
            <div
              className="DateAccordionBodyDateTo"
              onClick={() => setFieldAndOpenCalendar(ENUMS.DATE_FIELDS.TO)}
            >
              <h6 className="Medium">{endDate}</h6>
              <h6 className="Medium h7 DateAccordionBodyDateLabel">
                {t("common.time.to")}
              </h6>
            </div>
          </div>
          <WorkingHours
            workingHours={weekdays}
            onSwitch={handleOnSwitchIsWorking}
            handleOnChangeTime={handleOnChangeTime}
          />
        </div>
      )}
      <CalendarPicker
        value={activeField === ENUMS.DATE_FIELDS.FROM ? startDate : endDate}
        minDate={
          activeField === ENUMS.DATE_FIELDS.FROM
            ? new Date()
            : compareDateWithCurrentDate(new Date(from))
        }
        maxDate={activeField === ENUMS.DATE_FIELDS.FROM && new Date(to)}
        refCalendar={mainElementRefCalendar}
        setOpenCalendar={setOpenCalendar}
        openCalendar={openCalendar}
        handleDateChange={handleOnDateChange}
      />
    </div>
  );
};

DateAccordion.propTypes = {
  /**
   * The title of the accordion
   */
  title: PropTypes.string,

  /**
   * A boolean indicating whether the accordion is open or closed
   */
  isAccordionOpen: PropTypes.bool.isRequired,

  /**
   * A function to set the state of the accordion's open/closed status
   */
  setIsAccordionOpen: PropTypes.func.isRequired,

  /**
   * An optional extra component to be displayed within the accordion body
   */
  extraComponent: PropTypes.node,

  /**
   * The form data containing schedule information
   */
  formData: PropTypes.shape({
    schedule: PropTypes.shape({
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      weekdays: PropTypes.object.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,

  /**
   * A function to set the form data
   */
  setFormData: PropTypes.func.isRequired,
};

export default DateAccordion;
