import React from "react";

import "./WaitLoading.scss";
const WaitLoading = () => {
  return (
    <div className="WaitLoading">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
  );
};

export default WaitLoading;
