import React, { useEffect, useRef, useMemo, useState } from "react";
import PropTypes from "prop-types";
import ReactConfetti from "react-confetti";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ReactComponent as IconUserAvatar } from "assets/icons/user-avatar/UserAvatar.svg";
import { QUERY_PARAMS } from "utils/constants/routes";
import { STORE_NAMES } from "utils/constants/redux";

import "./SpinnerWheel.scss";

const colors = [
  "#A370E4",
  "#B49BE0",
  "#8A52D1",
  "#9464D6",
  "#C9A5EC",
  "#6C40BD",
  "#D6BDF1",
  "#784DB9",
  "#5F36A3",
  "#CE91F1",
  "#A288D6",
  "#9B5BCF",
  "#855BBF",
  "#B97BE4",
  "#A05CD6",
];
const drawSector = (sector, i, ctx, rad, arc) => {
  const angle = arc * i;
  ctx.save();
  ctx.beginPath();
  ctx.fillStyle = sector.color;
  ctx.moveTo(rad, rad);
  ctx.arc(rad, rad, rad, angle, angle + arc);
  ctx.fill();
  ctx.translate(rad, rad);
  ctx.rotate(angle + arc / 2);
  ctx.textAlign = "right";

  const displayLabel = truncateLabel(sector.label);
  ctx.fillStyle = sector.text;
  ctx.font = "bold 18px 'Lato', sans-serif";
  const TEXT_PADDING_RIGHT = 10;
  ctx.fillText(displayLabel, rad - TEXT_PADDING_RIGHT, 10);
  ctx.restore();
};

const truncateLabel = (label, maxLength = 6) => {
  return label.length > maxLength ? `${label.slice(0, maxLength)}` : label;
};
const friction = 0.98;

const shuffleSectors = (arr) => {
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

const SpinnerWheel = ({
  guestList,
  setWinner,
  setOpenEndModal,
  spinEnded,
  setSpinEnded,
  startName,
  setStartName,
  setSpinnerRunning,
  shuffleTrigger,
}) => {
  const { t } = useTranslation();
  const canvasRef = useRef(null);
  const spinElRef = useRef(null);
  let isSpinning = false;
  const [ctx, setCtx] = useState(null);
  const guestId = useSelector((state) => state[STORE_NAMES.guest].id);
  const shuffledGuests = useMemo(() => {
    const selectedGuests = guestList.filter((guest) => guest.checked);
    return shuffleSectors(selectedGuests);
  }, [
    guestList,
    !shuffleTrigger,
    window.location.search.includes(QUERY_PARAMS.showWheelOfFortune),
  ]);
  const sectors = useMemo(
    () =>
      shuffledGuests.map((guest, index) => ({
        color: colors[index % colors.length],
        text: "#FFF",
        label:
          guest?.person?.id === guestId ? t("dashboard.guest.me") : guest.name,
        image: guest.image || IconUserAvatar,
        isNew: guest.isNew || false,
      })),
    [shuffledGuests]
  );

  const tot = sectors.length;
  let ang = Math.random() * 2 * Math.PI;
  let angleVelocity = 0;
  let frameId;

  const rotate = (ctx) => {
    ctx.canvas.style.transform = `rotate(${ang - Math.PI / 2}rad)`;
    if (angleVelocity && ang % ((2 * Math.PI) / tot) < angleVelocity) {
      const finalSector =
        sectors[Math.floor(tot - (ang / (2 * Math.PI)) * tot) % tot];
      setStartName(truncateLabel(finalSector.label));
      // audioRef.current.currentTime = 0;
      // audioRef.current.play();
    }
  };

  //
  const frame = (ctx) => {
    if (angleVelocity === 0 && isSpinning) {
      const finalSector =
        sectors[Math.floor(tot - (ang / (2 * Math.PI)) * tot) % tot];
      setWinner({
        image: finalSector.image,
        name: finalSector.label,
        isNew: finalSector.isNew,
      });
      setSpinEnded(true);
      setOpenEndModal(true);
      setStartName(truncateLabel(finalSector.label));
      isSpinning = false;
      setSpinnerRunning(false);

      return;
    }
    if (!window.location.search?.includes(QUERY_PARAMS.showWheelOfFortune)) {
      isSpinning = false;
      angleVelocity = 0;
      // if (audioRef?.current) audioRef.current.pause();
      // audioRef.current.currentTime = 0;
      // spinElRef.current?.removeEventListener("click", spinHandler); yazilmali
    }

    angleVelocity =
      angleVelocity * friction < 0.0005 ? 0 : angleVelocity * friction;
    ang = (ang + angleVelocity) % (2 * Math.PI);
    rotate(ctx);
  };
  //
  const start = (ctx) => {
    if (isSpinning) {
      frame(ctx);
      frameId = requestAnimationFrame(() => start(ctx));
    } else {
      if (frameId) {
        cancelAnimationFrame(frameId);
      }
    }
  };
  useEffect(() => {
    const canvas = canvasRef.current;
    setCtx(canvas.getContext("2d"));
    const rad = canvas.getContext("2d").canvas.width / 2;
    const arc = (2 * Math.PI) / sectors.length;

    sectors.forEach((sector, i) =>
      drawSector(sector, i, canvas.getContext("2d"), rad, arc)
    );
    rotate(canvas.getContext("2d"));

    const spinHandler = () => {
      if (!isSpinning) {
        setSpinnerRunning(true);
        isSpinning = true;
        ang = 0;
        const minSpeed = 10;
        const maxSpeed = 20;
        angleVelocity =
          Math.random() * ((maxSpeed - minSpeed) / 2 + 1) * 2 + minSpeed;
        start(ctx);
      }
    };

    spinElRef.current.addEventListener("click", spinHandler);

    return () => {
      spinElRef.current?.removeEventListener("click", spinHandler);
    };
  }, [sectors]);

  return (
    <>
      <div id="spin_the_wheel">
        <div id="canvas-container">
          <canvas width="300" height="300" ref={canvasRef}></canvas>
        </div>
        <div ref={spinElRef} id="spin">
          <p className="SpinText">{startName}</p>
        </div>
      </div>

      {spinEnded && (
        <ReactConfetti
          width={window.innerWidth >= 1000 ? 500 : window.innerWidth}
          height={window.innerHeight >= 1000 ? 700 : window.innerHeight}
          recycle={true}
          numberOfPieces={900}
        />
      )}
    </>
  );
};

SpinnerWheel.propTypes = {
  guestList: PropTypes.array.isRequired,
  setWinner: PropTypes.func.isRequired,
  setOpenEndModal: PropTypes.func.isRequired,
  spinEnded: PropTypes.bool,
  setSpinEnded: PropTypes.func.isRequired,
  shuffleTrigger: PropTypes.bool,
  setSpinnerRunning: PropTypes.func.isRequired,
  startName: PropTypes.string.isRequired,
  setStartName: PropTypes.func.isRequired,
};

export default SpinnerWheel;
