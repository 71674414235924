import React from "react";
import { Trans, useTranslation } from "react-i18next";

import "./TermsAndCondition.scss";

const TermsAndCondition = () => {
  const { t } = useTranslation();

  return (
    <div className="TermsAndConditionContainer">
      <h3 className="SemiBold">{t("termsAndCondition.title")}</h3>
      <div className="TermsAndCondition">
        <p className="TermsAndConditionDescription NewLine Regular">
          <Trans
            i18nKey="termsAndCondition.description"
            values={{ site: "www.iloyal.app" }}
            components={{
              1: <span />,
            }}
          />
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title1")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description1")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title2")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description2")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title3")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description3")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title4")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description4")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title5")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description5")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title6")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description6")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title7")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description7")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title8")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description8")}
        </p>
      </div>
      <div className="TermsAndCondition">
        <h5 className="TermsAndConditionTitle SemiBold">
          {t("termsAndCondition.title9")}
        </h5>
        <p className="TermsAndConditionDescription NewLine Regular">
          {t("termsAndCondition.description9")}
        </p>
      </div>
    </div>
  );
};
export default TermsAndCondition;
