import React, { useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { createDOBucketName } from "utils/DO-Spaces";
import GuestComments from "components/admin/elements/order-comments/comment-item/GuestComments";
import OrderItem from "components/admin/cards/order-item/OrderItem";
import { STORE_NAMES } from "utils/constants/redux";
import IMG_GUEST from "assets/images/placeholder/GuestPlaceholder.png";
import {
  calculateAllOrdersDiscountPrice,
  calculateAllOrdersPrice,
  calculateAllOrdersTotalPrice,
  calculateServiceFee,
} from "utils/general";
import { calculateOrderAmountsToBePaid } from "utils/helper-functions/orderHelper";
import { concatFirstNameWithLastName } from "utils/helpers";
import Price, { ENUMS as ENUMS_PRICE } from "components/elements/price/Price";
import { DATA_TYPES } from "../admin-order-detail/AdminOrderDetail";
import ImageWithPlaceholder from "utils/hooks/useImageWithPlaceholder";

import "./RenderedOrderList.scss";

const RenderedAdminOrderList = ({
  isConfirmed,
  onConfirm,
  onRemoveOrderItem,
  onUpdateOrderItem,
  onEditOrderItem,
  onUndoOrderItem,
  dataType,
  guests,
  isUser,
  groupedPendingOrderItems,
}) => {
  const { serviceFee } = useSelector(
    (state) => state[STORE_NAMES.business].business
  );
  const calculateForEachPerson = (person) => {
    const subTotal = calculateAllOrdersPrice([person]);
    const discountPrice = calculateAllOrdersDiscountPrice([person]);
    const serviceFeeTotal = calculateServiceFee(
      subTotal,
      discountPrice,
      serviceFee
    );
    return Number(
      calculateAllOrdersTotalPrice(
        subTotal,
        0,
        undefined,
        serviceFeeTotal,
        discountPrice
      ).toFixed(2)
    );
  };
  const { t } = useTranslation();

  const selectedOrder = useSelector(
    (state) => state[STORE_NAMES.orders].selectedOrder
  );
  const { PaymentStatus } = useSelector(
    (state) => state[STORE_NAMES.app].enums
  );
  const paidOrderItems = useMemo(() => {
    if (!selectedOrder) return [];
    if (!selectedOrder.orderTransactions) return [];
    return selectedOrder.orderTransactions
      .filter(
        (transaction) => transaction.paymentStatus === PaymentStatus.success
      )
      .flatMap(({ transactionItems }) => transactionItems)
      .flatMap(({ externalId }) => externalId);
  }, [selectedOrder]);

  return (
    <>
      {guests.length > 0 &&
        guests.map((guest) => {
          const orderItems = guest.orderItems;
          const orderItemsPrice =
            dataType === DATA_TYPES.PENDING_DATA
              ? calculateForEachPerson(guest)
              : calculateOrderAmountsToBePaid({
                  orderItems,
                  paidOrderItems,
                });

          return (
            guest.orderItems.length > 0 && (
              <div
                key={guest.person.id}
                className={cx({
                  AdminOrderListGuest: isConfirmed,
                })}
              >
                <div className="AdminOrderDetailsOrderListCaption">
                  <div className="AdminOrderDetailsOrdersGuestDetailsContainer">
                    <ImageWithPlaceholder
                      imageSource={createDOBucketName(guest.profilePic)}
                      placeholder={IMG_GUEST}
                      alt="Guest"
                      className="AdminOrderDetailsOrdersGuestPicture"
                    />
                    <h6 className="SemiBold AdminOrderDetailsOrdersGuestName">
                      {isUser
                        ? guest.person.name
                        : concatFirstNameWithLastName({
                            firstName: guest.firstName,
                            lastName: guest.lastName,
                          }) ||
                          `${t("dashboard.guest.guest")} ${guest.person.id}`}
                    </h6>
                  </div>
                  <h6 className="SemiBold AdminOrderDetailsOrdersGuestTotal">
                    {t("basket.order.total")}:{" "}
                    <Price
                      type={ENUMS_PRICE.types.PRICE_XS}
                      value={orderItemsPrice}
                    />
                  </h6>
                </div>
                {isConfirmed && guest.orderMessage?.length > 0 && (
                  <GuestComments
                    className={"RenderedOrderListGuestComments"}
                    guest={guest}
                    showGuestTitle={false}
                  />
                )}
                {guest.orderItems && guest.orderItems.length > 0 && (
                  <div className="AdminOrderDetailsOrdersList">
                    {[...guest.orderItems]
                      .sort((a, b) => b.isConfirmed - a.isConfirmed)
                      .map((orderItem, index) => {
                        return (
                          <OrderItem
                            isPendingOrderItem={
                              dataType === DATA_TYPES.PENDING_DATA
                            }
                            orderItem={orderItem}
                            onConfirm={() =>
                              onConfirm(orderItem.id, guest.person.id)
                            }
                            key={index}
                            onEdit={() =>
                              onEditOrderItem(orderItem, isUser, dataType)
                            }
                            isPendingDelete={Boolean(
                              groupedPendingOrderItems?.["DELETE"]?.find(
                                (item) =>
                                  item.id === orderItem.id &&
                                  !orderItem.isPendingList
                              )
                            )}
                            isPendingEdit={Boolean(
                              groupedPendingOrderItems?.["EDIT"]?.find(
                                (item) =>
                                  item.id === orderItem.id &&
                                  !orderItem.isPendingList
                              )
                            )}
                            onIncrease={() =>
                              onUpdateOrderItem({
                                count: orderItem.count + 1,
                                orderItemId: orderItem.id,
                                isUser,
                              })
                            }
                            onRemove={() =>
                              onRemoveOrderItem(
                                orderItem.id,
                                guest.person.id,
                                isUser
                              )
                            }
                            onUndo={() =>
                              onUndoOrderItem
                                ? onUndoOrderItem(
                                    orderItem.id,
                                    guest.person.id,
                                    isUser
                                  )
                                : null
                            }
                            onDecrease={() =>
                              onUpdateOrderItem({
                                count: orderItem.count - 1,
                                orderItemId: orderItem.id,
                                isUser,
                                // isNotification,
                                // isFirstEdit,
                              })
                            }
                          />
                        );
                      })}
                  </div>
                )}
              </div>
            )
          );
        })}
    </>
  );
};

RenderedAdminOrderList.propTypes = {
  /**
   * An array of guest objects
   */
  guests: PropTypes.array.isRequired,

  /**
   * An array of users objects
   */
  users: PropTypes.array,

  /**
   * A boolean indicating whether the order is confirmed
   */
  isConfirmed: PropTypes.bool,

  /**
   * Function to handle order confirmation
   */
  onConfirm: PropTypes.func,

  /**
   * Function to handle removal of an order item
   */
  onRemoveOrderItem: PropTypes.func.isRequired,

  /**
   * Function to handle removal of an order item
   */
  onUndoOrderItem: PropTypes.func,

  /**
   * Function to handle update of an order item
   */
  onUpdateOrderItem: PropTypes.func.isRequired,

  /**
   * Function to handle editing of an order item
   */
  onEditOrderItem: PropTypes.func,

  /**
   * edited data type
   */
  dataType: PropTypes.string,

  isUser: PropTypes.bool,

  groupedPendingOrderItems: PropTypes.object,
};

RenderedAdminOrderList.DefaultProps = {
  isUser: false,
};

export default RenderedAdminOrderList;
