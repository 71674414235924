import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import PropTypes from "prop-types";

import Rating from "components/elements/rating/Rating";
import TextArea from "components/admin/forms/text-area-control/TextArea";
import CTAButton, {
  ENUMS as CTA_BUTTON_ENUMS,
} from "components/buttons/cta-button/CTAButton";
import Check from "assets/icons/check/CheckSuccess.svg";
import { STORE_NAMES } from "utils/constants/redux";
import { useSelector } from "react-redux";

import "./Review.scss";

export const ENUMS = {
  name: "Review",
};

const SEND_BUTTON_TYPES = {
  SEND: "SEND",
  NONE: "NONE",
  TRY_AGAIN: "TRY_AGAIN",
  UPDATE: "UPDATE",
};
const Review = ({ guest, onSubmit, isReviewRequestSuccessful }) => {
  const { t } = useTranslation();
  const isLoadingOrder = useSelector(
    (state) => state[STORE_NAMES.orders]?.thunkAPIStates?.getAllOrder
  );
  const initPreviewData = {
    ratingMessage: "",
    ratingService: 5,
    ratingProduct: 5,
  };
  const prevReviewData = React.useMemo(
    () => ({
      ratingMessage: guest.ratingMessage,
      ratingService: guest.ratingService,
      ratingProduct: guest.ratingProduct,
    }),
    [guest.ratingMessage, guest.ratingService, guest.ratingProduct]
  );

  const [formData, setFormData] = useState(prevReviewData);
  const [sendButton, setSendButton] = useState(SEND_BUTTON_TYPES.SEND);

  useEffect(() => {
    setFormData(prevReviewData);
  }, [prevReviewData]);

  const sendButtonText = (() => {
    switch (sendButton) {
      case SEND_BUTTON_TYPES.SEND:
        return t("buttons.send");
      case SEND_BUTTON_TYPES.NONE:
        return t("buttons.successfullySent");
      case SEND_BUTTON_TYPES.UPDATE:
        return t("buttons.update");
      default:
        return t("buttons.tryAgain");
    }
  })();

  const isReviewChanged = !isEqual(formData, prevReviewData);
  const isFirstTimeSendReview =
    guest.ratingMessage === null &&
    guest.ratingProduct === null &&
    guest.ratingService === null;

  useEffect(() => {
    if (isFirstTimeSendReview) {
      setSendButton(SEND_BUTTON_TYPES.SEND);
      return;
    }
    if (isReviewChanged) {
      setSendButton(SEND_BUTTON_TYPES.UPDATE);
    } else if (!isReviewChanged) {
      setSendButton(SEND_BUTTON_TYPES.NONE);
    } else {
      setSendButton(SEND_BUTTON_TYPES.TRY_AGAIN);
    }
  }, [isFirstTimeSendReview, isReviewChanged]);

  useEffect(() => {
    if (typeof isReviewRequestSuccessful === "boolean") {
      setSendButton(
        isReviewRequestSuccessful
          ? SEND_BUTTON_TYPES.NONE
          : SEND_BUTTON_TYPES.TRY_AGAIN
      );
    }
  }, [isReviewRequestSuccessful]);

  const handleOnCommentChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleOnSubmit = () => {
    const dataToSend = {
      ratingMessage: formData.ratingMessage || initPreviewData.ratingMessage,
      ratingService: formData.ratingService || initPreviewData.ratingService,
      ratingProduct: formData.ratingProduct || initPreviewData.ratingProduct,
    };
    onSubmit(dataToSend);
    setSendButton(SEND_BUTTON_TYPES.NONE);
  };

  return (
    <div className="Review">
      <div className="ReviewRating">
        <Rating
          title={t("dashboard.guest.review.meal")}
          rating={formData.ratingProduct || initPreviewData.ratingProduct}
          onRatingChange={(rating) => {
            setFormData({ ...formData, ratingProduct: rating });
          }}
        />
        <Rating
          title={t("dashboard.guest.review.service")}
          rating={formData.ratingService || initPreviewData.ratingService}
          onRatingChange={(rating) => {
            setFormData({ ...formData, ratingService: rating });
          }}
        />
      </div>

      <TextArea
        onChange={handleOnCommentChange}
        placeholder={t("inputs.addComment")}
        value={formData.ratingMessage || initPreviewData.ratingMessage}
        name="ratingMessage"
        maxLength={500}
      />

      {sendButton === SEND_BUTTON_TYPES.NONE && (
        <div className="SuccessFullySent">
          <h4 className="SemiBold">{t("buttons.sent")}</h4>
          <img src={Check} alt="Check" />
        </div>
      )}

      <CTAButton
        onClick={handleOnSubmit}
        name={sendButtonText}
        isLoading={isLoadingOrder}
        type={CTA_BUTTON_ENUMS.types.TYPE_B}
        className={cx("ReviewSendButton", {
          isSend: sendButton === SEND_BUTTON_TYPES.SEND,
          isSent: sendButton === SEND_BUTTON_TYPES.NONE,
          isTryAgain: sendButton === SEND_BUTTON_TYPES.TRY_AGAIN,
          isUpdate: sendButton === SEND_BUTTON_TYPES.UPDATE,
        })}
      />
    </div>
  );
};

Review.propTypes = {
  guest: PropTypes.shape({
    ratingMessage: PropTypes.string,
    ratingService: PropTypes.number,
    ratingProduct: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isReviewRequestSuccessful: PropTypes.bool,
};

export default Review;
