import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { DeviceFrameset } from "react-device-frameset";
import { GuestWebsocketContextProvider } from "utils/context-api/GuestWebsocketContext";
import { PARAM_NAME, ROUTE_NAME } from "utils/constants/routes";
import ClientPagesController from "pages/client/ClientPagesController";
import MenuItem from "pages/client/menu-item/MenuItem";
import Basket from "pages/client/basket/Basket";
import Business from "pages/client/business/Business";
import Menu from "pages/client/menu/Menu";
import Dashboard from "pages/client/dashboard/Dashboard";
import useWindowSize from "utils/hooks/useWindowSize";
import AllBusinesses from "pages/client/all-businesses/AllBusinesses";
import GuestChatController from "components/elements/chat/GuestChatController";
import MascotDashboard from "components/mascot/mascot-dashboard/MascotDashboard";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "pages/common/error-boundary/ErrorBoundary";
import OfflineMenu from "pages/common/offline-menu/OfflineMenu";
import FunZoneGames from "pages/client/fun-zone/games/FunZoneGames";
import useGuestRouteManager from "utils/hooks/useGuestRouteManager";
import MyReservations from "pages/client/reservation/my-reservations/MyReservations";
import CreateReservation from "pages/client/reservation/create-reservation/CreateReservation";
import WheelOfFortune from "components/wheel-of-fortune/WheelOfFortune";
import AllBusinessesHamburgerMenu from "pages/client/all-businesses/all-businesses-hamburger-menu/AllBusinessesHamburgerMenu";
import Auth from "pages/client/auth/Auth";
import SignUp from "pages/client/auth/sign-up/SignUp";
import SignIn from "pages/client/auth/sign-in/SignIn";
import ForgetPassword from "pages/client/auth/forget-password/ForgotPassword";
import useCreateOrGetGuest from "utils/hooks/useCreateOrGetGuest";
import MyProfile from "pages/client/my-profile/MyProfile";
import MyProfileEdit from "pages/client/my-profile-edit/MyProfileEdit";
import ResetPassword from "pages/client/reset-password/ResetPassword";
import VerifyUser from "pages/client/verify-user/VerifyUser";
import TowerBlock from "pages/client/fun-zone/games/tower-block/TowerBlock";
import MemoryCardGame from "pages/client/fun-zone/games/memory-card-game/MemoryCardGame";
import MagicOrder from "pages/client/magic-order/MagicOrder";
import TicTacToeGame from "pages/client/fun-zone/games/tic-tac-toe/TicTacToeGame";

const GuestRoutes = () => {
  useGuestRouteManager();
  const { isStoreReset } = useCreateOrGetGuest();

  // TODO Ilgar check where it is needed. Relocate this to better place ??
  useEffect(() => {
    if (window.innerWidth < 1000)
      document.documentElement.style.setProperty(
        "--width-inner-width",
        window.innerWidth
      );
    else {
      document.documentElement.style.setProperty("--width-inner-width", 500);
    }
  }, [window.innerWidth]);

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <GuestWebsocketContextProvider>
        <Routes>
          <Route
            path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}`}
            element={<ClientPagesController isStoreReset={isStoreReset} />}
          >
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}`}
              element={<Menu />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.menu}${ROUTE_NAME.menuItem}${PARAM_NAME.menuItemId}`}
              element={<MenuItem />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.basket}`}
              element={<Basket />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.info}`}
              element={<Business />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.dashboard}`}
              element={<Dashboard />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.chat}`}
              element={<GuestChatController />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`}
              element={<MyReservations />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.reservation}${ROUTE_NAME.createReservation}`}
              element={<CreateReservation />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}`}
              element={<FunZoneGames />}
            ></Route>
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.memoryCard}`}
              element={<MemoryCardGame />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.ticTacToe}`}
              element={<TicTacToeGame />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.funZone}${ROUTE_NAME.games}${ROUTE_NAME.towerBlock}`}
              element={<TowerBlock />}
            />
            <Route
              path={`${ROUTE_NAME.business}${PARAM_NAME.businessId}${ROUTE_NAME.magicOrder}`}
              element={<MagicOrder />}
            />
          </Route>
          <Route path={ROUTE_NAME.myProfile} element={<MyProfile />} />
          <Route
            path={`${ROUTE_NAME.myProfile}${ROUTE_NAME.edit}`}
            element={<MyProfileEdit />}
          />
          <Route
            path={`${ROUTE_NAME.myProfile}${ROUTE_NAME.resetPassword}`}
            element={<ResetPassword />}
          />
          <Route path={ROUTE_NAME.allBusinesses} element={<AllBusinesses />} />
          <Route
            path={`${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`}
            element={<MyReservations />}
          />

          <Route path={`${ROUTE_NAME.auth}`} element={<Auth />} />
          <Route
            path={`${ROUTE_NAME.auth}${ROUTE_NAME.signUp}`}
            element={<SignUp />}
          />
          <Route
            path={`${ROUTE_NAME.auth}${ROUTE_NAME.signIn}`}
            element={<SignIn />}
          />
          <Route
            path={`${ROUTE_NAME.auth}${ROUTE_NAME.forgotPassword}`}
            element={<ForgetPassword />}
          />
          <Route path={`${ROUTE_NAME.verifyUser}`} element={<VerifyUser />} />

          <Route
            path={`${ROUTE_NAME.reservation}${ROUTE_NAME.createReservation}`}
            element={<CreateReservation />}
          />

          <Route
            path="*"
            element={
              <Navigate
                to={`${ROUTE_NAME.client}${ROUTE_NAME.allBusinesses}`}
              />
            }
          />
          <Route path={ROUTE_NAME.offlineMenu} element={<OfflineMenu />} />
        </Routes>
        <MascotDashboard />
        <AllBusinessesHamburgerMenu />
        <WheelOfFortune />
      </GuestWebsocketContextProvider>
    </ErrorBoundary>
  );
};

const AppRouterGuest = () => {
  const { size } = useWindowSize();

  if (size.width >= 1000) {
    return (
      <div className="MockUp">
        <DeviceFrameset device="iPhone X">
          <GuestRoutes />
        </DeviceFrameset>
      </div>
    );
  } else {
    return <GuestRoutes />;
  }
};

export default AppRouterGuest;
