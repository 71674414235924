import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import GuestModal, {
  MODAL_POSITIONS,
  POSITION_TYPES,
} from "components/guest/guest-modal/GuestModal";

import PrimaryButton ,{
  ENUMS as PRIMARY_BUTTON_ENUMS
} from "components/admin/buttons/primary-button/PrimaryButton";
import CloseButton, {
  ENUMS as ENUMS_CLOSE_BUTTON,
} from "components/buttons/close-button/CloseButton";
import ConfettiIcon from "assets/icons/fun-zone/memory-card-game/confetti.svg";
import SadFaceIcon from "assets/icons/fun-zone/memory-card-game/sadFace.svg";

import "./MemoryCardWinModal.scss";

const MemoryCardResultModal = ({
  openSlide,
  onCancel,
  finalAttempts,
  finalSeconds,
  cardCount,
  isLoser,
  matchCount,
}) => {
  const { t } = useTranslation();
  const loserWinnerIcon = isLoser ? SadFaceIcon : ConfettiIcon;
  const loserWinnerMessage = isLoser
    ? t("funZone.memoryCardGame.loseMessage")
    : t("funZone.memoryCardGame.winMessage");

  const MemoryCardResultModalHeader = (
    <div className="MemoryCardWinModalHeader">
      <div className="MemoryCardWinModalHeaderMessage">
        <img src={loserWinnerIcon} alt="emoji" />
        <h1 className="Medium">{loserWinnerMessage}!</h1>
      </div>
      <div className="CloseButtonContainer">
        <CloseButton
          onClick={onCancel}
          type={ENUMS_CLOSE_BUTTON.types.TYPE_M}
        />
      </div>
    </div>
  );

  const MemoryCardResultModalBody = (
    <div className="MemoryCardWinModalBody">
      <div className="MemoryCardGameSeconds">
        <h2 className="Bold">{finalSeconds}</h2>
        <h4 className={finalSeconds === 0 ? "loserColor" : ""}>{t("funZone.memoryCardGame.secondsLeft")}</h4>
      </div>
      <div className="MemoryCardGameMatchs">
        <p className="Bold">
          {matchCount}/{cardCount}
        </p>
      </div>
      <div className="MemoryCardGameTries">
        <h2 className="Bold">{finalAttempts}</h2>
        <h4 className={finalAttempts === 0 ? "loserColor" : ""}>{t("funZone.memoryCardGame.triesLeft")} </h4>
      </div>
    </div>
  );

  const MemoryCardResultModalFooter = (
    <div className="MemoryCardWinModalFooter">
      <PrimaryButton
        onClick={onCancel}
        text={t("funZone.memoryCardGame.startNewGame")}
        type={PRIMARY_BUTTON_ENUMS.types.TYPE_F}
      />
    </div>
  );

  return (
    <GuestModal
      header={MemoryCardResultModalHeader}
      body={MemoryCardResultModalBody}
      footer={MemoryCardResultModalFooter}
      openSlide={openSlide}
      containerClassname={"ContainerClassname"}
      modalClassname={"ModalClassname"}
      slideDirection={POSITION_TYPES.DOWN}
      modalPosition={MODAL_POSITIONS.BOTTOM}
    />
  );
};
MemoryCardResultModal.propTypes = {
  openSlide: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  finalAttempts: PropTypes.number,
  finalSeconds: PropTypes.number,
  isLoser: PropTypes.bool,
  cardCount: PropTypes.number,
  matchCount: PropTypes.number,
};

export default MemoryCardResultModal;
