const PrivacyPolicy = {
  termsAndCondition: "Terms And Condition",
  title: "Privacy Policy Information",

  title1: "Privacy Notice",
  description1:
    "The “Portal” was created by “ILoyal” LLC. The Portal was exclusively established by ILoyal LLC to carry out commercial activities that are consistent with its charter and legislation.\n" +
    "This privacy policy outlines how the personal data of individuals registered on the Portal is collected, stored, used, and disclosed. Separate privacy policy documents may also be provided during the use of services offered on the Portal. By using the Portal, you agree to the collection, use, and disclosure of your personal data as described in this privacy policy by the Portal. When you use the services offered on the Portal, you consent to the collection, use, and disclosure of your personal data by ILoyal LLC for service provision purposes. ILoyal LLC reserves the right to have a privacy policy governing the processing of your personal data.\n",

  title2: "Collection of Personal Data",
  description2:
    "The Portal collects your personal data to manage its services. This includes the data you provide and data generated by the Portal related to your use.",

  title3: "The personal data you provide includes:",
  description3:
    "Your email address;\n" +
    "Your mobile phone number;\n" +
    "Your password;\n" +
    "The individual identification number of your ID card;\n" +
    "Your secret questions and answers;\n" +
    "The method you choose to receive portal message notifications.\n",

  title4: "The data generated by the Portal includes:",
  description4:
    "Your Portal username;\n" +
    "Activity logs related to your Portal account, such as account login attempts, your device (including device name, model, operating system, and IP address);\n" +
    "Service-related activities and the pages and messages you access;\n" +
    "Device permissions for accessing personal data;\n" +
    "Depending on the user's specific device, the Portal may require certain permissions to access the user's device data as described below.\n" +
    "\n" +
    "By default, these permissions must be granted by the user before the relevant data is accessed. Permissions can be revoked at any time by the user. The exact procedure for managing permissions may depend on the user's device and software. Note that revoking such permissions may affect the proper functioning of the Portal. If the user grants any of the permissions listed below, the corresponding personal data may be processed by the Portal (e.g., access, modification, or removal):\n",

  title5: "Camera Permission",
  description5:
    "Used to access the camera or to capture pictures and videos from the device. The 'Storage permission' allows the Portal to store files created by the Portal for a specified duration.",

  title6: "Why We Collect Your Personal Data:",
  description6:
    "The Portal collects, uses, and discloses personal data to provide users with Portal notifications (updates, promotions, news, etc.).",

  title7: "Portal Management",
  description7:
    "The Portal collects, uses, and discloses your personal data to manage its services. This includes collecting, using, and disclosing your data to:\n" +
    "Enable the creation of and access to your Portal account;\n" +
    "Manage, protect, and support you with your Portal account.\n",

  title8: "Portal Notifications",
  description8:
    "The Portal collects, uses, and discloses your personal data to provide notifications. This includes collecting, using, and disclosing your data to:\n" +
    "Enable notifications from institutions;\n" +
    "Inform you via email or text message on your preferred email address or mobile number whenever you receive a new message from an institution;\n" +
    "Other collections, uses, and disclosures.\n",

  title9:
    "The Portal may also collect, use, and disclose your personal data for:",
  description9:
    "Purposes for which you have consented to the collection, use, or disclosure; or\n" +
    "Cases where the Portal is permitted by law to collect, use, or disclose your information for such purposes (e.g., criminal investigations or legal proceedings).\n",

  title10: "Cookies:",
  description10:
    "The Portal uses session-based cookies. Session-based cookies are temporary cookie files transferred to your computer and deleted when you close your browser. These cookies are used to perform functions within the Portal after logging in and to improve the Portal by collecting anonymous web usage data. The Portal does not use persistent cookies. Persistent cookies remain on your hard drive until deleted or they expire. Rejecting cookie files may affect certain functionalities on the Portal. The Portal does not use cookies to identify your browsing activities outside of its services.",

  title11: "Modifications",
  description11:
    "Modifications to the information in your Portal account can only be made based on the data provided by the user. If there are deficiencies or changes in the information, you can submit a request on the Portal.",

  title12: "Final Provisions",
  description12:
    "The Portal operates based on the current legislation of the Republic of Azerbaijan and recognizes the supremacy of the law in ensuring the operation of the Portal.",
};

export default PrivacyPolicy;

export const PrivacyPolicyAz = {
  termsAndCondition: "Şərtlər və Qaydalar",

  title: "Məxfilik siyasəti haqqında məlumatlandırma",

  title1: "Məxfilik bildirişi",
  description1:
    "“Portal” “İloyal” MMC tərəfindən yaranmışdır. Portal müstəsna qaydada İloyal MMC tərəfidən nizamnaməsi və qanunvericiliyə zidd olmayan kommersiya fəaliyyətinin həyata keçirilməsi üçün yaradılmışdır. \n" +
    "Bu məxfilik siyasəti Portalda qeydiyyatdan keçmiş şəxslərin şəxsi məlumatlarının necə toplanması, saxlamasını, istifadəsini və açıqlamalarını müəyyənləşdirir. Portalda qeydiyyatdan keçməklə təqdim edilən xidmətlərdən istifadə zamanı da ayrı-ayrı Məxfilik siyasəti sənədləri təqdim edilə bilər. Portaldan istifadə etdiyiniz təqdirdə Portal tərəfindən bu məxfilik siyasətində göstərildiyi kimi şəxsi məlumatlarınızı toplanması, istifadə edilməsi və açıqlanmasına razısınız. Portalda göstərilən xidmətlərindən istifadə etdiyiniz təqdirdə Xidmətin göstərilməsi ilə əlaqəli şəxsi məlumatlarınızın İLoyal MMC ərəfindən toplanması, istifadə edilməsi və açıqlanmasına razısınız. İLoyal MMC şəxsi məlumatlarınızın işlənməsi üçün tətbiq olunan məxfilik siyasətinə malik olmaq hüququna sahibdir.\n",

  title2: "Şəxsi məlumatların toplanması",
  description2:
    "Portalda xidmətləri idarə etmək üçün sizin haqqınızda şəxsi məlumat toplanır. Buraya təqdim etdiyiniz məlumatlar və istifadə ilə əlaqədar Portal tərəfindən yaradılan məlumatlar daxildir.",

  title3: "Təqdim etdiyiniz fərdi məlumatlara aşağıdakılar daxildir:",
  description3:
    "Elektron poçt ünvanınız;\n" +
    "Mobil telefon nömrəniz;\n" +
    "Parolunuz;\n" +
    "Şəxsiyyət vəsiqənizin fərdi identifikasiya nömrəsi;\n" +
    "Gizli suallarınız və cavablarınız;\n" +
    "Portal mesajı bildirişlərini almaq üçün seçdiyiniz metod.\n",

  title4: "Portal tərəfindən yaradılan məlumatlara aşağıdakılar daxildir:",
  description4:
    "Portal istifadəçi adınızı;\n" +
    "Portal hesabınızla əlaqəli fəaliyyət qeydləri, məsələn: hesaba giriş cəhdləri, cihazınız (cihazın adı, model, əməliyyat sistemi və IP ünvanı daxil olmaqla);\n" +
    " Xidmətlərlərlə əlaqəli və daxil olduğunuz səhifələr və mesajlar;\n" +
    "Fərdi məlumatlara giriş üçün cihaz icazələri;\n" +
    "İstifadəçinin xüsusi cihazından asılı olaraq, Portal aşağıda təsvir olunduğu kimi İstifadəçinin cihaz məlumatlarına daxil olmağa imkan verən müəyyən icazələri tələb edə bilər.\n" +
    "\n" +
    "Susmaya görə, müvafiq məlumatlar əldə edilməzdən əvvəl bu icazələr İstifadəçi tərəfindən verilməlidir. İcazə verildikdən sonra istifadəçi tərəfindən istənilən vaxt ləğv edilə bilər. Cihaz icazələrinə nəzarət üçün dəqiq prosedura İstifadəçinin cihazı və proqramından asılı ola bilər. Unutmayın ki, bu cür icazələrin ləğvi Portalın düzgün işləməsinə təsir göstərə bilər. İstifadəçi aşağıda sadalanan hər hansı bir icazəni verərsə, müvafiq Şəxsi Məlumatlar Portal tərəfindən işlənə bilər (yəni daxil olmaq, dəyişdirmək və ya çıxarmaq):\n",

  title5: "Kamera icazəsi",
  description5:
    "Kameraya daxil olmaq və ya cihazdan şəkillər və video çəkmək üçün istifadə olunur. Sənədlərin saxlanması icazəsi (“Storage permission”) Portal tərəfindən yaradılan sənədlər Portal tərəfindən müəyyən edilmiş müddətdə saxlanılır.",

  title6: "Şəxsi məlumatlarınızı niyə toplayırıq:",
  description6:
    "Portal şəxsi məlumatları İstifadəçiləri Portal bildirişləri ilə (yeniliklər, kampaniylar, xəbərlər və s.) təmin etmək məqsədilə toplayır, istifadə edir və açıqlayır.",

  title7: "Portalın idarə olunması",
  description7:
    "Portal xidmətlərini idarə etmək üçün şəxsi məlumatlarınızı toplayır, istifadə edir və açıqlayır. Bu halda məlumatlarınızın toplanması, istifadəsi və açıqlanmasına daxildir:\n" +
    "Portal hesabınızı yaratmağa və daxil olmağa imkan vermək;\n" +
    "İdarə etmək, qorumaq və Portal hesabınızla bağlı sizə dəstək vermək.\n",

  title8: "Portal bildirişləri",
  description8:
    "Portal sizə bildirişlərini təqdim etmək üçün şəxsi məlumatlarınızı toplayır, istifadə edir və açıqlayır. Bu halda məlumatlarınızın toplanması, istifadəsi və açıqlanmasına daxildir:\n" +
    "Qurumlardan bildirişlər almağa imkan vermək;\n" +
    "Qurumdan yeni bir mesaj aldığınız zaman sizə üstünlük verdiyiniz e-poçt adresinizə və ya mobil nömrənizə e-poçt və ya mətn mesajı ilə məlumat vermək;\n" +
    "Digər kolleksiyalar, istifadələr və açıqlamalar.\n",

  title9:
    "Portal həmçinin şəxsi məlumatlarınızı toplaya, istifadə edə və açıqlaya bilər:",
  description9:
    "bu məqsədlə toplanmasına, istifadəsinə və ya açıqlanmasına razılıq vermisiniz və ya;\n" +
    "Portal tərəfindən məlumatınızı bu məqsədlə (cinayət işi və ya məhkəmə araşdırması və s.) toplamaq, istifadə etmək və ya açıqlamaq üçün qanunla icazə verilir.\n",

  title10: "Kukilər:",
  description10:
    "Portal sessiya əsaslı kukilərdən istifadə edir. Sessiya əsaslı kukilər kompüterinizə köçürülən və brauzerinizi bağladıqda silinən müvəqqəti kuki fayllarıdır. Bu kukilər Portal tərəfindən daxil olunduqdan sonra Portal daxilində funksiyaları yerinə yetirməyə imkan vermək və Portalı yaxşılaşdırmaq üçün anonim veb istifadə məlumatları toplamaq üçün istifadə olunur. Portal davamlı kuki istifadə etmir. Bunlar silinməyincə və ya istifadə müddəti bitməyincə sabit diskinizdə olan kukilərdir. Kuki fayllarını rədd etməyiniz portaldakı bəzi funksiyalara təsir edə bilər. Portal xidmətlərindən kənarda gəzən fəaliyyətlərinizi müəyyən etmək üçün kukilərdən istifadə etmir.",

  title11: "Düzəlişlər",
  description11:
    "Portaldakı hesabınızda olan məlumatlara düzəlişlər yalnız İstifadəçi tərəfindən daxil edilən məlumatlar əsasında həyata keçirilə bilər. Məlumatlarda çatışmazlıq və ya dəyişiklik olduğu halda bu barədə Portalda müraciətinizi yerləşdirə bilərsiz. ",

  title12: "Yekun məsələlər.",
  description12:
    "Portal öz fəaliyyətində Azərbaycan Respublikasının qüvvədə olan qanunvericiliyinə əsaslanır və Portalın fəaliyyətinin təmin edilməsində qanunun üstünlüyünü tanıyır.",
};

export const PrivacyPolicyDe = {
  termsAndCondition: "Allgemeine Geschäftsbedingungen",
  title: "Datenschutzrichtlinie Informationen",

  title1: "Datenschutzhinweis",
  description1:
    "Das „Portal“ wurde von der „ILoyal“ LLC erstellt. Das Portal wurde ausschließlich von der ILoyal LLC gegründet, um kommerzielle Aktivitäten durchzuführen, die mit ihrem Satzungszweck und der Gesetzgebung übereinstimmen.\n" +
    "Diese Datenschutzrichtlinie beschreibt, wie die persönlichen Daten von Personen, die im Portal registriert sind, gesammelt, gespeichert, verwendet und offengelegt werden. Separate Datenschutzrichtlinien können auch während der Nutzung der im Portal angebotenen Dienste bereitgestellt werden. Durch die Nutzung des Portals stimmen Sie der Erhebung, Nutzung und Offenlegung Ihrer persönlichen Daten gemäß dieser Datenschutzrichtlinie durch das Portal zu. Wenn Sie die auf dem Portal angebotenen Dienste nutzen, stimmen Sie der Erhebung, Nutzung und Offenlegung Ihrer persönlichen Daten durch die ILoyal LLC für Servicebereitstellungszwecke zu. ILoyal LLC behält sich das Recht vor, eine Datenschutzrichtlinie zur Verarbeitung Ihrer persönlichen Daten zu haben.\n",

  title2: "Erhebung persönlicher Daten",
  description2:
    "Das Portal sammelt Ihre persönlichen Daten, um seine Dienste zu verwalten. Dies umfasst die von Ihnen bereitgestellten Daten und die vom Portal generierten Daten im Zusammenhang mit Ihrer Nutzung.",

  title3: "Die von Ihnen bereitgestellten persönlichen Daten umfassen:",
  description3:
    "Ihre E-Mail-Adresse;\n" +
    "Ihre Handynummer;\n" +
    "Ihr Passwort;\n" +
    "Die individuelle Identifikationsnummer Ihres Personalausweises;\n" +
    "Ihre Geheimfragen und -antworten;\n" +
    "Die Methode, die Sie zur Benachrichtigung über Portalnachrichten wählen.\n",

  title4: "Die vom Portal generierten Daten umfassen:",
  description4:
    "Ihr Portal-Benutzername;\n" +
    "Aktivitätsprotokolle in Bezug auf Ihr Portal-Konto, wie z. B. Anmeldeversuche, Ihr Gerät (einschließlich Gerätebezeichnung, Modell, Betriebssystem und IP-Adresse);\n" +
    "Dienstbezogene Aktivitäten sowie die Seiten und Nachrichten, die Sie aufrufen;\n" +
    "Gerätezugriffsberechtigungen für persönliche Daten;\n" +
    "Abhängig vom spezifischen Gerät des Benutzers kann das Portal bestimmte Berechtigungen zur Nutzung der Gerätedaten des Benutzers erfordern, wie unten beschrieben.\n" +
    "\n" +
    "Standardmäßig müssen diese Berechtigungen vom Benutzer erteilt werden, bevor die entsprechenden Daten verwendet werden können. Berechtigungen können jederzeit vom Benutzer widerrufen werden. Die genaue Vorgehensweise zur Verwaltung von Berechtigungen kann vom Gerät und der Software des Benutzers abhängen. Beachten Sie, dass das Widerrufen solcher Berechtigungen die ordnungsgemäße Funktion des Portals beeinträchtigen kann. Wenn der Benutzer eine der unten aufgeführten Berechtigungen erteilt, können die entsprechenden persönlichen Daten vom Portal verarbeitet werden (z. B. Zugriff, Änderung oder Entfernung):\n",

  title5: "Kameraberechtigung",
  description5:
    "Wird verwendet, um auf die Kamera zuzugreifen oder Bilder und Videos vom Gerät aufzunehmen. Die 'Speicherberechtigung' ermöglicht es dem Portal, Dateien zu speichern, die vom Portal für eine bestimmte Dauer erstellt wurden.",

  title6: "Warum wir Ihre persönlichen Daten sammeln:",
  description6:
    "Das Portal sammelt, verwendet und offenbart persönliche Daten, um den Benutzern Benachrichtigungen des Portals bereitzustellen (Updates, Werbeaktionen, Neuigkeiten usw.).",

  title7: "Portal-Management",
  description7:
    "Das Portal sammelt, verwendet und offenbart Ihre persönlichen Daten, um seine Dienste zu verwalten. Dies umfasst das Sammeln, Verwenden und Offenlegen Ihrer Daten, um:\n" +
    "Die Erstellung und den Zugriff auf Ihr Portal-Konto zu ermöglichen;\n" +
    "Ihr Portal-Konto zu verwalten, zu schützen und Sie dabei zu unterstützen.\n",

  title8: "Portal-Benachrichtigungen",
  description8:
    "Das Portal sammelt, verwendet und offenbart Ihre persönlichen Daten, um Benachrichtigungen bereitzustellen. Dies umfasst das Sammeln, Verwenden und Offenlegen Ihrer Daten, um:\n" +
    "Benachrichtigungen von Institutionen zu aktivieren;\n" +
    "Sie per E-Mail oder SMS an Ihre bevorzugte E-Mail-Adresse oder Mobilnummer zu informieren, wenn Sie eine neue Nachricht von einer Institution erhalten;\n" +
    "Andere Sammlungen, Verwendungen und Offenlegungen.\n",

  title9:
    "Das Portal kann Ihre persönlichen Daten auch für folgende Zwecke sammeln, verwenden und offenlegen:",
  description9:
    "Zwecke, für die Sie der Sammlung, Verwendung oder Offenlegung zugestimmt haben; oder\n" +
    "Fälle, in denen das Portal gesetzlich berechtigt ist, Ihre Informationen für solche Zwecke zu sammeln, zu verwenden oder offenzulegen (z. B. strafrechtliche Ermittlungen oder rechtliche Verfahren).\n",

  title10: "Cookies:",
  description10:
    "Das Portal verwendet sitzungsbasierte Cookies. Sitzungsbasierte Cookies sind temporäre Cookie-Dateien, die auf Ihren Computer übertragen und gelöscht werden, wenn Sie Ihren Browser schließen. Diese Cookies werden verwendet, um Funktionen im Portal nach dem Einloggen auszuführen und das Portal zu verbessern, indem anonyme Webnutzungsdaten gesammelt werden. Das Portal verwendet keine dauerhaften Cookies. Dauerhafte Cookies verbleiben auf Ihrer Festplatte, bis sie gelöscht werden oder ablaufen. Das Ablehnen von Cookie-Dateien kann bestimmte Funktionen des Portals beeinträchtigen. Das Portal verwendet keine Cookies, um Ihre Browsing-Aktivitäten außerhalb seiner Dienste zu identifizieren.",

  title11: "Änderungen",
  description11:
    "Änderungen der Informationen in Ihrem Portal-Konto können nur basierend auf den vom Benutzer bereitgestellten Daten vorgenommen werden. Wenn Informationen fehlen oder sich ändern, können Sie eine Anfrage im Portal stellen.",

  title12: "Schlussbestimmungen",
  description12:
    "Das Portal arbeitet auf Grundlage der aktuellen Gesetzgebung der Republik Aserbaidschan und erkennt die Vorrangstellung des Gesetzes bei der Sicherstellung des Betriebs des Portals an.",
};

export const PrivacyPolicyTr = {
  termsAndCondition: "Şartlar ve Koşullar",
  title: "Gizlilik Politikası Bilgileri",

  title1: "Gizlilik Bildirimi",
  description1:
    "“Portal”, “ILoyal” LLC tarafından oluşturulmuştur. Portal, ILoyal LLC tarafından tüzüğüne ve yasalara uygun ticari faaliyetleri yürütmek amacıyla özel olarak kurulmuştur.\n" +
    "Bu gizlilik politikası, Portal’a kayıtlı bireylerin kişisel verilerinin nasıl toplandığını, saklandığını, kullanıldığını ve ifşa edildiğini açıklar. Portal üzerinden sunulan hizmetlerin kullanımı sırasında ayrıca ayrı gizlilik politikası belgeleri de sağlanabilir. Portal’ı kullanarak, kişisel verilerinizin bu gizlilik politikasında açıklandığı şekilde Portal tarafından toplanmasını, kullanılmasını ve ifşa edilmesini kabul etmiş olursunuz. Portal’da sunulan hizmetleri kullandığınızda, ILoyal LLC’nin kişisel verilerinizi hizmet sağlama amaçları için toplamasına, kullanmasına ve ifşa etmesine izin vermiş olursunuz. ILoyal LLC, kişisel verilerinizin işlenmesine ilişkin bir gizlilik politikasına sahip olma hakkını saklı tutar.\n",

  title2: "Kişisel Verilerin Toplanması",
  description2:
    "Portal, hizmetlerini yönetmek amacıyla kişisel verilerinizi toplar. Bu, sağladığınız veriler ve Portal’ın kullanımınıza bağlı olarak oluşturulan verileri içerir.",

  title3: "Sağladığınız kişisel veriler şunları içerir:",
  description3:
    "E-posta adresiniz;\n" +
    "Cep telefonu numaranız;\n" +
    "Şifreniz;\n" +
    "Kimlik kartınızın bireysel tanımlama numarası;\n" +
    "Gizli sorularınız ve cevaplarınız;\n" +
    "Portal mesaj bildirimlerini alma yönteminiz.\n",

  title4: "Portal tarafından oluşturulan veriler şunları içerir:",
  description4:
    "Portal kullanıcı adınız;\n" +
    "Hesabınıza ilişkin aktivite günlükleri, örneğin hesap giriş denemeleri, cihazınız (cihaz adı, modeli, işletim sistemi ve IP adresi dahil);\n" +
    "Hizmetle ilgili aktiviteler ve eriştiğiniz sayfalar ve mesajlar;\n" +
    "Kişisel verilere erişim izni verilen cihazlar;\n" +
    "Kullanıcının belirli cihazına bağlı olarak Portal, aşağıda açıklandığı şekilde cihaz verilerine erişim için belirli izinler gerektirebilir.\n" +
    "\n" +
    "Varsayılan olarak, bu izinler ilgili veriye erişmeden önce kullanıcı tarafından verilmelidir. İzinler, kullanıcı tarafından herhangi bir zamanda iptal edilebilir. İzinleri yönetme prosedürü, kullanıcının cihazına ve yazılımına bağlı olarak değişebilir. Bu tür izinlerin iptali, Portal’ın düzgün çalışmasını etkileyebilir. Kullanıcı aşağıda listelenen izinlerden herhangi birini verdiğinde, ilgili kişisel veriler Portal tarafından işlenebilir (örneğin erişim, değişiklik veya silme):\n",

  title5: "Kamera İzni",
  description5:
    "Kameraya erişmek veya cihazdan fotoğraf ve video çekmek için kullanılır. 'Depolama izni', Portal'ın belirli bir süre boyunca oluşturulan dosyaları saklamasına olanak tanır.",

  title6: "Kişisel Verilerinizi Neden Topluyoruz:",
  description6:
    "Portal, kullanıcılarına Portal bildirimlerini (güncellemeler, promosyonlar, haberler vb.) sağlamak amacıyla kişisel verileri toplar, kullanır ve ifşa eder.",

  title7: "Portal Yönetimi",
  description7:
    "Portal, hizmetlerini yönetmek amacıyla kişisel verilerinizi toplar, kullanır ve ifşa eder. Bu, aşağıdakiler için verilerin toplanmasını, kullanılmasını ve ifşa edilmesini içerir:\n" +
    "Portal hesabınızı oluşturma ve erişim sağlama;\n" +
    "Portal hesabınızı yönetme, koruma ve destekleme.\n",

  title8: "Portal Bildirimleri",
  description8:
    "Portal, bildirimler sağlamak için kişisel verilerinizi toplar, kullanır ve ifşa eder. Bu, aşağıdakiler için verilerin toplanmasını, kullanılmasını ve ifşa edilmesini içerir:\n" +
    "Kurumlardan gelen bildirimleri etkinleştirme;\n" +
    "Kurumdan yeni bir mesaj aldığınızda tercih ettiğiniz e-posta adresi veya cep telefonu numarası üzerinden size e-posta veya SMS ile bilgi verme;\n" +
    "Diğer veri toplama, kullanım ve ifşalar.\n",

  title9:
    "Portal ayrıca kişisel verilerinizi aşağıdaki amaçlar için toplayabilir, kullanabilir ve ifşa edebilir:",
  description9:
    "Toplama, kullanım veya ifşaya izin verdiğiniz amaçlar; veya\n" +
    "Portal’ın yasal olarak bu tür amaçlar için bilgilerinizi toplamasına, kullanmasına veya ifşa etmesine izin verilen durumlar (örneğin, ceza soruşturmaları veya yasal işlemler).\n",

  title10: "Çerezler:",
  description10:
    "Portal, oturum tabanlı çerezler kullanır. Oturum tabanlı çerezler, bilgisayarınıza aktarılan ve tarayıcınızı kapattığınızda silinen geçici çerez dosyalarıdır. Bu çerezler, giriş yaptıktan sonra Portal içinde işlevleri yerine getirmek ve anonim web kullanım verilerini toplayarak Portal’ı geliştirmek için kullanılır. Portal, kalıcı çerezler kullanmaz. Kalıcı çerezler, silinene veya süreleri dolana kadar sabit diskinizde kalır. Çerez dosyalarını reddetmek, Portal’daki bazı işlevleri etkileyebilir. Portal, hizmetleri dışındaki tarama etkinliklerinizi tanımlamak için çerez kullanmaz.",

  title11: "Değişiklikler",
  description11:
    "Portal hesabınızdaki bilgilerdeki değişiklikler yalnızca kullanıcı tarafından sağlanan verilere dayalı olarak yapılabilir. Bilgilerde eksiklik veya değişiklik olması durumunda Portal üzerinden bir talepte bulunabilirsiniz.",

  title12: "Son Hükümler",
  description12:
    "Portal, Azerbaycan Cumhuriyeti'nin yürürlükteki mevzuatına dayanarak faaliyet göstermektedir ve Portal'ın faaliyetlerinin sağlanmasında yasanın üstünlüğünü kabul etmektedir.",
};

export const PrivacyPolicyRu = {
  termsAndCondition: "Условия и положения",
  title: "Информация о политике конфиденциальности",

  title1: "Уведомление о конфиденциальности",
  description1:
    "«Портал» был создан ООО «ILoyal». Портал был создан исключительно ООО «ILoyal» для осуществления коммерческой деятельности в соответствии с его уставом и законодательством.\n" +
    "Настоящая политика конфиденциальности описывает, как персональные данные зарегистрированных на портале лиц собираются, хранятся, используются и раскрываются. Во время использования услуг, предлагаемых на портале, могут быть предоставлены отдельные документы политики конфиденциальности. Используя портал, вы соглашаетесь на сбор, использование и раскрытие ваших персональных данных, как описано в настоящей политике конфиденциальности портала. Используя услуги, предлагаемые на портале, вы соглашаетесь на сбор, использование и раскрытие ваших персональных данных ООО «ILoyal» в целях предоставления услуг. ООО «ILoyal» оставляет за собой право иметь политику конфиденциальности, регулирующую обработку ваших персональных данных.\n",

  title2: "Сбор персональных данных",
  description2:
    "Портал собирает ваши персональные данные для управления своими услугами. Это включает предоставленные вами данные и данные, сгенерированные порталом в связи с вашим использованием.",

  title3: "Предоставленные вами персональные данные включают:",
  description3:
    "Ваш адрес электронной почты;\n" +
    "Ваш номер мобильного телефона;\n" +
    "Ваш пароль;\n" +
    "Идентификационный номер вашей карты;\n" +
    "Ваши секретные вопросы и ответы;\n" +
    "Метод, который вы выбрали для получения уведомлений портала.\n",

  title4: "Сгенерированные порталом данные включают:",
  description4:
    "Ваше имя пользователя на портале;\n" +
    "Журналы активности, связанные с вашей учетной записью портала, такие как попытки входа, ваше устройство (включая название устройства, модель, операционную систему и IP-адрес);\n" +
    "Действия, связанные с услугами, а также страницы и сообщения, к которым вы получаете доступ;\n" +
    "Разрешения устройства для доступа к личным данным;\n" +
    "В зависимости от конкретного устройства пользователя портал может потребовать определенных разрешений на доступ к данным устройства пользователя, как описано ниже.\n" +
    "\n" +
    "По умолчанию эти разрешения должны быть предоставлены пользователем перед доступом к соответствующим данным. Разрешения могут быть отозваны пользователем в любое время. Точная процедура управления разрешениями может зависеть от устройства и программного обеспечения пользователя. Обратите внимание, что отзыв таких разрешений может повлиять на правильное функционирование портала. Если пользователь предоставляет любое из перечисленных ниже разрешений, соответствующие персональные данные могут обрабатываться порталом (например, доступ, изменение или удаление):\n",

  title5: "Разрешение на использование камеры",
  description5:
    "Используется для доступа к камере или для съемки фотографий и видео с устройства. Разрешение на использование хранилища позволяет порталу хранить созданные файлы в течение определенного времени.",

  title6: "Почему мы собираем ваши персональные данные:",
  description6:
    "Портал собирает, использует и раскрывает персональные данные для предоставления уведомлений пользователям портала (обновления, акции, новости и т. д.).",

  title7: "Управление порталом",
  description7:
    "Портал собирает, использует и раскрывает ваши персональные данные для управления своими услугами. Это включает сбор, использование и раскрытие ваших данных для:\n" +
    "Создания и доступа к вашей учетной записи портала;\n" +
    "Управления, защиты и поддержки вашей учетной записи портала.\n",

  title8: "Уведомления портала",
  description8:
    "Портал собирает, использует и раскрывает ваши персональные данные для предоставления уведомлений. Это включает сбор, использование и раскрытие ваших данных для:\n" +
    "Включения уведомлений от учреждений;\n" +
    "Информирования вас по электронной почте или текстовым сообщением на ваш предпочтительный адрес электронной почты или номер мобильного телефона, когда вы получаете новое сообщение от учреждения;\n" +
    "Других сборов, использования и раскрытия.\n",

  title9:
    "Портал также может собирать, использовать и раскрывать ваши персональные данные для:",
  description9:
    "Целей, на которые вы дали согласие на сбор, использование или раскрытие; или\n" +
    "Случаев, когда портал имеет законное право на сбор, использование или раскрытие вашей информации для таких целей (например, уголовные расследования или судебные процессы).\n",

  title10: "Файлы cookie:",
  description10:
    "Портал использует основанные на сессиях файлы cookie. Основанные на сессиях файлы cookie - это временные файлы cookie, передаваемые на ваш компьютер и удаляемые при закрытии браузера. Эти файлы используются для выполнения функций в портале после входа в систему и для улучшения портала путем сбора анонимных данных использования сети. Портал не использует постоянные файлы cookie. Постоянные файлы cookie остаются на вашем жестком диске до их удаления или истечения срока их действия. Отклонение файлов cookie может повлиять на определенные функции портала. Портал не использует файлы cookie для идентификации ваших действий за пределами своих служб.",

  title11: "Изменения",
  description11:
    "Изменения информации в вашей учетной записи портала могут быть сделаны только на основе данных, предоставленных пользователем. Если в информации есть недостатки или изменения, вы можете отправить запрос на портале.",

  title12: "Заключительные положения",
  description12:
    "Портал действует на основании действующего законодательства Республики Азербайджан и признает верховенство закона в обеспечении работы портала.",
};
