import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate, useParams } from "react-router-dom";

import CTAButton, {
  ENUMS as ENUMS_CTA_BUTTON,
} from "components/buttons/cta-button/CTAButton";
import InputControl, {
  ENUMS as ENUMS_INPUT_CONTROL,
} from "components/admin/forms/input-control/InputControl";
import { useValidationSchema } from "utils/hooks/useValidationSchema";
import { ROUTE_NAME } from "utils/constants/routes";
import useAsync from "utils/hooks/useAsync";
import { createReservationGuest } from "utils/api/services/reservation";
import ReservationSuccessModal from "pages/client/reservation/create-reservation/reservation-final-info/reservation-success-modal/ReservationSuccessModal";
import useOutsideClick from "utils/hooks/useOutsideClick";

import "./ReservationFinalInfo.scss";

const ReservationFinalInfo = ({ formData, setFormData }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const schemas = useValidationSchema(t);
  const { reservationGuestCommentSchema } = schemas;
  const { businessId } = useParams();
  const [openSuccessModal, setOpenSuccessModal] = useOutsideClick();
  const {
    execute: executeCreateReservationGuest,
    loading: isCreateReservationLoading,
  } = useAsync(createReservationGuest, {
    onSuccess: async () => {
      setOpenSuccessModal(true);
    },
    onError: () => {},
  });

  const handleCreateReservation = () => {
    const reservationData = {
      guestId: formData.guestId,
      guestName: formData.guestName,
      guestPhone: formData.guestPhone.replace(/[\s()+-]/g, ""),
      guestComment: formData.guestComment,
      guestsCount: formData.guestsCount,
      startDateTime: formData.startDateTime,
      endDateTime: formData.endDateTime,
      tableId: formData.table?.id,
    };
    if (!formData.reservationId) {
      executeCreateReservationGuest(formData.business.id, reservationData);
    }
  };

  const methods = useForm({
    resolver: zodResolver(reservationGuestCommentSchema),
    criteriaMode: "all",
  });
  const closeSuccessModal = () => {
    setOpenSuccessModal(false);
    const destination = businessId
      ? `${ROUTE_NAME.client}${ROUTE_NAME.business}/${businessId}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`
      : `${ROUTE_NAME.client}${ROUTE_NAME.reservation}${ROUTE_NAME.myReservations}`;
    navigate(destination);
  };

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const {
    formState: { errors },
    register,
  } = methods;

  return (
    <div className="ReservationFinalInfo">
      <form
        className="ReservationFinalInfoForm"
        onSubmit={(e) => e.preventDefault()}
      >
        <InputControl
          name="guestComment"
          type="textarea"
          placeholder={t("inputs.addComment")}
          className="ReservationEditorInputTextarea"
          defaultValue={formData?.guestComment}
          textarea
          labelType={ENUMS_INPUT_CONTROL.types.TYPE_B}
          func={{
            ...register("guestComment", {
              onChange: handleInputChange,
            }),
          }}
          hasError={errors.guestComment}
          error={
            <ErrorMessage
              errors={errors}
              name="guestComment"
              render={({ message }) => (
                <p className="h7 error-message">{message}</p>
              )}
            />
          }
        />
      </form>
      <CTAButton
        type={ENUMS_CTA_BUTTON.types.TYPE_R}
        onClick={handleCreateReservation}
        name={t("buttons.confirm")}
        className="ReservationSubmitButton"
        isLoading={isCreateReservationLoading}
      />
      <ReservationSuccessModal
        openSlide={openSuccessModal}
        onClose={closeSuccessModal}
      />
    </div>
  );
};

ReservationFinalInfo.propTypes = {
  formData: PropTypes.shape({
    guestId: PropTypes.number,
    guestName: PropTypes.string,
    guestPhone: PropTypes.string,
    guestComment: PropTypes.string,
    guestsCount: PropTypes.number,
    startDateTime: PropTypes.string,
    endDateTime: PropTypes.string,
    table: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      zoneName: PropTypes.string,
    }),
    reservationId: PropTypes.string,
    business: PropTypes.shape({
      id: PropTypes.number,
    }),
    date: PropTypes.string,
    time: PropTypes.string,
    reservationDuration: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
};

export default ReservationFinalInfo;
