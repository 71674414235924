import React, { useEffect, useState, useRef, useCallback } from "react";
import { useKeyboardControls, Text } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { RigidBody, CuboidCollider } from "@react-three/rapier";
import { useTranslation } from "react-i18next";

import { useTowerGameContext } from "pages/client/fun-zone/games/tower-block/stores/useGame";

function Start({ colors }) {
  const { t } = useTranslation();
  return (
    <>
      <Text
        rotation={[0, 0, 0]}
        position={[0, -0.5, 0.51]}
        scale={0.07}
        textAlign="center"
        maxWidth={0.25}
      >
        {`${t("funZone.tower").toUpperCase()}`}
      </Text>
      <Text
        rotation={[0, Math.PI / 2, 0]}
        position={[0.51, -0.5, 0]}
        scale={0.07}
        textAlign="center"
        maxWidth={0.25}
      >
        {t("funZone.towerGame.blocks")}
      </Text>
      <mesh receiveShadow position-y={-0.5}>
        <boxGeometry args={[1, 0.4, 1]} />
        <meshStandardMaterial color={colors[1]} />
      </mesh>
      <mesh position-y={-1.45}>
        <boxGeometry args={[1, 1.5, 1]} />
        <meshStandardMaterial color={colors[0]} />
      </mesh>
    </>
  );
}

function GenerateBlock({
  position,
  color,
  boxSize,
  idx,
  animate = true,
  block,
}) {
  const blockRef = useRef();
  const [direction, setDirection] = useState(1);
  const speed = 2 + (idx * 1.1) / 30;
  useFrame((state, delta) => {
    if (animate) {
      if (idx % 2 === 0) {
        if (
          (blockRef.current.position.z >= 1.5 && direction > 0) ||
          (blockRef.current.position.z < -1.5 && direction < 0)
        ) {
          setDirection(direction * -1);
        }
        blockRef.current.position.z += speed * delta * direction;
      } else {
        if (
          (blockRef.current.position.x >= 1.5 && direction > 0) ||
          (blockRef.current.position.x < -1.5 && direction < 0)
        ) {
          setDirection(direction * -1);
        }
        blockRef.current.position.x += speed * delta * direction;
      }
      if (!block.dropped)
        block.position = [
          blockRef.current.position.x,
          blockRef.current.position.y,
          blockRef.current.position.z,
        ];
    }
  });

  return (
    <mesh castShadow receiveShadow ref={blockRef} position={position}>
      <boxGeometry args={boxSize} />
      <meshStandardMaterial color={color} />
    </mesh>
  );
}

function GenerateSlice({ slicedMesh }) {
  return (
    <RigidBody>
      <mesh castShadow receiveShadow position={slicedMesh.position}>
        <boxGeometry args={slicedMesh.boxSize} />
        <meshStandardMaterial color={slicedMesh.color} />
      </mesh>
    </RigidBody>
  );
}

function GenerateBounds({ bound }) {
  return (
    <RigidBody type="fixed">
      <CuboidCollider
        args={[bound.size[0] / 2, bound.size[1] / 2, bound.size[2] / 2]}
        position={bound.position}
      />
    </RigidBody>
  );
}

const Level = ({ colors }) => {
  const { t } = useTranslation();
  const [blocks, setBlocks] = useState([]);
  const [slicedMeshes, setSlicedMeshes] = useState([]);
  const [bounds, setBounds] = useState([
    { size: [1, 0.4, 1], position: [0, -0.5, 0] },
  ]);
  const [count, setCount] = useState(0);
  const { phase, addScore, resetScore, stop, setPhase } = useTowerGameContext();

  const [sub] = useKeyboardControls();

  const [smoothedCameraPosition, setSmoothedCameraPosition] = useState(
    new THREE.Vector3(2, 2.5, 2)
  );
  const [smoothedCameraTarget, setSmoothedCameraTarget] = useState(
    new THREE.Vector3()
  );

  const { scene, camera } = useThree();

  const end = () => {
    stop();
    return 0;
  };

  const restart = () => {
    setPhase("start");
    setBlocks([]);
    setBounds([{ size: [1, 0.4, 1], position: [0, -0.5, 0] }]);
    setSlicedMeshes([]);
    setCount(0);
    resetScore();
  };

  const drop = () => {
    if (phase === "start") {
      const generateNextBlock = changeCurBlock(blocks, scene);
      if (generateNextBlock) {
        const newBlock = nextBlock(blocks, count);
        setCount(count + 1);
        setBlocks((blocks) => [...blocks, newBlock]);
      }
    }
  };

  const nextBlock = () => {
    const oldBlock = blocks[blocks.length - 1];
    let positionX = 0;
    let positionZ = 0;
    if (blocks.length > 0) {
      positionX = oldBlock.position[0];
      positionZ = oldBlock.position[2];
    }
    return {
      boxSize: blocks.length > 0 ? oldBlock.boxSize : [1, 0.2, 1],
      position:
        blocks.length % 2 === 0
          ? [positionX, -0.2 + blocks.length * 0.2, -1.5]
          : [-1.5, -0.2 + blocks.length * 0.2, positionZ],
      color: colors[Math.floor(Math.random() * colors.length)],
      idx: count,
      dropped: false,
    };
  };

  const changeCurBlock = () => {
    const minOffset = 0.05;
    if (blocks.length === 1) {
      const curblock = blocks[blocks.length - 1];
      curblock.animate = false;
      curblock.dropped = true;
      const curBlockPosition = curblock.position;
      const curBlockSize = curblock.boxSize;
      const direction = curblock.idx % 2 === 0;

      let offset = Math.abs(curBlockPosition[0]);
      if (direction) {
        offset = Math.abs(curBlockPosition[2]);
      }

      if (offset < minOffset) {
        curblock.position = [0, curBlockPosition[1], 0];
      } else if (offset > curBlockSize[2]) {
        return end();
      } else if (direction && curBlockPosition[2] > 0) {
        setSlicedMeshes([
          ...slicedMeshes,
          {
            boxSize: [curBlockSize[0], curBlockSize[1], offset],
            position: [
              curBlockPosition[0],
              curBlockPosition[1],
              curBlockPosition[2] + 0.5 - curBlockPosition[2] + offset / 2,
            ],
            color: curblock.color,
          },
        ]);
        curblock.position = [
          curBlockPosition[0],
          curBlockPosition[1],
          offset / 2,
        ];
        curblock.boxSize = [
          curBlockSize[0],
          curBlockSize[1],
          1 - curBlockPosition[2],
        ];
      } else if (direction && curBlockPosition[2] < 0) {
        setSlicedMeshes([
          ...slicedMeshes,
          {
            boxSize: [curBlockSize[0], curBlockSize[1], offset],
            position: [
              curBlockPosition[0],
              curBlockPosition[1],
              curBlockPosition[2] + -0.5 - curBlockPosition[2] - offset / 2,
            ],
            color: curblock.color,
          },
        ]);
        curblock.position = [
          curBlockPosition[0],
          curBlockPosition[1],
          -offset / 2,
        ];
        curblock.boxSize = [
          curBlockSize[0],
          curBlockSize[1],
          1 + curBlockPosition[2],
        ];
      }
      setBounds([
        ...bounds,
        {
          size: curblock.boxSize,
          position: curblock.position,
        },
      ]);
      addScore();
    } else if (blocks.length > 0) {
      const curblock = blocks[blocks.length - 1];
      curblock.animate = false;
      curblock.dropped = true;
      const curBlockPosition = curblock.position;
      const curBlockSize = curblock.boxSize;
      const direction = curblock.idx % 2 === 0;

      const basePosition = blocks[blocks.length - 2].position;

      let offset = 0;
      let newSize = 0;
      if (direction) {
        if (curBlockPosition[2] < 0 && basePosition[2] < 0) {
          offset = Math.abs(
            Math.abs(curBlockPosition[2]) - Math.abs(basePosition[2])
          );
        } else if (curBlockPosition[2] > 0 && basePosition[2] > 0) {
          offset = Math.abs(curBlockPosition[2] - basePosition[2]);
        } else {
          offset = Math.abs(curBlockPosition[2]) + Math.abs(basePosition[2]);
        }
        newSize = curBlockSize[2] - Math.abs(offset);
      } else {
        if (curBlockPosition[0] < 0 && basePosition[0] < 0) {
          offset = Math.abs(
            Math.abs(curBlockPosition[0]) - Math.abs(basePosition[0])
          );
        } else if (curBlockPosition[0] > 0 && basePosition[0] > 0) {
          offset = Math.abs(curBlockPosition[0] - basePosition[0]);
        } else {
          offset = Math.abs(curBlockPosition[0]) + Math.abs(basePosition[0]);
        }
        newSize = curBlockSize[0] - Math.abs(offset);
      }
      if (offset < minOffset) {
        curblock.position = [
          basePosition[0],
          curBlockPosition[1],
          basePosition[2],
        ];
      } else if (
        (offset > curBlockSize[0] && !direction) ||
        (offset > curBlockSize[2] && direction)
      ) {
        return end();
      } else if (direction && curBlockPosition[2] > basePosition[2]) {
        setSlicedMeshes([
          ...slicedMeshes,
          {
            boxSize: [curBlockSize[0], curBlockSize[1], offset],
            position: [
              curBlockPosition[0],
              curBlockPosition[1],
              curBlockPosition[2] +
                (basePosition[2] + curBlockSize[2] / 2 - curBlockPosition[2]) +
                offset / 2,
            ],
            color: curblock.color,
          },
        ]);
        curblock.position = [
          curBlockPosition[0],
          curBlockPosition[1],
          curBlockPosition[2] - offset / 2,
        ];
        curblock.boxSize = [curBlockSize[0], curBlockSize[1], newSize];
      } else if (direction && curBlockPosition[2] < basePosition[2]) {
        setSlicedMeshes([
          ...slicedMeshes,
          {
            boxSize: [curBlockSize[0], curBlockSize[1], offset],
            position: [
              curBlockPosition[0],
              curBlockPosition[1],
              curBlockPosition[2] +
                (basePosition[2] - curBlockSize[2] / 2 - curBlockPosition[2]) -
                offset / 2,
            ],
            color: curblock.color,
          },
        ]);
        curblock.position = [
          curBlockPosition[0],
          curBlockPosition[1],
          curBlockPosition[2] + offset / 2,
        ];
        curblock.boxSize = [curBlockSize[0], curBlockSize[1], newSize];
      } else if (!direction && curBlockPosition[0] > basePosition[0]) {
        setSlicedMeshes([
          ...slicedMeshes,
          {
            boxSize: [offset, curBlockSize[1], curBlockSize[2]],
            position: [
              curBlockPosition[0] +
                (basePosition[0] + curBlockSize[0] / 2 - curBlockPosition[0]) +
                offset / 2,
              curBlockPosition[1],
              curBlockPosition[2],
            ],
            color: curblock.color,
          },
        ]);
        curblock.position = [
          curBlockPosition[0] - offset / 2,
          curBlockPosition[1],
          curBlockPosition[2],
        ];
        curblock.boxSize = [newSize, curBlockSize[1], curBlockSize[2]];
      } else if (!direction && curBlockPosition[0] < basePosition[0]) {
        setSlicedMeshes([
          ...slicedMeshes,
          {
            boxSize: [offset, curBlockSize[1], curBlockSize[2]],
            position: [
              curBlockPosition[0] +
                (basePosition[0] - curBlockSize[0] / 2 - curBlockPosition[0]) -
                offset / 2,
              curBlockPosition[1],
              curBlockPosition[2],
            ],
            color: curblock.color,
          },
        ]);
        curblock.position = [
          curBlockPosition[0] + offset / 2,
          curBlockPosition[1],
          curBlockPosition[2],
        ];
        curblock.boxSize = [newSize, curBlockSize[1], curBlockSize[2]];
      }
      setBounds([
        ...bounds,
        {
          size: curblock.boxSize,
          position: curblock.position,
        },
      ]);
      addScore();
    }
    return 1;
  };

  useEffect(() => {
    if (phase === "start" || phase === "restart") {
      restart();
    }
  }, [phase]);

  const stableDrop = useCallback(() => {
    if (drop) drop();
  }, [drop]);

  // useEffect(() => {
  //   const unsubDrop = sub(
  //     (state) => state.drop,
  //     (value) => {
  //       if (value) stableDrop();
  //     }
  //   );
  //
  //   return () => {
  //     unsubDrop();
  //   };
  // }, [stableDrop]);

  useFrame((state, delta) => {
    if (blocks.length > 2) {
      const blockPosition = blocks[blocks.length - 2].position;
      let cameraPosition = new THREE.Vector3(2, blockPosition[1] + 2.5, 2);

      const cameraTarget = new THREE.Vector3(0, blockPosition[1], 0);

      smoothedCameraPosition.lerp(cameraPosition, 5 * delta);
      smoothedCameraTarget.lerp(cameraTarget, 5 * delta);

      state.camera.position.copy(smoothedCameraPosition);
      state.camera.lookAt(smoothedCameraTarget);
    } else {
      setSmoothedCameraPosition(new THREE.Vector3(2, 2.5, 2));
      setSmoothedCameraTarget(new THREE.Vector3());
      camera.position.copy(new THREE.Vector3(2, 2.5, 2));
      camera.lookAt(new THREE.Vector3(0, 0, 0));
    }
  });

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.code === "Space") {
        drop();
      }
    };

    window.addEventListener("click", drop);
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("click", drop);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [drop]);

  return (
    <>
      <Start colors={colors} />
      <Text
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, -0.29, 0]}
        scale={0.06}
        textAlign="center"
        // onPointerMissed={drop}
      >
        {t("funZone.towerGame.clickToPlay")}
      </Text>
      {blocks.map((block, idx) => (
        <GenerateBlock
          key={idx}
          boxSize={block.boxSize}
          position={block.position}
          color={block.color}
          idx={block.idx}
          animate={block.animate}
          block={block}
        />
      ))}
      {slicedMeshes.map((mesh, idx) => (
        <GenerateSlice key={idx} slicedMesh={mesh} />
      ))}
      {bounds.map((bound, idx) => (
        <GenerateBounds key={idx} bound={bound} />
      ))}
    </>
  );
};

export default Level;
