const TermsAndCondition = {
  privacyPolicy: "Privacy Policy",
  title: "TERMS AND CONDITIONS OF PORTAL USAGE",
  description:
    "These Terms and Conditions (“Terms”) are prepared in accordance with the Civil Code of the Republic of Azerbaijan, the laws of the Republic of Azerbaijan “On Electronic Commerce”, “On Electronic Signature and Electronic Document”, “On Telecommunications”, “On Information, Informatization and Protection of Information”, “On Personal Data” and other normative acts adopted in accordance with these laws. These Terms govern the legal regulation of agreements concluded through the website.\n" +
    "These Terms define the conditions for the services obtained (purchased) through the <1>{{site}}</1> site (“Site”) by individuals and legal entities. Before ordering any service via the site, you must READ these terms in FULL!\n" +
    "If you do not accept any of the terms stated herein, it is important to cancel your transactions without making a payment.\n" +
    "For the purposes of these Terms, the <1>{{site}}</1> site will be referred to as the <1>“Site”</1>, the administration of the Site as the <1>“Intermediary”</1>, users who register on the site and receive services as <1>“Buyers”</1>, those who offer their services by placing offers on the site as <1>“Sellers”</1>, and collectively as “Parties”.\n",

  title1: "1. General Provisions",
  description1:
    "1.1. Every Buyer who registers on the Site (including those accessing via QR code scanning or social media accounts) is deemed to have read and accepted the contract and terms of use stated on the site. The terms of the contract are valid for any services obtained through the Site by making payments.\n" +
    "1.2. The Site primarily operates to facilitate e-commerce by mediating agreements between registered Buyers of services and Sellers offering (presenting) their services for sale.\n" +
    "1.3. To use any of the services offered by a Seller listed on the Site, the Buyer must necessarily register on the Site. The Buyer is responsible for the accuracy of the information provided during registration (name, surname, middle name, email address, mobile or landline phone number, etc.). By registering on the Site, the Buyer confirms the accuracy of this information. The email address provided by the Buyer may be used in the future to send any notifications. The validity of this email address falls within the Buyer's responsibility. The Intermediary or the Seller is not liable for non-delivery or delayed delivery of information due to errors in the Buyer’s email address. If the information provided by the Buyer during registration is inaccurate, the Intermediary reserves the right to refuse the service or cancel the Buyer’s registration.\n" +
    "1.4. Upon registration, an electronic cabinet is automatically created on the Site for both Buyers and Sellers.\n" +
    "1.5. The Buyer must ensure the security of their username and password and guarantee that they are not used by others. Otherwise, the Site will not bear any responsibility for any operations that may occur. The login information sent to the Buyer is not stored by the Intermediary. If the Buyer forgets the login or password, they may request the information to be resent to the corresponding email address via the Site. For verification purposes, the Intermediary may request a scanned copy of the Buyer's identity card.\n" +
    "1.6. The Site bears no responsibility to third parties for the actions of Buyers or Sellers.\n" +
    "1.7. The Buyer must be at least 18 years old to enter into a contract with the Seller. Buyers under 18 must be represented by their parents or legal representatives when requesting services from the Seller via the Site. The Site bears no responsibility for disputes arising from misunderstandings between such Buyers and their parents or legal representatives.\n" +
    "1.8. For detailed information about the collection, storage, and use of Buyers’ and Sellers’ personal data, refer to our Privacy Policy.\n",

  title2: "2. Rules for Contract Conclusion",
  description2:
    "2.1. The Buyer reviews the Sellers’ details and other service terms (offer) listed in the relevant announcements section on the site.\n" +
    "2.2. The Buyer can only negotiate the details of the contract with the Seller of their chosen service via the communication section (chat) provided on the Site. If the Buyer and Seller fully agree on the terms, payment (acceptance) is mandatory for the contract to be concluded.\n" +
    "2.3. After agreeing on the terms of the contract, the Buyer must make the payment.\n" +
    "2.4. Once the payment is fully executed, the Seller sends all their requisites and contact details (email, phone number) to the Buyer, and the Parties commence the execution of the contract.\n",

  title3:
    "3. Consent to the Collection and Use of Personal Data (Privacy Terms)",
  description3:
    "3.1. By registering on the Site, the Buyer agrees to the collection and use of the personal data they have voluntarily entered for the purposes of these Terms.\n" +
    "3.2. The Intermediary guarantees the security of the personal data provided by the Buyer and Seller and stored by it, and declares and guarantees that all technical capabilities are in place to prevent such data from being unlawfully obtained or used by third parties.\n" +
    "3.3. Personal data belonging to the Buyer or Seller that is collected and stored by the Intermediary may only be disclosed to competent state authorities and courts in accordance with the provisions and procedures stipulated by law.\n" +
    "3.4. The Site must collect, store, and use the Buyer’s personal data in accordance with the laws of the Republic of Azerbaijan protecting personal data.\n" +
    "3.5. To ensure the efficient use of the Site and compliance with the contract terms by Buyers and Sellers, the Intermediary may create backups of the Buyer’s personal data.\n",

  title4: "4. Security Terms",
  description4:
    "The Buyer must always keep their login and password completely confidential. The Buyer must not disclose their login and password to anyone. If the Buyer suspects or learns that their login and password have been disclosed to others, they must immediately inform the Site’s contact person and change their login and password accordingly. The Buyer’s obligations under this clause are subject to the following exceptions:\n" +
    "•\tThe Buyer’s login and password must be unique and not easily discoverable or accessible by others, including using personal data like date of birth, names of the Buyer or family members, phone numbers, or sequences such as '1234'.\n" +
    "•\tThe security of the Buyer’s data depends on adhering to proper security practices. The Buyer agrees to take all necessary precautions to ensure that no confidential information is disclosed while performing transactions using the services.\n",

  title5: "5. Intellectual Property Relations",
  description5:
    "Objects and information published or posted on the Site and considered intellectual property under national and international legal acts are protected accordingly, and their unauthorized distribution or use is prohibited. Relationships concerning objects posted on the Site by Buyers or Sellers that may be considered intellectual property are governed by the legislation of the Republic of Azerbaijan on intellectual property (copyright, related rights, patents, trademarks, geographical indications, inventions, discoveries, industrial designs, etc.).",

  title6: "6. Force Majeure",
  description6:
    "The Site is not liable for non-performance or delays in services due to natural disasters (fire, floods, earthquakes, storms, hurricanes, or other natural calamities), war, invasion, acts of foreign enemies, hostilities (whether declared or not), civil war, rebellion, revolution, uprisings, seizure of power, confiscation, acts of terrorism, nationalization, government sanctions, bans, embargoes, labor disputes, strikes, lockouts, as well as interruptions or failures in electricity, internet connections, telecommunication services, or other necessary services essential for providing the services.",

  title7: "7. Restrictions on Site Access",
  description7:
    "7.1. The Site may temporarily or permanently suspend access to the site without prior notice in the following cases:\n" +
    "-Violation of contract terms by the Buyer or Seller;\n" +
    "-Necessary actions to ensure the Site’s security;\n" +
    "-Compliance with legal requirements.\n" +
    "The Site is not responsible for any loss or inconvenience resulting from such temporary suspension or termination.\n" +
    "7.2. If a registered Buyer or Seller agrees to make payments for their contract outside the site (e.g., cash, other payment systems, payment terminals, direct card-to-card payments, etc.) or if such an offer is made by one party to the other, the access of such Parties to the Site may be restricted or terminated unilaterally.\n",

  title8: "8. Duration of the Contract",
  description8:
    "By registering on the Site, the Buyer is deemed to have fully accepted all the terms of this agreement. This agreement is concluded for an indefinite period.",

  title9: "9. Applicable Legislation and Dispute Resolution",
  description9:
    "All matters not specifically mentioned in these Terms of Use are governed solely by the legislation of the Republic of Azerbaijan. In the event of any disputes regarding the use of services, entering into contracts for this purpose, or these Terms of Use, the parties must make every effort to resolve such disputes through negotiations. If the parties fail to resolve the disputes within 30 (thirty) days from the start of negotiations, any party may apply to the competent and relevant courts of the Republic of Azerbaijan.",
};

export default TermsAndCondition;

export const TermsAndConditionAz = {
  privacyPolicy: "Məxfilik Siyasəti",
  title: "PORTALDAN  İSTİFADƏ QAYDALARI VƏ ŞƏRTLƏRİ",
  description:
    "Bu Qaydalar və Şərtlər (“Qaydalar”) Azərbaycan Respublikasının Mülki Məcəlləsi, “Elektron ticarət haqqında”, “Elektron imza və elektron sənəd haqqında”, “Telekommunikasiya haqqında”, “İnformasiya, informasiyalaşdırma və informasiyanın mühafizəsi haqqında”, “Fərdi məlumatlar haqqında” Azərbaycan Respublikası qanunlarına və bu qanunlara müvafiq olaraq qəbul edilmiş digər normativ aktlara müvafiq olaraq hazırlanmışdır və sayt vasitəsilə bağlanan müqavilələrin hüquqi tənzimlənməsini nəzərdə tutur. \n" +
    "Bu Qaydalar fiziki və hüquqi şəxslərlə <1>{{site}}</1> saytı (“Sayt”) üzərindən əldə edilən (alınan) xidmətlərin şərtlərini müəyyən edir. Sayt üzərindən hər hansı xidməti sifariş etməzdən əvvəl bu qaydaları TAM OLARAQ oxumaq lazımdır ! \n" +
    "Burada qeyd olunan şərtlərdən hər hansı birini qəbul etmirsinizsə sifariş əməliyyatlarınızı ödəniş etmədən ləğv etməyiniz vacibdir. \n" +
    "Bu Qaydaların məqsədləri üçün <1>{{site}}</1> saytı <1>“Sayt”</1> olaraq, Saytın administrasiyası <1>“Vasitəçi”</1> olaraq, saytda qeydiyyatdan keçərək xidmət alan İstifadəçilər <1>“Alıcı”</1>, saytda təkliflərini yerləşdirərək öz xidmətlərini təklif edən şəxslər <1>“Satıcı”</1>, birlikdə isə “Tərəflər” olaraq qeyd olunacaqdır.\n",

  title1: "1. Ümumi müddəalar",
  description1:
    "1.1. Saytda qeydiyyatdan keçən hər bir Alıcı (QR kod skan edərək və ya sosial media hesabları vasitəsilə daxil olanlar da daxil olmaqla), saytda qeyd olunan müqavilə və istifadə şərtlərini oxumuş və qəbul etmiş sayılır. Müqavilə şərtləri Saytdan ödəniş edərək əldə edilən istənilən xidmətlər üçün keçərlidir.\n" +
    "1.2. Sayt əsasən qeydiyyatdan keçmiş xidmət Alıcıları ilə öz xidmətlərini satışa çıxaran (təqdim edən) Satıclar arasında müvafiq müqavilənin bağlanmasında vasitəçilik yolu ilə elektron ticarətin həyata keçirilməsi məqsədilə fəaliyyət göstərir. \n" +
    "1.3. Saytda göstərilən istənilən Satıcının xidmətlərindən yaralanmaq üçün Alıcı mütləq qaydada saytda qeydiyyatdan keçməlidir. Alıcının qeydiyyatdan keçərkən təqdim etdiyi məlumatlarının hər hansı birində (ad, soyad, ata adı, e-mail ünvan, mobil və ya stasionar telefon nömrəsi və s.) qeyd olunan məlumatların doğru olmaması onun məsuliyyət dairəsindədir. Saytda qeydiyyatdan keçərək xidmət alan Alıcı bu məlumatların doğruluğunu qəbul etmiş sayılır. Alıcının qeyd etdiyi e-mail ünvandan gələcəkdə hər hansı bir bildiriş göndərmək üçün istifadə edilə bilər. Bu e-mail ünvanın etibarlı olması Alıcının məsuliyyət dairəsinə daxildir. Alıcının e-mail ünvanındakı hər hansı bir xətadan dolayı məlumatların çatmaması və ya vaxtında çatmaması Vasitəçinin və ya Satıcının məsuliyyətinə aid deyil. Alıcının qeydiyyat zamanı təqdim etdiyi məlumatların doğru olmaması halında, Vasitəçi xidmətin göstərilməsindən imtina etmək və ya Alıcının qeydiyyatını ləğv etmək hüququna malikdir.\n" +
    "1.4. Saytda qeydiyyatdan keçən Alıcıya və Satıcıya Sayt tərəfindən avtomatik rejimdə elektron kabinet yaradılır.\n" +
    "1.5. Alıcı istifadəçi adı və şifrənin təhlükəsizliyini təmin etməli, onun başqa şəxslər tərəfindən istifadə olunmamasına təminat verməlidir. Əks təqdirdə baş verə biləcək əməliyyatlara görə Sayt heç bir məsuliyyət daşımayacaqdır. Alıcıya göndərilən login barədə məlumatlar Vasitəçidə saxlanılmır. Əgər Alıcı login və ya parolu unudarsa sayt üzərindən müvafiq e-mail ünvanına həmin məlumatların təkrar göndərilməsini tələb edə bilər. Məlumatların yoxlanması məqsədilə Vasitəçi Alıcıdan şəxsiyyət vəsiqəsinin skan olunmuş versiyasını tələb edə bilər. \n" +
    "1.6. Sayt Alıcıların və ya Satıcıların hərəkətlərinə görə üçüncü şəxslər qarşısında heç bir məsuliyyət daşımır.\n" +
    "1.7. Alıcının Satıcı ilə müqavilə bağlaması üçün ən azı 18 yaşı tamam olmalıdır. 18 yaşından azı olan Alıcılar, Sayt vasitəsi ilə Satıcıdan xidmət tələb etməsi üçün onların valideynləri və ya qanuni nümayəndələri tərəfindən təmsil olunmalıdır. Sayt 18 yaşından az olan Alıcı ilə həmin Alıcının valideynləri və ya qanuni                       nümayəndələri arasında anlaşılmazlıqlardan meydana çıxan hallara/hadisələrə/anlaşılmazlıqlara/mübahisələrə görə heç bir cavabdehlik daşımır.\n" +
    "1.8. Alıcının və Satıcının şəxsi məlumatlarının toplanması, saxlanması və istifadəsi ilə bağlı ətraflı məlumat üçün Məxfilik Siyasətimizə baxın.\n",

  title2: "2.  Müqavilə bağlanması qaydaları",
  description2:
    "2.1. Alıcı saytda müvafiq elanlar bölməsində məlumatları yerləşdirilmiş Satıcıların göstəriciləri və digər xidmət şərtləri ilə (oferta) tanış olur.\n" +
    "2.2. Alıcı xidmətini almaq istədiyi Satıcı ilə onun yalnız saytda yerləşdirilən əlaqə bölməsi (chat) vasitəsi ilə müqavilənin detallarına dair danışıqlar apara bilər. Əgər Alıcı və Satıcı şərtlər barədə tam razılığa gəlsələr müqavilə bağlanması üçün yalnız ödəniş edilməsi vacibdir (aksept). \n" +
    "2.3. Müqavilə şərtləri barədə razılığa gəldikdən sonra Alıcı ödəniş etməlidir.\n" +
    "2.4. Ödəniş tam icra edildikdən sonra Satıcı Alıcıya bütün rekvizitlərini və əlaqə vasitələrini (e-mail, telefon nömrəsi)) göndərir və Tərəflərin müqavilənin icrasına başlayırlar. \n",

  title3:
    "3. İstifadəçilərin şəxsi (fərdi) məlumatlarının toplanmasına və istifadəsinə razılığı (Məxfilik şərtləri)",
  description3:
    "3.1. Saytda qeydiyyatdan keçən Alıcı qeydiyyatdan keçərkən öz razılığı ilə daxil etdiyi şəxsi (fərdi) məlumatlarının Vasitəçi tərəfindən yalnız bu Qaydaların məqsədləri üçün toplanmasına və istifadəsinə razılığını bildirmiş olur. \n" +
    "3.2. Vasitəçi Alıcı və Satıcı tərəfindən ona təqdim edilən və özündə toplanmış şəxsi (fərdi) məlumatların təhlükəsizliyinə zəmanət verir və həmin məlumatların qanunsuz olaraq üçüncü şəxslər tərəfindən əldə edilməməsi və istifadə olunmaması üçün hər cür texniki imkana malik olmasını bəyan edir və zəmanət verir.\n" +
    "3.3. Vasitəçidə toplanan və Alıcı və ya Satıcıya məxsus olan şəxsi (fərdi) məlumatlar yalnız qanunvericilikdə göstərilən hallarda və qaydada səlahiyyətli dövlət orqanlarına və məhkəməyə təqdim edilə bilər. \n" +
    "3.4. Sayt Alıcının şəxsi məlumatını Azərbaycan Respublikasının fərdi məlumatların qorunmasını təmin edən qanunvericiliyə uyğun olaraq qəbul etməli, saxlamalı, istifadə etməli və onun hüquqlarını pozmamalıdır.\n" +
    "3.5. Saytın səmərəli istifadəsini və Alıcı və Satıcıların müqavilə şərltərinə əməl edilməsinin təmin edilməsi məqsədilə Vasitəçi Alıcının şəxsi məlumatlarının nüsxələmələrini (“back up”) həyata keçirə bilər.\n",

  title4: "4. Təhlükəsizlik Şərtləri",
  description4:
    "Alıcı özünün login və parolunu hər zaman tam məxfi saxlamalıdır. Alıcı heç kəsə öz login və parolunu açıqlamamalıdır. Əgər Alıcı öz login və parolunu kiməsə məlum olmasından şübhələnərsə və ya bu barədə xəbər tutarsa, Alıcı dərhal Saytın əlaqəli şəxsinə barədə məlumat verməlidir və dərhal login və parolunu müvafiq qaydada dəyişməlidir. Alıcınin bu bənd üzrə öhdəlikləri aşağıda göstərilən istisnalardan asılıdır:\n" +
    "•\tAlıcının login və parolunu özünəməxsus və digər şəxslər tərəfindən asanlıqla tapıla bilməyən və ya əldə oluna bilməyən olmalıdır, o cümlədən: doğum tarixindən və ya Alıcının öz və ya ailə üzvünün adından, Alıcının telefon nömrəsindən və ya “1234” kimi ardıcıl rəqəmlərdən istifadə olunmamalıdır.\n" +
    "•\tXidmətlər üçün Alıcıyə məxsus məlumatların təhlükəsizliyi Alıcının normal təhlükəsiz iş qaydalarına riayət olunmasından asılıdır. Alıcı razılaşır ki, xidmətlər vasitəsilə əməliyyatlar həyata keçirərkən, Alıcı əməliyyat həyata keçirmək məqsədilə xidmətlər vasitəsilə ötürülən məlumatlar daxil olmamaq şərtilə, hər hansı məxfi məlumatın kimsəyə açıqlanmamasının təmin olunması üçün bütün lazımı tədbirlər görür. \n",

  title5: "5. Əqli Mülkiyyət Münasibətləri",
  description5:
    "Saytda dərc edilən və ya yerləşdirilən və milli və beynəlxalq hüquqi aktlarla əqli mülkiyyət obyekti hesab olunan obyekt və məlumatlar müvafiq qaydada qorunur və qanunsuz yayılmasına və istifadəsinə icazə verilmir. Sayta alıcı və ya satıcı tərəfindən yerləşdirilən və əqli mülkiyyət obyektinə aid ola bilən obyektlərlə bağlı münasibətlər Azərbaycan Respublikasının əqli mülkiyyətə dair (müəlliflik hüququ, əlaqəli hüquqlar, patent, əmtəə nişanı, coğrafi göstərici, ixtira, kəsf, sənaye nümunəsi və s.) qanunvericiliyinə müvafiq olaraq tənzimlənir. ",

  title6: "6. Fors-Major Halları",
  description6:
    "Sayt, xidmətlərin yerinə yetirilməməsinə və ya gecikməsinə görə məsuliyyət daşımır, əgər bu hallar təbii fəlakətlər (yanğın, daşqın, zəlzələ, fırtına, qasırğa və ya digər təbii fəlakətlər), müharibə, işğal, xarici düşmənlərin əməlləri, hərbi əməliyyatlar (müharibə elan olunub-olunmamasından asılı olmayaraq), vətəndaş müharibəsi, üsyan, inqilab, qiyam, hakimiyyətin qəsb edilməsi, müsadirə, terror aktları, milliləşdirmə, dövlət sanksiyaları, qadağalar, embarqolar, əmək mübahisələri, tətil, lokaut, eləcə də elektrik enerjisinin, internet bağlantısının, telekommunikasiya xidmətlərinin və ya xidmətlərin göstərilməsi üçün zəruri olan digər xidmətlərin kəsilməsi və ya sıradan çıxması nəticəsində baş vermişdirsə.",

  title7: "7. Sayta girişə qoyula bilən məhdudiyyətlər",
  description7:
    "7.1. Sayt, aşağıdakı hallarda istənilən vaxt əvvəlcədən xəbərdarlıq etmədən sayta girişi müvəqqəti və ya qalıcı olaraq dayandıra bilər:\n" +
    "-Alıcı və ya Satıcı tərəfindən müqavilə şərtlərinin pozulması;\n" +
    "-Saytın təhlükəsizliyinin təmin edilməsi üçün zəruri hallar;\n" +
    "-Qanunvericiliyin tələblərinin yerinə yetirilməsi.\n" +
    "Sayt belə müvəqqəti dayandırma və ya ləğv etmə nəticəsində baş verə biləcək istənilən itki və ya narahatlığa görə heç bir məsuliyyət daşımır.\n" +
    "\n" +
    "7.2. Saytda qeydiyyatdan keçmiş Alıcı və ya Satıcı bağladıqları (bağlayacaqları) müqavilə üzrə ödənişin sayt üzərindən deyil, saytdan kənar qaydada (nağd formada, başqa ödəniş sistemləri üzərindən, pdəniş terminalları vasitəsilə, birbaşa kartlararası ödəmə formasında və s.) edilməsini razılaşdırarlarsa və ya bu barədə bir tərəf digər tərəfə təklif irəli sürərsə, o zaman belə razılaşmanı edən Tərəflərin və ya belə təklif irəli sürən Tərəfin sayta girişi birtərəfli qaydada məhdudlaşdırıla və ya ləğv edilə bilər. \n",

  title8: "8. Müqavilənin müddəti",
  description8:
    "Alıcı saytda qeydiyyatdan keçməklə bu müqavilənin bütün şərtlərini tam qəbul etmiş sayılır. Bu müqavilə müddətsiz olaraq bağlanılır.",

  title9: "9. Tətbiq olunan qanunvericilik və mübahisələrin həlli",
  description9:
    "Bu İstifadə Şərtlərində xüsusi olaraq qeyd olunmayan bütün məsələlər yalnız Azərbaycan Respublikasının qanunvericiliyinə uyğun şəkildə tənzimlənir. Xidmətlərdən istifadə etmə, bu məqsədlə müqavilə bağlamaq və ya bu İstifadə Şərtləri ilə bağlı hər hansı mübahisələr yarandıqda, tərəflər belə mübahisələri danışıqlar yolu ilə həll olunması üçün əlindən gələni etməlidir. Danışıqlar başlayan andan etibarən tərəflər mübahisələri 30 (otuz) gün ərzində həll etməzsə, istənilən tərəf belə mübahisələrlə bağlı Azərbaycan Respublikasının səlahiyyətli və aidiyyatı məhkəmələrinə müraciət edə bilər.",
};

export const TermsAndConditionDe = {
  privacyPolicy: "Datenschutzrichtlinie",
  title: "NUTZUNGSBEDINGUNGEN DES PORTALS",
  description:
    "Diese Nutzungsbedingungen („Bedingungen“) wurden gemäß dem Zivilgesetzbuch der Republik Aserbaidschan, den Gesetzen der Republik Aserbaidschan „Über den elektronischen Handel“, „Über die elektronische Signatur und das elektronische Dokument“, „Über Telekommunikation“, „Über Information, Informatisierung und den Schutz von Informationen“, „Über personenbezogene Daten“ und anderen in Übereinstimmung mit diesen Gesetzen erlassenen normativen Akten erstellt. Diese Bedingungen regeln die rechtliche Regulierung von Vereinbarungen, die über die Website abgeschlossen werden.\n" +
    "Diese Bedingungen definieren die Voraussetzungen für die über die Website <1>{{site}}</1> („Website“) bezogenen (gekauften) Dienstleistungen durch natürliche und juristische Personen. Bevor Sie eine Dienstleistung über die Website bestellen, müssen Sie diese Bedingungen VOLLSTÄNDIG LESEN!\n" +
    "Wenn Sie einer der hier genannten Bedingungen nicht zustimmen, ist es wichtig, Ihre Transaktionen abzubrechen, ohne eine Zahlung vorzunehmen.\n" +
    "Für die Zwecke dieser Bedingungen wird die Website <1>{{site}}</1> als <1>„Website“</1>, die Verwaltung der Website als <1>„Vermittler“</1>, die sich auf der Website registrierenden und Dienstleistungen empfangenden Nutzer als <1>„Käufer“</1>, die ihre Dienstleistungen durch Angebote auf der Website anbieten, als <1>„Verkäufer“</1> und gemeinsam als „Parteien“ bezeichnet.\n",

  title1: "1. Allgemeine Bestimmungen",
  description1:
    "1.1. Jeder Käufer, der sich auf der Website registriert (einschließlich derjenigen, die über QR-Code-Scanning oder Social-Media-Konten darauf zugreifen), gilt als mit dem auf der Website angegebenen Vertrag und den Nutzungsbedingungen einverstanden und hat diese gelesen. Die Vertragsbedingungen gelten für alle über die Website bezogenen Dienstleistungen, die durch Zahlungen erfolgen.\n" +
    "1.2. Die Website dient in erster Linie dazu, den elektronischen Handel zu erleichtern, indem sie Vereinbarungen zwischen registrierten Käufern von Dienstleistungen und Verkäufern, die ihre Dienstleistungen zum Verkauf anbieten, vermittelt.\n" +
    "1.3. Um eine der vom Verkäufer auf der Website angebotenen Dienstleistungen zu nutzen, muss sich der Käufer zwingend auf der Website registrieren. Der Käufer ist für die Richtigkeit der bei der Registrierung angegebenen Informationen (Name, Vorname, Nachname, E-Mail-Adresse, Mobil- oder Festnetznummer usw.) verantwortlich. Durch die Registrierung auf der Website bestätigt der Käufer die Richtigkeit dieser Informationen. Die vom Käufer angegebene E-Mail-Adresse kann künftig für Benachrichtigungen verwendet werden. Die Gültigkeit dieser E-Mail-Adresse liegt in der Verantwortung des Käufers. Der Vermittler oder der Verkäufer haftet nicht für die Nichtzustellung oder verzögerte Zustellung von Informationen aufgrund von Fehlern in der E-Mail-Adresse des Käufers. Wenn die vom Käufer bei der Registrierung angegebenen Informationen ungenau sind, behält sich der Vermittler das Recht vor, die Dienstleistung zu verweigern oder die Registrierung des Käufers zu stornieren.\n" +
    "1.4. Nach der Registrierung wird auf der Website automatisch ein elektronisches Konto sowohl für Käufer als auch für Verkäufer erstellt.\n" +
    "1.5. Der Käufer muss die Sicherheit seines Benutzernamens und Passworts gewährleisten und sicherstellen, dass diese nicht von anderen genutzt werden. Andernfalls übernimmt die Website keine Verantwortung für mögliche Vorgänge. Die dem Käufer zugesandten Login-Daten werden vom Vermittler nicht gespeichert. Sollte der Käufer den Login oder das Passwort vergessen, kann er die Zusendung der Informationen an die entsprechende E-Mail-Adresse über die Website beantragen. Zu Verifizierungszwecken kann der Vermittler eine gescannte Kopie des Personalausweises des Käufers anfordern.\n" +
    "1.6. Die Website haftet nicht gegenüber Dritten für die Handlungen von Käufern oder Verkäufern.\n" +
    "1.7. Der Käufer muss mindestens 18 Jahre alt sein, um einen Vertrag mit dem Verkäufer abzuschließen. Käufer unter 18 Jahren müssen bei der Beantragung von Dienstleistungen beim Verkäufer über die Website von ihren Eltern oder gesetzlichen Vertretern vertreten werden. Die Website übernimmt keine Verantwortung für Streitigkeiten, die aus Missverständnissen zwischen solchen Käufern und ihren Eltern oder gesetzlichen Vertretern entstehen.\n" +
    "1.8. Detaillierte Informationen über die Erhebung, Speicherung und Verwendung der personenbezogenen Daten von Käufern und Verkäufern finden Sie in unserer Datenschutzrichtlinie.\n",

  title2: "2. Regeln für den Vertragsabschluss",
  description2:
    "2.1. Der Käufer prüft die Angaben der Verkäufer und andere Dienstleistungsbedingungen (Angebot), die im entsprechenden Ankündigungsbereich auf der Website aufgeführt sind.\n" +
    "2.2. Der Käufer kann die Details des Vertrags nur mit dem Verkäufer der gewählten Dienstleistung über den Kommunikationsbereich (Chat) auf der Website verhandeln. Wenn sich Käufer und Verkäufer vollständig über die Bedingungen einig sind, ist die Zahlung (Annahme) obligatorisch, damit der Vertrag zustande kommt.\n" +
    "2.3. Nach Einigung über die Vertragsbedingungen muss der Käufer die Zahlung vornehmen.\n" +
    "2.4. Nach vollständiger Zahlung sendet der Verkäufer dem Käufer alle notwendigen Angaben und Kontaktdaten (E-Mail, Telefonnummer), und die Parteien beginnen mit der Vertragsdurchführung.\n",

  title3:
    "3. Zustimmung zur Erhebung und Nutzung personenbezogener Daten (Datenschutzbedingungen)",
  description3:
    "3.1. Durch die Registrierung auf der Website erklärt sich der Käufer mit der Erhebung und Nutzung der von ihm freiwillig eingegebenen personenbezogenen Daten für die Zwecke dieser Bedingungen einverstanden.\n" +
    "3.2. Der Vermittler garantiert die Sicherheit der vom Käufer und Verkäufer bereitgestellten und gespeicherten personenbezogenen Daten und erklärt und garantiert, dass alle technischen Möglichkeiten vorhanden sind, um eine unrechtmäßige Erlangung oder Nutzung dieser Daten durch Dritte zu verhindern.\n" +
    "3.3. Personenbezogene Daten, die dem Käufer oder Verkäufer gehören und vom Vermittler gesammelt und gespeichert werden, dürfen nur den zuständigen staatlichen Behörden und Gerichten gemäß den gesetzlichen Bestimmungen und Verfahren offengelegt werden.\n" +
    "3.4. Die Website muss die personenbezogenen Daten des Käufers in Übereinstimmung mit den Gesetzen der Republik Aserbaidschan zum Schutz personenbezogener Daten erheben, speichern und nutzen.\n" +
    "3.5. Um die effiziente Nutzung der Website und die Einhaltung der Vertragsbedingungen durch Käufer und Verkäufer sicherzustellen, kann der Vermittler Backups der personenbezogenen Daten des Käufers erstellen.\n",

  title4: "4. Sicherheitsbedingungen",
  description4:
    "Der Käufer muss seine Login-Daten und sein Passwort stets vollständig vertraulich behandeln. Der Käufer darf seine Login-Daten und sein Passwort niemandem mitteilen. Sollte der Käufer vermuten oder erfahren, dass seine Login-Daten und sein Passwort anderen offengelegt wurden, muss er den Kontakt der Website umgehend informieren und seine Login-Daten und sein Passwort entsprechend ändern. Die Verpflichtungen des Käufers gemäß dieser Klausel unterliegen den folgenden Ausnahmen:\n" +
    "•\tDie Login-Daten und das Passwort des Käufers müssen eindeutig und für andere nicht leicht auffindbar oder zugänglich sein, einschließlich der Verwendung personenbezogener Daten wie Geburtsdatum, Namen des Käufers oder Familienmitglieder, Telefonnummern oder Folgen wie '1234'.\n" +
    "•\tDie Sicherheit der Daten des Käufers hängt davon ab, dass geeignete Sicherheitspraktiken eingehalten werden. Der Käufer erklärt sich bereit, alle notwendigen Vorsichtsmaßnahmen zu treffen, um sicherzustellen, dass keine vertraulichen Informationen bei der Nutzung der Dienstleistungen offengelegt werden.\n",

  title5: "5. Beziehungen zum geistigen Eigentum",
  description5:
    "Auf der Website veröffentlichte oder eingestellte Objekte und Informationen, die gemäß nationalen und internationalen Rechtsvorschriften als geistiges Eigentum gelten, sind entsprechend geschützt, und ihre unbefugte Verbreitung oder Nutzung ist untersagt. Beziehungen in Bezug auf Objekte, die von Käufern oder Verkäufern auf der Website eingestellt wurden und als geistiges Eigentum gelten können, unterliegen den Rechtsvorschriften der Republik Aserbaidschan über geistiges Eigentum (Urheberrecht, verwandte Rechte, Patente, Marken, geografische Angaben, Erfindungen, Entdeckungen, Industriedesigns usw.).",

  title6: "6. Höhere Gewalt",
  description6:
    "Die Website haftet nicht für die Nichterfüllung oder Verzögerung von Dienstleistungen aufgrund von Naturkatastrophen (Feuer, Überschwemmungen, Erdbeben, Stürme, Hurrikane oder andere Naturkatastrophen), Krieg, Invasion, Handlungen ausländischer Feinde, Feindseligkeiten (ob erklärt oder nicht), Bürgerkrieg, Rebellion, Revolution, Aufstände, Machtübernahme, Enteignung, Terrorismusakte, Verstaatlichung, Regierungssanktionen, Verbote, Embargos, Arbeitskonflikte, Streiks, Aussperrungen sowie Unterbrechungen oder Ausfälle bei Elektrizität, Internetverbindungen, Telekommunikationsdiensten oder anderen wesentlichen Dienstleistungen, die für die Erbringung der Dienstleistungen erforderlich sind.",

  title7: "7. Einschränkungen des Zugriffs auf die Website",
  description7:
    "7.1. Die Website kann den Zugang zur Website vorübergehend oder dauerhaft ohne vorherige Ankündigung aus folgenden Gründen aussetzen:\n" +
    "-Verstoß gegen die Vertragsbedingungen durch den Käufer oder Verkäufer;\n" +
    "-Erforderliche Maßnahmen zur Gewährleistung der Sicherheit der Website;\n" +
    "-Einhaltung gesetzlicher Anforderungen.\n" +
    "Die Website haftet nicht für Verluste oder Unannehmlichkeiten, die sich aus einer solchen vorübergehenden Sperrung oder Kündigung ergeben.\n" +
    "7.2. Wenn ein registrierter Käufer oder Verkäufer zustimmt, Zahlungen für seinen Vertrag außerhalb der Website zu leisten (z. B. Bargeld, andere Zahlungssysteme, Zahlungsterminals, direkte Karten-zu-Karten-Zahlungen usw.) oder wenn eine solche Zahlung von einer Partei der anderen angeboten wird, kann der Zugang solcher Parteien zur Website einseitig eingeschränkt oder gekündigt werden.\n",

  title8: "8. Vertragsdauer",
  description8:
    "Durch die Registrierung auf der Website wird der Käufer als mit allen Bedingungen dieser Vereinbarung vollständig einverstanden angesehen. Diese Vereinbarung wird auf unbestimmte Zeit abgeschlossen.",

  title9: "9. Anwendbares Recht und Streitbeilegung",
  description9:
    "Alle nicht ausdrücklich in diesen Nutzungsbedingungen genannten Angelegenheiten unterliegen ausschließlich den Gesetzen der Republik Aserbaidschan. Im Falle von Streitigkeiten im Zusammenhang mit der Nutzung von Dienstleistungen, dem Abschluss von Verträgen zu diesem Zweck oder diesen Nutzungsbedingungen müssen die Parteien alle Anstrengungen unternehmen, um solche Streitigkeiten durch Verhandlungen beizulegen. Gelingt es den Parteien nicht, die Streitigkeiten innerhalb von 30 (dreißig) Tagen nach Beginn der Verhandlungen beizulegen, kann jede Partei bei den zuständigen und zuständigen Gerichten der Republik Aserbaidschan Klage einreichen.",
};

export const TermsAndConditionTr = {
  privacyPolicy: "Gizlilik Politikası",
  title: "PORTAL KULLANIM ŞARTLARI",
  description:
    "Bu Kullanım Şartları (“Şartlar”), Azerbaycan Cumhuriyeti Medeni Kanunu, Azerbaycan Cumhuriyeti’nin “Elektronik Ticaret Hakkında”, “Elektronik İmza ve Elektronik Belge Hakkında”, “Telekomünikasyon Hakkında”, “Bilgi, Bilişim ve Bilginin Korunması Hakkında”, “Kişisel Veriler Hakkında” ve bu yasalara uygun olarak kabul edilen diğer normatif düzenlemeler çerçevesinde hazırlanmıştır. Bu Şartlar, web sitesi aracılığıyla yapılan sözleşmelerin yasal düzenlemesini yönetir.\n" +
    "Bu Şartlar, bireyler ve tüzel kişiler tarafından <1>{{site}}</1> web sitesi (“Site”) üzerinden alınan (satın alınan) hizmetlerin koşullarını tanımlar. Siteden herhangi bir hizmet sipariş etmeden önce bu şartları TAMAMEN OKUMALISINIZ!\n" +
    "Burada belirtilen şartlardan herhangi birini kabul etmiyorsanız, ödeme yapmadan işlemlerinizi iptal etmeniz önemlidir.\n" +
    "Bu Şartlar çerçevesinde, <1>{{site}}</1> sitesi <1>“Site”</1>, Sitenin yönetimi <1>“Aracı”</1>, siteye kayıt olan ve hizmet alan kullanıcılar <1>“Alıcılar”</1>, hizmetlerini siteye teklif sunarak sağlayanlar <1>“Satıcılar”</1>, ve topluca “Taraflar” olarak anılacaktır.\n",

  title1: "1. Genel Hükümler",
  description1:
    "1.1. Siteye kayıt olan her Alıcı (QR kod tarayarak veya sosyal medya hesaplarıyla erişim dahil), sitede belirtilen sözleşmeyi ve kullanım şartlarını okumuş ve kabul etmiş sayılır. Sözleşme şartları, site üzerinden yapılan tüm hizmetler için geçerlidir.\n" +
    "1.2. Site, hizmet alıcılarıyla hizmetlerini sunan satıcılar arasında anlaşmaları aracılık ederek e-ticareti kolaylaştırmak amacıyla faaliyet göstermektedir.\n" +
    "1.3. Satıcının Site üzerinde sunduğu herhangi bir hizmeti kullanabilmek için Alıcı mutlaka Siteye kayıt olmalıdır. Alıcı, kayıt sırasında verdiği bilgilerin (ad, soyad, e-posta adresi, telefon numarası vb.) doğruluğundan sorumludur. Siteye kayıt olarak Alıcı, bu bilgilerin doğruluğunu teyit eder. Alıcı tarafından verilen e-posta adresi, gelecekte yapılacak bildirimler için kullanılabilir. Bu e-posta adresinin geçerliliği Alıcı’nın sorumluluğundadır. Kayıt sırasında verilen bilgilerin yanlış olması durumunda Aracı, hizmeti reddetme veya Alıcı’nın kaydını iptal etme hakkını saklı tutar.\n" +
    "1.4. Kayıttan sonra hem Alıcılar hem de Satıcılar için Sitede otomatik olarak bir elektronik hesap oluşturulur.\n" +
    "1.5. Alıcı, kullanıcı adı ve şifresinin güvenliğini sağlamak ve bunların başkaları tarafından kullanılmamasını temin etmek zorundadır. Aksi takdirde, Site meydana gelebilecek işlemlerden sorumlu tutulamaz. Alıcıya gönderilen giriş bilgileri Aracı tarafından saklanmaz. Alıcı, giriş bilgilerini veya şifresini unutursa, Site üzerinden ilgili e-posta adresine yeniden gönderilmesini talep edebilir. Doğrulama amacıyla Aracı, Alıcı’nın kimlik kartının taranmış bir kopyasını talep edebilir.\n" +
    "1.6. Site, Alıcılar veya Satıcıların üçüncü şahıslara yönelik eylemlerinden sorumlu değildir.\n" +
    "1.7. Alıcı, Satıcı ile sözleşme yapabilmek için en az 18 yaşında olmalıdır. 18 yaşından küçük alıcılar, Site üzerinden Satıcıdan hizmet talep ederken ebeveynleri veya yasal temsilcileri tarafından temsil edilmelidir. Site, bu tür Alıcılar ile ebeveynleri veya yasal temsilcileri arasındaki yanlış anlamalardan kaynaklanan ihtilaflardan sorumlu değildir.\n" +
    "1.8. Alıcılar ve Satıcıların kişisel verilerinin toplanması, saklanması ve kullanımı hakkında detaylı bilgi için Gizlilik Politikamıza bakınız.\n",

  title2: "2. Sözleşme Kuralları",
  description2:
    "2.1. Alıcı, Sitedeki ilgili ilanlar bölümünde listelenen Satıcıların detaylarını ve diğer hizmet şartlarını (teklif) inceler.\n" +
    "2.2. Alıcı, seçtiği hizmetin Satıcısı ile sözleşme detaylarını yalnızca Sitede sunulan iletişim bölümü (sohbet) aracılığıyla müzakere edebilir. Alıcı ve Satıcı koşullarda tamamen anlaştıkları takdirde, sözleşmenin kurulması için ödeme (kabul) zorunludur.\n" +
    "2.3. Sözleşme şartlarında anlaşmaya varıldıktan sonra Alıcı ödemeyi yapmak zorundadır.\n" +
    "2.4. Ödemenin tamamen yapılmasının ardından Satıcı, Alıcıya tüm gerekli bilgileri ve iletişim bilgilerini (e-posta, telefon numarası) gönderir ve taraflar sözleşmenin yürütülmesine başlar.\n",

  title3:
    "3. Kişisel Verilerin Toplanması ve Kullanımına Onay (Gizlilik Şartları)",
  description3:
    "3.1. Siteye kayıt olan Alıcı, bu Şartlar kapsamında gönüllü olarak girdiği kişisel verilerin toplanmasına ve kullanılmasına onay verir.\n" +
    "3.2. Aracı, Alıcı ve Satıcı tarafından sağlanan ve kendisi tarafından saklanan kişisel verilerin güvenliğini garanti eder ve bu verilerin üçüncü şahıslar tarafından yasa dışı bir şekilde elde edilmesini veya kullanılmasını önlemek için tüm teknik imkanların mevcut olduğunu beyan ve garanti eder.\n" +
    "3.3. Aracı tarafından toplanan ve saklanan Alıcı veya Satıcıya ait kişisel veriler, yalnızca yasal prosedürler uyarınca yetkili devlet makamlarına ve mahkemelere açıklanabilir.\n" +
    "3.4. Site, Alıcı’nın kişisel verilerini Azerbaycan Cumhuriyeti’nin kişisel verilerin korunmasına ilişkin yasalarına uygun olarak toplamak, saklamak ve kullanmak zorundadır.\n" +
    "3.5. Sitenin etkin kullanımı ve Alıcılar ile Satıcıların sözleşme şartlarına uyumunu sağlamak amacıyla Aracı, Alıcı’nın kişisel verilerinin yedeklerini oluşturabilir.\n",

  title4: "4. Güvenlik Şartları",
  description4:
    "Alıcı, giriş bilgilerini ve şifresini tamamen gizli tutmalıdır. Alıcı, giriş bilgilerini ve şifresini kimseye ifşa etmemelidir. Giriş bilgilerinin ve şifrenin başkalarına ifşa edildiğinden şüphelenir veya öğrenirse, Site’nin iletişim kişisini derhal bilgilendirmeli ve giriş bilgilerini ve şifresini buna uygun şekilde değiştirmelidir. Alıcı’nın bu madde kapsamındaki yükümlülükleri şu istisnalara tabidir:\n" +
    "•\tAlıcı’nın giriş bilgileri ve şifresi benzersiz olmalı ve başkaları tarafından kolayca bulunamamalıdır. Doğum tarihi, Alıcı veya aile üyelerinin isimleri, telefon numaraları veya '1234' gibi diziler gibi kişisel veriler kullanılmamalıdır.\n" +
    "•\tAlıcı’nın verilerinin güvenliği, uygun güvenlik uygulamalarına bağlıdır. Alıcı, hizmetleri kullanırken hiçbir gizli bilginin ifşa edilmemesini sağlamak için gerekli tüm önlemleri almayı kabul eder.\n",

  title5: "5. Fikri Mülkiyet İlişkileri",
  description5:
    "Sitede yayımlanan veya yayınlanan ve ulusal ve uluslararası yasal düzenlemelere göre fikri mülkiyet olarak kabul edilen nesneler ve bilgiler uygun şekilde korunmaktadır ve bunların izinsiz dağıtımı veya kullanımı yasaktır. Alıcılar veya Satıcılar tarafından Sitede yayınlanan ve fikri mülkiyet olarak kabul edilebilecek nesnelerle ilgili ilişkiler, Azerbaycan Cumhuriyeti’nin fikri mülkiyet mevzuatı (telif hakları, ilgili haklar, patentler, ticari markalar, coğrafi işaretler, icatlar, keşifler, endüstriyel tasarımlar vb.) tarafından düzenlenir.",

  title6: "6. Mücbir Sebep",
  description6:
    "Site, doğal afetler (yangın, sel, deprem, fırtına, kasırga veya diğer doğal felaketler), savaş, istila, yabancı düşman eylemleri, düşmanlıklar (ilan edilmiş olsun veya olmasın), iç savaş, isyan, devrim, ayaklanma, iktidarın ele geçirilmesi, el koyma, terör eylemleri, kamulaştırma, hükümet yaptırımları, yasaklar, ambargolar, iş anlaşmazlıkları, grevler, lokavtlar ve elektrik, internet bağlantıları, telekomünikasyon hizmetleri veya hizmetlerin sağlanması için gerekli diğer hizmetlerdeki kesintiler veya arızalar nedeniyle hizmetlerin yerine getirilmemesi veya gecikmesinden sorumlu tutulamaz.",

  title7: "7. Siteye Erişim Kısıtlamaları",
  description7:
    "7.1. Site, aşağıdaki durumlarda siteye erişimi önceden haber vermeksizin geçici veya kalıcı olarak askıya alabilir:\n" +
    "-Alıcı veya Satıcı tarafından sözleşme şartlarının ihlali;\n" +
    "-Sitenin güvenliğini sağlamak için gerekli önlemler;\n" +
    "-Yasal gerekliliklere uyum.\n" +
    "Site, bu tür geçici askıya alma veya fesih nedeniyle meydana gelen kayıplardan veya rahatsızlıklardan sorumlu değildir.\n" +
    "7.2. Kayıtlı bir Alıcı veya Satıcı, sözleşme için ödemeleri Site dışında yapmayı kabul ederse (örneğin, nakit, diğer ödeme sistemleri, ödeme terminalleri, doğrudan karttan karta ödemeler vb.) veya böyle bir teklif bir tarafça diğerine yapılırsa, bu tür Tarafların Siteye erişimi tek taraflı olarak kısıtlanabilir veya sonlandırılabilir.\n",

  title8: "8. Sözleşmenin Süresi",
  description8:
    "Siteye kayıt olan Alıcı, bu sözleşmenin tüm şartlarını tamamen kabul etmiş sayılır. Bu sözleşme, belirsiz bir süre için yapılmıştır.",

  title9: "9. Uygulanabilir Hukuk ve Uyuşmazlıkların Çözümü",
  description9:
    "Bu Kullanım Şartlarında açıkça belirtilmeyen tüm konular, yalnızca Azerbaycan Cumhuriyeti yasalarına tabidir. Hizmetlerin kullanımı, bu amaçla sözleşme yapılması veya bu Kullanım Şartlarına ilişkin ortaya çıkabilecek uyuşmazlık durumunda, taraflar bu tür uyuşmazlıkları müzakereler yoluyla çözmek için azami çabayı göstermelidir. Taraflar, müzakerelerin başlamasından itibaren 30 (otuz) gün içinde anlaşmazlıkları çözemediği takdirde, her taraf Azerbaycan Cumhuriyeti’nin yetkili ve ilgili mahkemelerine başvurabilir.",
};

export const TermsAndConditionRu = {
  privacyPolicy: "Политика конфиденциальности",
  title: "УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ПОРТАЛА",
  description:
    "Настоящие Условия использования («Условия») подготовлены в соответствии с Гражданским кодексом Азербайджанской Республики, законами Азербайджанской Республики «Об электронной коммерции», «Об электронной подписи и электронном документе», «О телекоммуникациях», «Об информации, информатизации и защите информации», «О персональных данных» и другими нормативными актами, принятыми в соответствии с указанными законами. Эти Условия регулируют правовые отношения по договорам, заключаемым через веб-сайт.\n" +
    "Настоящие Условия определяют условия предоставления (приобретения) услуг физическими и юридическими лицами через веб-сайт <1>{{site}}</1> («Сайт»). Перед заказом любой услуги через сайт ВЫ ДОЛЖНЫ ПОЛНОСТЬЮ ПРОЧИТАТЬ эти условия!\n" +
    "Если вы не принимаете какие-либо из указанных условий, важно отменить транзакцию до осуществления оплаты.\n" +
    "Для целей настоящих Условий веб-сайт <1>{{site}}</1> будет именоваться <1>«Сайт»</1>, администрация сайта — <1>«Посредник»</1>, пользователи, зарегистрировавшиеся на сайте и получающие услуги — <1>«Покупатели»</1>, лица, предлагающие свои услуги, размещая предложения на сайте, — <1>«Продавцы»</1>, а вместе — «Стороны».\n",

  title1: "1. Общие положения",
  description1:
    "1.1. Каждый Покупатель, зарегистрировавшийся на Сайте (включая тех, кто получает доступ через сканирование QR-кода или учетные записи в социальных сетях), считается ознакомившимся и согласившимся с условиями договора и использования, указанными на сайте. Условия договора применимы ко всем услугам, предоставленным через Сайт после оплаты.\n" +
    "1.2. Сайт предназначен для упрощения электронной коммерции путем посредничества в заключении договоров между зарегистрированными Покупателями услуг и Продавцами, предлагающими свои услуги.\n" +
    "1.3. Для использования любой из услуг, предлагаемых Продавцом на Сайте, Покупатель должен обязательно зарегистрироваться на Сайте. Покупатель несет ответственность за точность предоставленных при регистрации данных (имя, фамилия, адрес электронной почты, номер мобильного или стационарного телефона и т.д.). Регистрация на Сайте подтверждает правильность этих данных. Адрес электронной почты, указанный Покупателем, может быть использован для отправки уведомлений в будущем. Покупатель несет ответственность за корректность указанного адреса электронной почты. Посредник или Продавец не несут ответственности за неполучение или задержку информации по причине ошибок в адресе электронной почты Покупателя. Если предоставленная информация является неточной, Посредник оставляет за собой право отказать в услуге или аннулировать регистрацию Покупателя.\n" +
    "1.4. После регистрации на Сайте автоматически создается электронный кабинет как для Покупателей, так и для Продавцов.\n" +
    "1.5. Покупатель обязан обеспечить безопасность своего имени пользователя и пароля и гарантировать, что они не будут использованы другими лицами. В противном случае Сайт не несет ответственности за возможные операции. Логин и пароль, отправленные Покупателю, не сохраняются Посредником. Если Покупатель забыл логин или пароль, он может запросить их повторную отправку на указанный адрес электронной почты через Сайт. Для целей верификации Посредник может запросить сканированную копию удостоверения личности Покупателя.\n" +
    "1.6. Сайт не несет ответственности перед третьими лицами за действия Покупателей или Продавцов.\n" +
    "1.7. Покупатель должен быть не моложе 18 лет, чтобы заключить договор с Продавцом. Покупатели младше 18 лет должны быть представлены своими родителями или законными представителями при заказе услуг у Продавца через Сайт. Сайт не несет ответственности за споры, возникающие из-за недоразумений между такими Покупателями и их родителями или законными представителями.\n" +
    "1.8. Для получения подробной информации о сборе, хранении и использовании персональных данных Покупателей и Продавцов ознакомьтесь с нашей Политикой конфиденциальности.\n",

  title2: "2. Правила заключения договора",
  description2:
    "2.1. Покупатель изучает информацию о Продавцах и другие условия предоставления услуг (предложение), указанные в соответствующем разделе объявлений на Сайте.\n" +
    "2.2. Покупатель может согласовывать условия договора только с Продавцом выбранной услуги через раздел связи (чат), предоставленный на Сайте. Если Покупатель и Продавец полностью договорились об условиях, обязательным условием заключения договора является оплата (акцепт).\n" +
    "2.3. После согласования условий договора Покупатель обязан произвести оплату.\n" +
    "2.4. После полной оплаты Продавец отправляет Покупателю свои реквизиты и контактные данные (электронная почта, номер телефона), и стороны приступают к выполнению договора.\n",

  title3:
    "3. Согласие на сбор и использование персональных данных (Условия конфиденциальности)",
  description3:
    "3.1. Регистрируясь на Сайте, Покупатель соглашается на сбор и использование предоставленных им персональных данных в целях настоящих Условий.\n" +
    "3.2. Посредник гарантирует безопасность персональных данных Покупателя и Продавца, хранящихся у него, и заявляет, что обладает всеми техническими возможностями для предотвращения незаконного получения или использования этих данных третьими лицами.\n" +
    "3.3. Персональные данные Покупателя или Продавца, собранные и хранящиеся у Посредника, могут быть раскрыты только компетентным государственным органам и судам в соответствии с законами и установленными процедурами.\n" +
    "3.4. Сбор, хранение и использование персональных данных Покупателей и Продавцов должно осуществляться в соответствии с законодательством Азербайджанской Республики о защите персональных данных.\n" +
    "3.5. Для обеспечения эффективного использования Сайта и соблюдения условий договора Покупателями и Продавцами Посредник может создавать резервные копии персональных данных Покупателей.\n",

  title4: "4. Условия безопасности",
  description4:
    "Покупатель обязан всегда хранить свои логин и пароль в строгой конфиденциальности. Покупатель не должен передавать свои логин и пароль третьим лицам. Если Покупатель подозревает или узнает, что его логин и пароль стали известны третьим лицам, он должен немедленно уведомить контактное лицо Сайта и изменить свои логин и пароль. Обязанности Покупателя по этому пункту подлежат следующим исключениям:\n" +
    "• Логин и пароль Покупателя должны быть уникальными и не должны быть легко обнаружены третьими лицами, включая использование личных данных, таких как дата рождения, имена Покупателя или членов его семьи, номера телефонов или последовательности вроде '1234'.\n" +
    "• Безопасность данных Покупателя зависит от соблюдения надлежащих мер безопасности. Покупатель обязуется принимать все необходимые меры предосторожности, чтобы гарантировать, что никакая конфиденциальная информация не будет раскрыта при использовании услуг.\n",

  title5: "5. Интеллектуальная собственность",
  description5:
    "Объекты и информация, опубликованные или размещенные на Сайте и признаваемые интеллектуальной собственностью в соответствии с национальными и международными законодательными актами, защищены соответствующим образом, и их несанкционированное распространение или использование запрещено. Отношения, касающиеся объектов, размещенных Покупателями или Продавцами на Сайте и признаваемых интеллектуальной собственностью, регулируются законодательством Азербайджанской Республики об интеллектуальной собственности (авторское право, смежные права, патенты, товарные знаки, географические указания, изобретения, открытия, промышленные образцы и т.д.).",

  title6: "6. Форс-мажор",
  description6:
    "Сайт не несет ответственности за невыполнение или задержку в предоставлении услуг по причинам природных катастроф (пожары, наводнения, землетрясения, ураганы и т.д.), войн, вторжений, действий иностранных врагов, враждебных действий (независимо от того, объявлены ли они), гражданских войн, восстаний, революций, захвата власти, актов терроризма, национализации, правительственных санкций, запретов, эмбарго, трудовых конфликтов, забастовок, локаутов, а также сбоев или нарушений в работе электроэнергии, интернет-соединений, телекоммуникаций или других необходимых услуг.",

  title7: "7. Ограничения доступа к Сайту",
  description7:
    "7.1. Сайт может временно или постоянно приостановить доступ к сайту без предварительного уведомления в следующих случаях:\n" +
    "- Нарушение условий договора Покупателем или Продавцом;\n" +
    "- Необходимость обеспечения безопасности Сайта;\n" +
    "- Соблюдение требований законодательства.\n" +
    "Сайт не несет ответственности за потери или неудобства, вызванные таким временным приостановлением или прекращением доступа.\n" +
    "7.2. Если зарегистрированный Покупатель или Продавец соглашаются на оплату за договор вне Сайта (например, наличными, через другие платежные системы, терминалы, прямые переводы с карты на карту и т.д.) или если такое предложение делает одна сторона другой, доступ таких сторон к Сайту может быть ограничен или прекращен в одностороннем порядке.\n",

  title8: "8. Срок действия договора",
  description8:
    "Зарегистрировавшись на Сайте, Покупатель считается полностью принявшим все условия настоящего соглашения. Настоящее соглашение заключается на неопределенный срок.",

  title9: "9. Применимое законодательство и разрешение споров",
  description9:
    "Все вопросы, не урегулированные прямо настоящими Условиями использования, регулируются исключительно законодательством Азербайджанской Республики. В случае возникновения споров, связанных с использованием услуг, заключением договоров для этих целей или настоящими Условиями использования, стороны обязаны приложить все усилия для разрешения таких споров путем переговоров. Если стороны не разрешат споры в течение 30 (тридцати) дней с начала переговоров, любая из сторон вправе обратиться в компетентные и соответствующие суды Азербайджанской Республики.",
};
