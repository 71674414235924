import  React,{useRef } from "react";
import { useFrame } from "@react-three/fiber"

export default function Lights() {
    const light = useRef()

    useFrame((state) => {
        light.current.position.y = state.camera.position.y
        light.current.target.position.y = state.camera.position.y - 2.5
        light.current.target.updateMatrixWorld()
    })

    return <>
        <directionalLight 
            ref={light}
            castShadow
            position={[5,2.5,-5]}
            intensity={1.5}
        />
        <ambientLight intensity={1}/>
    </>
}