import { useMemo } from "react";
import { CHART_DATA_KEYS } from "pages/admin/admin-pages/admin-dashboard/admin-dash-feedbacks/AdminDashFeedbacks";
import { getFeedbacksStarsChartData } from "utils/helper-functions/dashboard/feedbackHelpers";

export const useFeedbackChartData = (
  feedbacksStarsData,
  type,
  keys = CHART_DATA_KEYS
) => {
  return useMemo(() => {
    const chartData = getFeedbacksStarsChartData(
      feedbacksStarsData,
      type,
      keys
    );
    return {
      meta: {
        type: "monotone",
        xAxisDataKey: keys.xAxisDataKey,
        data: [{ dataKey: keys.starCount }],
      },
      data: chartData,
    };
  }, [feedbacksStarsData, type, keys]);
};
