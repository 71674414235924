import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { resetGuestStore } from "redux/slices/guestStore";
import { createGuestAsync } from "redux/actions/guestAction";
import { resetBasketStore } from "redux/slices/basketStore";
import { LOCAL_STORAGE_VARIABLES } from "utils/constants/global";
import useTransferOrderSourceToTarget from "./useTransferOrderSourceToTarget";
import { useRef } from "react";

const useLogoutGuest = () => {
  const dispatch = useDispatch();
  const { transferOrderSourceToTarget } = useTransferOrderSourceToTarget();
  const guestHasSignedUp = useSelector(
    (state) => state[STORE_NAMES.guest]?.hasSignedUp
  );
  const guestId = useSelector((state) => state[STORE_NAMES.guest]?.id);

  const authenticatedGuestId = useRef(guestId);

  const logoutGuest = async () => {
    localStorage.removeItem(LOCAL_STORAGE_VARIABLES.accessTokenForGuest);
    if (guestHasSignedUp) {
      await dispatch(resetBasketStore());
      await dispatch(resetGuestStore());
      const response = await dispatch(createGuestAsync());
      await transferOrderSourceToTarget({
        sourceId: authenticatedGuestId.current,
        targetId: response.payload.id,
      });
    }
  };

  return { logoutGuest };
};

export default useLogoutGuest;
