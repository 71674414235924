import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import SectionHeader from "components/admin/elements/section-header/SectionHeader";
import AddTagButton from "components/admin/buttons/add-tag-button/AddTagButton";
import TagCard from "components/admin/cards/tag-card/TagCard";
import useOutsideClick from "utils/hooks/useOutsideClick";
import AddItemModal from "components/admin/cards/add-item-modal/AddItemModal";
import AddNewButton from "components/admin/buttons/add-new-button/AddNewButton";
import Search, { ENUMS as SEARCH_ENUMS } from "components/forms/search/Search";
import Table from "components/admin/elements/table/Table";
import WarningIcon, {
  ENUMS as WARNING_ICON_ENUMS,
} from "components/admin/elements/warning-icon/WarningIcon";
import AddInventoryItemModal from "pages/admin/admin-pages/admin-inventory/add-inventory-item-modal/AddInventoryItemModal";
import { useDispatch, useSelector } from "react-redux";
import { STORE_NAMES } from "utils/constants/redux";
import { ReactComponent as SortArrowDefault } from "assets/icons/admin-dashboard/sortArrowDefault.svg";
import { ReactComponent as SortArrowDesc } from "assets/icons/admin-dashboard/sortArrowDesc.svg";
import { ReactComponent as SortArrowAsc } from "assets/icons/admin-dashboard/sortArrowAsc.svg";
import { TableV2 } from "components/table/TableV2";
import { addNewCategory, ChangeNameOfCategory, deleteInventoryCategory, addInventoryItemToCategory, deleteCategoryItem, editInventoryItemToCategory } from "redux/slices/inventoryStore";
import EmptyTableIcon from "assets/icons/admin-dashboard/EmptyTable.svg";
import DeleteButton from "components/buttons/delete-button/DeleteButton";
import EditButton from "components/admin/buttons/edit-button/EditButton";
import Pagination from "components/pagination/Pagination";
import ElipsL from "assets/icons/inventory/elipsleft.svg";
import ElipsR from "assets/icons/inventory/elipsright.svg";

import "./AdminInventory.scss";

const TABLE_KEYS = [
  {
    value: "name",
    valueForTranslation: "inventory.productname",
    isSortable: false,
  },
  {
    value: "category",
    valueForTranslation: "dashboard.table.menuStatisticsTable.category",
    isSortable: false,
  },
  {
    value: "stock",
    valueForTranslation: "inventory.stock",
    isSortable: true,
  },
];

const meta = {
  breakpoints: [
    {
      BreakPointsRange: {
        max: -1,
        min: 800,
      },
      cellDisplayAndProportions: [
        20,
        20,
        60,
      ],
    },
    {
      BreakPointsRange: {
        max: 799,
        min: 20,
      },
      cellDisplayAndProportions: [
        null,
        40,
        60,
      ],
    }
  ]
};

const AdminInventory = () => {
  const { t } = useTranslation();
  const { data: categories } = useSelector((state) => state[STORE_NAMES.inventory]);
  const dispatch = useDispatch();

  const [openSlideCategory, setOpenSlideCategory, mainElementRefCategory] = useOutsideClick();
  const [openSlideItem, setOpenSlideItem, mainElementRefItem] = useOutsideClick();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(
    {
      name: false,
      amount: false,
      warningAmount: false,
      unit: false,
      category: false,
    },
  )
  const [showError, setShowError] = useState(false);
  const [sortType, setSortType] = useState("");
  const [currentPage, setActivePage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const pagesize = 2;

  const handleOnAddCategory = () => {
    setOpenSlideCategory(true);
    setSelectedCategory(null);
  };

  const handleOnEditCategory = (category) => {
    setSelectedCategory(category);
    setOpenSlideCategory(true);
  };

  const createRows = (rowsData) => {
    const rows = rowsData?.map((item, index) => [
      {
        element: (
          <div key={index} className="tableRowCell">
            <p className="Medium">{item?.name}</p>
          </div>
        ),
      },
      {
        element: (
          <div key={index} className="tableRowCell">
            <p className="Medium">{activeCategory?.name}</p>
          </div>
        ),
      },
      {
        element: (
          <div key={index} className="tableRowCell">
            <div className="AmountContainer">
              <p className="Medium">{`${item?.amount} ${item?.unit?.name}`}</p>
              {item?.amount < item?.warningAmount && (
                <WarningIcon
                  type={WARNING_ICON_ENUMS.WARNING}
                  message={t("inventory.warningAmount")}
                />
              )}
            </div>
            <div className="actioncontainer">
              <DeleteButton onClick={() => handleOnDeleteInventoryItem(item.id)}/>
              <EditButton onClick={() => handleOnEditInventoryItem(item)}/>
            </div>
          </div>
        ),
      },
    ]);

    return rows;
  }

  useEffect(() => {
    if (activeCategory) {
      setActiveCategory(
        categories.find((category) => category.id === activeCategory.id)
      );
    } else {
      setActiveCategory(categories?.[0]);
    }
  }, [categories]);

  useEffect(() => {
    setPageCount(Math.ceil(activeCategory?.inventoryItems?.length / pagesize));
    if (activeCategory) {
      setRows(createRows(activeCategory?.inventoryItems));
      setSearchValue("");
    }else {
      setRows([])
    }
  }, [activeCategory]);

  useEffect(() => {
    if (!openSlideCategory) {
      setError({
        name: false,
        amount: false,
        warningAmount: false,
        unit: false,
        category: false,
      })
      setShowError(false);
    }
  }, [openSlideItem])

  useEffect(() => {
    if (rows?.length == 0 && currentPage > 1) {
      setActivePage(currentPage - 1);
    }
  }, [rows])

  const handleSearchChange = (value) => {
    setSearchValue(value);
  
    if (!activeCategory || !activeCategory.inventoryItems || activeCategory.inventoryItems.length === 0) {
      setRows([]); 
      return;
    }
  
    const items = activeCategory.inventoryItems; 
  
    if (value.trim() !== "") {
      const result = items.filter((item) =>
        item.name
          .toLowerCase()
          .split(" ")
          .some((word) => word.startsWith(value.toLowerCase()))
      );

      setPageCount(Math.ceil(result.length / pagesize));
      handlePagination(result)
    } else {
      setPageCount(Math.ceil(activeCategory?.inventoryItems?.length / pagesize));
      handlePagination()
    }
  };

  const addCategory = (name) => {
    dispatch(addNewCategory(name));
    setOpenSlideCategory(false);
    setSelectedCategory(null);
  }

  const editCategory = (name, id) => {
    dispatch(ChangeNameOfCategory({name, id}));
    setOpenSlideCategory(false);
    setSelectedCategory(null);
  }

  const handleCategory = (isEditable, name, id) => {
    if (isEditable) {
      editCategory(name, id);
    } else {
      addCategory(name);
    }
  }

  const handleOnDeleteCategory = async (id) => {
    dispatch(deleteInventoryCategory({ id }));
    setActiveCategory(null)
  };

  const handleOnDeleteInventoryItem = (id) => {
    dispatch(deleteCategoryItem({ categoryId: activeCategory.id, itemId: id }));
  };

  const handleOnEditInventoryItem = (item) => {
    setOpenSlideItem(true);
    setSelectedItem(item);
  };

  const handleSort = (header) => {
    if (!header.isSortable) return;
  
    // Check if activeCategory and inventoryItems are valid and not empty
    if (!activeCategory || !activeCategory.inventoryItems || activeCategory.inventoryItems.length === 0) return;
  
    let sortedData;
    const startingindex = (currentPage - 1) * pagesize;
  
    if (sortType === "") {
      sortedData = [...activeCategory.inventoryItems.slice(startingindex, startingindex + pagesize)].sort((a, b) => a.amount - b.amount);
      setSortType("ASC");
    } else if (sortType === "ASC") {
      sortedData = [...activeCategory.inventoryItems.slice(startingindex, startingindex + pagesize)].sort((a, b) => b.amount - a.amount);
      setSortType("DESC");
    } else if (sortType === "DESC") {
      // Use a fallback for the unsorted case
      sortedData = activeCategory.inventoryItems.slice(startingindex, startingindex + pagesize);
      setSortType("");
    }
  
    // Call createRows and update rows only if sortedData is defined
    if (sortedData) {
      const rows = createRows(sortedData);
      setRows(rows);
    }
  };
  
  const EmptyTable = () => {
    return (
      <div className={`AdminDashHistoryFormAndTableEmpty`}>
        <img src={EmptyTableIcon} alt="Empty Table" />
        <h4 className="Medium AdminDashHistoryFormAndTableEmptyInfo">
          {t("emptyStates.noTableData")}
        </h4>
      </div>
    );
  };

  const handleOnAddInventoryItem = () => {
    setOpenSlideItem(true);
    setSelectedItem(null);
  };

  const handleOnEditItem = (item) => {
    dispatch(editInventoryItemToCategory({item : {id: selectedItem.id, amount: item.amount, unit: item.unit, name: item.name, warningAmount: item.warningAmount }, categoryId: item.category.id}));
    setOpenSlideItem(false);
    setSelectedItem(null);
  }; 

  const AddItemToCategory = (item) => {
    const { category, ...restItem } = item;
    dispatch(addInventoryItemToCategory({ categoryId: category.id, item: restItem }));
    setOpenSlideItem(false);
    setSelectedItem(null);
  };

  const handleItem = (isEditable, item) => {
    if (isEditable) {
      handleOnEditItem(item);
    } else {
      AddItemToCategory(item)
    }
  };

  const headers = TABLE_KEYS.map((header, index) => {
    return {
      element: (
        <div
          key={index}
          className="headerCell"
          onClick={() => handleSort(header)}
        >
          <p>
            {t(header.valueForTranslation)}
          </p>
          {header.isSortable && (
            <>
              { sortType === ""  ? (
                <SortArrowDefault />
              ) : sortType === "ASC" ? (
                <SortArrowAsc />
              ) : sortType === "DESC" ? (
                <SortArrowDesc />
              ) : null}
            </>
          )}
        </div>
      ),
    };
  });

  const handlePagination = (result) => {
    const startingindex = (currentPage - 1) * pagesize;
    if (result) {
      setRows(createRows(result))
    }else {
      const rows = createRows(activeCategory?.inventoryItems.slice(startingindex, startingindex + pagesize));
      setRows(rows);
    }
  }

  useEffect(() => {
    handlePagination();
  }, [currentPage, activeCategory])

  return (
    <div className="AdminInventory">
     
      <div className="AdminInventoryCategories">
        <SectionHeader
          title={t("menu.category.categories")}
          count={categories?.length}
        />
        <div className="AdminInventoryCategoriesList">
          <div className="AdminInventoryCategoriesListAdd">
            <AddTagButton onClick={handleOnAddCategory} />
          </div>
          <div className="AdminInventoryCategoriesListExisting">
            {categories?.map((category) => (
              <div
                key={category.id}
                className={`AdminInventoryCategoriesListItem ${
                  activeCategory?.id === category.id ? 'active' : ''
                }`}
              >
              <img src={ElipsL} alt="elips" className={`elipsl ${category?.id ==  activeCategory?.id ? 'active' : '' }`} />
              <TagCard
                tag={category}
                onClick={() => setActiveCategory(category)}
                onEdit={() => handleOnEditCategory(category)}
              />
              <img src={ElipsR} alt="elips" className={`elipsr ${category?.id ==  activeCategory?.id ? 'active' : '' }`} />
            </div>
            ))}
          </div>
        </div>
      </div>

      <div className="AdminInventoryItems">
        <div className="AdminInventoryItemsTitle">
          <div className="AdminInventoryItemsTitleLeft">
            <h4 className="SemiBold">{t("inventory.productList")}</h4>
            <h6 className="Medium">
              {activeCategory?.inventoryItems?.length} {t("inventory.product")}
            </h6>
          </div>
          <div className="AdminInventoryItemsTitleRight">
            <div className="AdminInventoryItemsTitleRightSearch">
              <Search
                onChange={(value) => {
                  handleSearchChange(value);
                }}
                value={searchValue}
                type={SEARCH_ENUMS.types.TYPE_B}
              />
            </div>
            <AddNewButton
              onClick={handleOnAddInventoryItem}
              label={t("buttons.addNew")}
            />
          </div>
        </div>
       
        <TableV2
          headers={headers}
          rows={rows}
          meta={meta}
          EmptyComponent={<EmptyTable />}
          Footer={
            (pageCount > 1) && (
              <Pagination
                pageCount={pageCount}
                pageRange={1}
                setActivePage={setActivePage}
                currentPage={currentPage}
              />
            )
          }
        />
      </div>

      <AddItemModal
        mainElementRef={mainElementRefCategory}
        setOpenSlide={setOpenSlideCategory}
        title={t("menu.category.name")}
        placeholder={t("menu.category.name")}
        item={selectedCategory}
        openSlide={openSlideCategory}
        onSave={handleCategory}
        onDelete={handleOnDeleteCategory}
      />

      <AddInventoryItemModal
        mainElementRef={mainElementRefItem}
        setOpenSlide={setOpenSlideItem}
        title={t("inventory.product")}
        item={selectedItem}
        openSlide={openSlideItem}
        activeCategory={activeCategory}
        onSave={handleItem}
        onDelete={handleOnDeleteInventoryItem}
        error={error}
        setError={setError}
        showError={showError}
        setShowError={setShowError}
      />
      
    </div>
  );
};

export default AdminInventory;
