import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import cx from "classnames";

import { STORE_NAMES } from "utils/constants/redux";
import { updateBasket, updateFavorites } from "redux/slices/basketStore";
import MenuViewPoker from "components/homepage-views/menu-view-poker/MenuViewPoker";
import MenuViewScrollWrapper from "components/homepage-views/menu-view-scroll/menu-view-scroll-wrapper/MenuViewScrollWrapper";
import MenuViewGridWrapper from "components/homepage-views/menu-view-grid/menu-view-grid-wrapper/MenuViewGridWrapper";
import MenuViewListWrapper from "components/homepage-views/menu-view-list/menu-view-list-wrapper/MenuViewListWrapper";
import { QUERY_PARAMS, ROUTE_NAME } from "utils/constants/routes";
import {
  findGuest,
  getBasketModificationsWithDefaultValues,
} from "utils/helpers";
import { filterMenuItemByIsPublishedAndSchedule } from "utils/general";
import { ADMIN_MENU_ITEM_TYPE } from "pages/admin/admin-pages/admin-order/admin-order-menu/AdminOrderMenu";
import OrderMenuItemWrapper from "components/admin/cards/order-menu-item/order-menu-item-wrapper/OrderMenuItemWrapper";
import useLanguage from "utils/hooks/useLanguage";
import { MENU_VIEW_ENUMS } from "utils/constants/data/base";
// import AddToBasketSound from "assets/audios/AddToBasketSound.mp3";

import "./MenuCategories.scss";

const MenuCategories = ({
  category,
  menuViewType,
  setActiveCategory,
  onClick,
  onAdd,
  isAdmin,
  isOrderSection,
  categoriesRef,
  scrollElement,
  categories,
  searchValue,
  setSearchParams,
  setSelectedMenuItemId,
}) => {
  const { displayDataByLanguage } = useLanguage();
  const userId = useSelector((state) => state[STORE_NAMES.guest].id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryRef = useRef(null);
  const basketOrders = useSelector((state) => state[STORE_NAMES.basket].order);
  const { business } = useSelector((state) => state[STORE_NAMES.business]);
  const audioRef = useRef(null);

  let menuItems = filterMenuItemByIsPublishedAndSchedule(
    category.menuItems,
    category.id,
    displayDataByLanguage,
    searchValue
  );
  if (menuItems?.length < 1) {
    menuItems = filterMenuItemByIsPublishedAndSchedule(
      category.menuItems,
      category.id,
      displayDataByLanguage,
      ""
    );
  }

  const guest = findGuest(userId, basketOrders);

  const favoriteItems = guest?.favoriteItems || [];

  const orderItems = guest?.orderItems || [];
  // let [searchParams, setSearchParams] = useSearchParams();
  //
  // const [selectedMenuItemId, setSelectedMenuItemId] = useState(
  //   searchParams.get(QUERY_PARAMS.selectedItem) || null
  // );
  // console.log(selectedMenuItemId, searchParams);

  const handleOnAdd = (menuItem) => {
    // audioRef.current = new Audio(AddToBasketSound);
    // audioRef.current.currentTime = 0;
    // audioRef.current.play().catch((error) => {
    //   console.error("Audio playback failed: ", error);
    // });
    const modifications = getBasketModificationsWithDefaultValues(
      menuItem.modifications
    );
    const filteredModifications = modifications.filter((modification) => {
      return modification.options.length !== 0;
    });
    dispatch(
      updateBasket({
        menuItem: {
          id: menuItem.id,
          modifications: filteredModifications.map((modification) => {
            return {
              id: modification.id,
              options: modification.options.map((option) => {
                return {
                  id: option.id,
                  count: option.count ?? 1,
                };
              }),
            };
          }),
        },
        count: 1,
        userId,
      })
    );
  };

  const handleOnFavorite = (menuItemId) => {
    dispatch(
      updateFavorites({
        userId,
        menuItemID: menuItemId,
      })
    );
  };

  const handleOnClick = (id) => {
    setSelectedMenuItemId(id);
    setSearchParams({ [QUERY_PARAMS.selectedItem]: id });
    navigate(
      `${ROUTE_NAME.client}${ROUTE_NAME.business}/${business?.id}${ROUTE_NAME.menu}?${QUERY_PARAMS.selectedItem}=${id}`
    );
  };

  const handleScroll = () => {
    const categoryTopDistance =
      categoryRef.current?.getBoundingClientRect().top;
    if (categoryTopDistance > 0 && categoryTopDistance < 200) {
      setActiveCategory(category.id);
    }
  };

  useEffect(() => {
    if (isOrderSection && categoriesRef) {
      return categoriesRef.current.addEventListener("scroll", handleScroll);
    }
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
  }, [categories]);

  const isCategoryHasItems = menuItems.some(
    (menuItem) => menuItem.categoryId === category.id
  );

  return (
    <>
      {isCategoryHasItems && (
        <div
          id={category.id}
          ref={categoryRef}
          className={cx("MenuCategories", {
            isAdmin: isAdmin,
          })}
        >
          <div
            className={
              isOrderSection
                ? "MenuDisplaySectionNameForOrderPage"
                : "MenuDisplaySectionName"
            }
          >
            {isOrderSection ? (
              <h1 className={"SemiBold"}>
                {displayDataByLanguage(category.name)}
              </h1>
            ) : (
              <h1 className={"Medium MenuDisplaySectionNameCtgTitle"}>
                {displayDataByLanguage(category.name)}
              </h1>
            )}
          </div>
          {menuViewType === MENU_VIEW_ENUMS.SCROLL.id && (
            <MenuViewScrollWrapper
              menuItems={menuItems}
              onAdd={handleOnAdd}
              onFavorite={handleOnFavorite}
              onClick={handleOnClick}
              favoriteItems={favoriteItems}
              orderItems={orderItems}
            />
          )}
          {menuViewType === MENU_VIEW_ENUMS.LIST.id && (
            <MenuViewListWrapper
              menuItems={menuItems}
              onAdd={handleOnAdd}
              onFavorite={handleOnFavorite}
              onClick={handleOnClick}
              favoriteItems={favoriteItems}
              orderItems={orderItems}
            />
          )}
          {/*{menuViewType === MENU_VIEW_ENUMS.POKER.id && (*/}
          {/*  <MenuViewPoker*/}
          {/*    menuItems={menuItems}*/}
          {/*    onAdd={handleOnAdd}*/}
          {/*    onFavorite={handleOnFavorite}*/}
          {/*    onClick={handleOnClick}*/}
          {/*    favoriteItems={favoriteItems}*/}
          {/*    orderItems={orderItems}*/}
          {/*  />*/}
          {/*)}*/}
          {/*{menuViewType === MENU_VIEW_ENUMS.THREE_D.id && (*/}
          {/*  <MenuView3D*/}
          {/*    menuItems={menuItems}*/}
          {/*    isHorizontalSlider={true}*/}
          {/*    onAdd={handleOnAdd}*/}
          {/*    onFavorite={handleOnFavorite}*/}
          {/*    onClick={handleOnClick}*/}
          {/*    favoriteItems={favoriteItems}*/}
          {/*    orderItems={orderItems}*/}
          {/*  />*/}
          {/*)}*/}
          {menuViewType === MENU_VIEW_ENUMS.GRID.id && (
            <MenuViewGridWrapper
              menuItems={menuItems}
              onAdd={handleOnAdd}
              onFavorite={handleOnFavorite}
              onClick={handleOnClick}
              favoriteItems={favoriteItems}
              orderItems={orderItems}
            />
          )}
          {menuViewType === ADMIN_MENU_ITEM_TYPE && (
            <OrderMenuItemWrapper
              items={menuItems}
              onClick={onClick}
              onAdd={onAdd}
            />
          )}
        </div>
      )}
      {/*{selectedMenuItemId && (*/}
      {/*  <MenuItemSelected menuItemId={selectedMenuItemId} />*/}
      {/*)}*/}
    </>
  );
};

MenuCategories.propTypes = {
  /**
   * The menu category object.
   */
  category: PropTypes.object,
  /**
   * The id of menu view type to display (e.g., SCROLL, GRID, etc.).
   */
  menuViewType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * A function to set the active category.
   */
  setActiveCategory: PropTypes.func,

  /**
   * The onClick function to be called when a specific action is triggered.
   */
  onClick: PropTypes.func,

  /**
   * The onAdd function to be called when adding an item.
   */
  onAdd: PropTypes.func,

  /**
   * A flag indicating whether the user is an admin.
   */
  isAdmin: PropTypes.bool,

  /**
   * Search input value
   */
  searchValue: PropTypes.string,

  isOrderSection: PropTypes.bool,
  categoriesRef: PropTypes.object,

  scrollElement: PropTypes.object,
  categories: PropTypes.array,
};
export default MenuCategories;
